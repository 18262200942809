import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class WebhookHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Create Webhook Information</DialogTitle>
        <DialogContent>
          <h4>Welcome to creating a Webhook.</h4>
          <p>Using this form to create an automation webhook is easy!</p>
          <p>
            First, choose the Smartsheet Workspace in which your desired
            Smartsheet is located. Next, select the Smartsheet you wish to add
            automation to from the SMARTSHEET DROPDOWN. This list contains all
            sheets currently in the TTS R&D Active Projects workspace. Then
            select which column or columns you would like the webhook to be
            triggered on. Lastly, enter in the callbackUrl endpoint that your
            webhook will use. For this, you only need the endpoint without the
            leading '/' character.
          </p>
          <p>
            NOTE: For your chosen automation to run correctly, the selected
            Smartsheet must include its corresponding required columns. The
            required columns can be found in the template for each particular
            automation:{' '}
            <a
              href="https://app.smartsheet.com/folders/mGjC6497G7gH4gxq7ffG2J7WXGRJ3f66WHpvPJf1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Templates Link
            </a>{' '}
          </p>
          <p>
            Once you have made selections in both DROPDOWNs and TEXT FIELD,
            click the CREATE BUTTON in the lower right corner to create the
            webhook. Once the webhook is created, ziptie will automatically
            enable it, switching its TOGGLE to a green, enabled state. However,
            if the TOGGLE displays a gray, disabled state, please contact the
            R&D software engineers for additional help.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleCreateHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
