import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Typography, CardContent } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { getRdPinData } from '../../../store/Procurement/actionCreator'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'

export class rdPinTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rdPinFileList: [],
      loading: true,
    }
    this.baseState = { ...this.state }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    await this.props.getRdPinData()
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'R&D PIN Inventory' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rdPinFileList.length) {
      this.setState({
        rdPinFileList: JSON.parse(JSON.stringify(nextProps.rdPinFileList)),
        loading: false,
      })
    }
  }

  render() {
    let rdPinFileList = []
    if (this.state.rdPinFileList.length > 1) {
      const columns = [
        { label: 'Manufacturer', name: 'manufacturer' },
        { label: 'Inventory R&D PIN', name: 'inventory_rd_pin' },
        { label: 'UPN', name: 'upn' },
        { label: 'Description', name: 'description' },
        { label: 'Inventory Warehouse', name: 'inventory_warehouse' },
        { label: 'Qty on PO', name: 'qty_on_po' },
        { label: 'Qty Usable', name: 'qty_usable' },
      ]

      rdPinFileList = (
        <MUIDataTable
          title="R&D PIN Inventory"
          columns={columns}
          data={this.props.rdPinFileList}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            rowsPerPageOptions: [100, 500, 1000],
            selectableRows: 'none',
            sortOrder: {
              name: 'inventory_rd_pin',
              direction: 'asc',
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      rdPinFileList = this.state.rdPinFileList
    }

    const inventoryPanel = <div>{rdPinFileList}</div>

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                {this.state.rdPinFileList.length > 0 ? (
                  typeof this.state.rdPinFileList[0] === 'string' ? (
                    <Typography variant="h4">
                      {this.state.rdPinFileList}
                    </Typography>
                  ) : (
                    inventoryPanel
                  )
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rdPinFileList: state.procurement.rdPinFileList,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  getRdPinData,
}

export default connect(mapStateToProps, mapDispatchToProps)(rdPinTab)
