/* eslint-disable prettier/prettier */
import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import { Typography } from '@mui/material'

export default class ChangeSuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventsMessage: '',
    }
    this.successMessage = this.successMessage.bind(this)
  }

  successMessage(number, changeUrl) {
    return (
      <div>
        Change{' '}
        <a href={changeUrl} target="_blank" rel="noopener noreferrer">
          {number}
        </a>{' '}
        created!
      </div>
    )
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.eventsMessage === '' ||
          this.props.eventsMessage === undefined ? (
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
            <Typography variant="body1" gutterBottom>
                {this.props.title === 'Success'
                  ? this.successMessage(this.props.changeNumber, this.props.changeUrl)
                  : this.props.eventsMessage}
              </Typography>
              <Button variant="contained" onClick={this.props.handleClose}>
                OK
              </Button>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
