import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export const DnsErrorModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{ onExited: props.clearMessage }}
    >
      <DialogTitle>DNS RTM Generation Error</DialogTitle>
      <DialogContent>{props.message}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
