import Firefly from 'firefly-sdk-ts'
import apiConfig from '../config/apiConfig'

export function initializeFireflySDK() {
  Firefly.registerEventManagerWithConfig(apiConfig.fireflyConfig)

  Firefly.registerEventManager(
    apiConfig.fireflyConfig.eventManagerKey,
    apiConfig.fireflyConfig.apiKey,
    apiConfig.fireflyConfig.persistMethod,
    apiConfig.fireflyConfig.retries,
    apiConfig.fireflyConfig.schemaId,
    apiConfig.fireflyConfig.url,
  )
}
