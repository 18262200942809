import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class LayoutErrorModal extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Layout Creation Error</DialogTitle>
        <DialogContent>{this.props.message}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this.props.handleErrorModalClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
