import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function getLocationProjects(locationId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/location_projects/${locationId}`)
      .then((resp) => {
        let activeProjects = resp.data
        if (activeProjects.length === 0) {
          activeProjects = 'No Projects Found!'
        }
        dispatch({
          type: types.GET_LOCATION_PROJECTS,
          payload: {
            locationId: locationId,
            projects: activeProjects,
          },
        })
      })
      .catch((error) => {
        var result = 'No Projects Found!'
        dispatch({
          type: types.GET_LOCATION_PROJECTS,
          payload: {
            locationId: locationId,
            projects: result,
          },
        })
      })
  }
}

export function getLocationIncidents(locationId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/location_incidents?location_id=${locationId}`)
      .then((resp) => {
        let activeIncidents = resp.data.filter(function (incident) {
          return incident.state !== 'Restored'
        })
        if (activeIncidents.length === 0) {
          activeIncidents = 'No Active Incidents!'
        }
        dispatch({
          type: types.GET_LOCATION_INCIDENTS,
          payload: {
            locationId: locationId,
            incidents: activeIncidents,
          },
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Location Not Found!'
          dispatch({
            type: types.GET_LOCATION_INCIDENTS,
            payload: {
              locationId: locationId,
              incidents: result,
            },
          })
        }
      })
  }
}

export function getLocationInformation(locationId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/location_information?location_id=${locationId}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_LOCATION_INFORMATION,
          payload: {
            locationId: locationId,
            locationInformation: resp.data,
          },
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Location Not Found!'
          dispatch({
            type: types.GET_LOCATION_INFORMATION,
            payload: {
              locationId: locationId,
              locationInformation: result,
            },
          })
        }
      })
  }
}
