import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class DeviceConfigurationHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Device Configuration Help</DialogTitle>
        <DialogContent>
          <h4>Device Configuration</h4>
          <div>
            <p>
              To see the list of the devices available to configure, visit{' '}
              <a
                href="https://app.smartsheet.com/sheets/HH76JGRwRmwcHcmXvjfc7wJG98rGCc2MmQHxp4c1?view=grid"
                target="_blank"
                rel="noopener noreferrer"
              >
                Device Configuration Automation Grid
              </a>
              .
            </p>
            <p>Follow the steps below to configure devices:</p>
            <ol>
              <li>
                In the 'Scope Sheet' drop-down, select the sheet from the Active
                Projects Workspace that has the projects for the devices you
                wish to configure.
              </li>
              <li>
                The 'Configuration Type' drop-down will then populate with the
                configuration types used by projects in your selected sheet.
                NOTE: 'Configuration Step' and 'Configuration Locations' are
                populated based on configuration type, so if the type is
                changed, it may reset the other fields.
              </li>
              <li>
                Next, in the 'Configuration Step' drop-down, select the
                configuration step that you wish to initiate. The steps listed
                will correspond to those for the chosen configuration type. Some
                items in the list may be grayed-out. This is okay. It simply
                means that based upon your role, you won't have access to
                initiate certain steps for a configuration type.
              </li>
              <li>
                In the 'Configuration Locations' drop-down, next select all
                relevant locations. These are the locations listed in your
                selected sheet with the chosen configuration type.
              </li>
              <li>
                If, at any time you wish to start over, click on the 'Reset'
                button and the entire form will be cleared of any selected data.
              </li>
              <li>
                When you're satisfied with your choices, press the 'Submit'
                button to initiate the chosen configuration step. Once the
                initiation has been completed, a window will appear with either
                a success message, or a description of what went wrong.
              </li>
            </ol>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
