import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import { Typography } from '@mui/material'

export default class RevokeMessageModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventsMessage: '',
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.revokeMessage === '' ||
        this.props.revokeMessage === undefined ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>PIN Revoke Status</DialogTitle>
            <DialogContent>
              <div>
                <Typography variant="body1" gutterBottom>
                  {this.props.revokeMessage.split('\n').map((sentence) => (
                    <p>{sentence}</p>
                  ))}
                </Typography>
                <Button variant="contained" onClick={this.props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
