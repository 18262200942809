import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class CreateCutsheetHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Create Cutsheet/Supplemental Sheet Help</DialogTitle>
        <DialogContent>
          <h4>Access Points and Phones</h4>
          <p>
            <ol>
              <li>In 'Sheet Type' drop-down, select appropriate type</li>
              <li>
                In 'New or Existing Sheet' drop-down, select appropriate choice.
                If you select Existing Sheet, select the Smartsheet Workspace
                that the sheet exists in and then select the desired Smartsheet
              </li>
              <li>Click 'Choose Files' and only select files of type .csv</li>
            </ol>
          </p>
          <h4>TVS supplemental sheet</h4>
          <p>
            <ol>
              <li>
                In 'Sheet Type' drop-down, select 'TVS - supplemental sheet'
              </li>
              <li>
                In 'Team Name' drop-down, select which R&D team this sheet is
                for. That name will be appended to the end of the Smartsheet
                name
              </li>
              <li>
                Click 'Choose Files' and only select files of type .xls or .xlsx
              </li>
            </ol>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
