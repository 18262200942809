// THIS FILE WAS PREVIOUSLY PART OF THE DEPRECATED REACT-PRAXIS-COMPONENTS LIBRARY.
// THE RECOMMENDED FIX FROM THE PRAXIS TEAM IS TO COPY & PASTE NEEDED COMPONENTS
// INTO YOUR PROJECT

import { string, func } from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import ExitIcon from '@mui/icons-material/ExitToApp'

export const UserBar = (props) => {
  const {
    classes,
    displayName,
    company,
    handleSignOut,
    handleSignIn,
    isAuthenticated,
  } = props
  return (
    <Toolbar classes={{ root: classes.root, gutters: classes.gutters }}>
      {isAuthenticated() && (
        <Grid item>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid className={classes.displayName} item>
              {displayName}
            </Grid>
            <Grid item>
              <IconButton
                title="Sign Out"
                color="inherit"
                onClick={handleSignOut}
                aria-label="Sign Out"
                size="large"
              >
                <ExitIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid className={classes.company} item>
            {company}
          </Grid>
        </Grid>
      )}
      {!isAuthenticated && (
        <Button
          className={classes.signInButton}
          color="inherit"
          onClick={handleSignIn}
          aria-label="Sign In"
        >
          Sign In
        </Button>
      )}
    </Toolbar>
  )
}

UserBar.propTypes = {
  displayName: string,
  company: string,
  handleSignIn: func,
  handleSignOut: func,
  isAuthenticated: func,
}
UserBar.defaultProps = {
  classes: {},
}

const styles = {
  root: {
    backgroundColor: '#666',
    color: '#fff',
  },
  gutters: {
    paddingLeft: '14px',
    paddingRight: '4px',
  },
  displayName: {
    flexShrink: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  signInButton: {
    margin: '0 auto',
  },
}

export default withStyles(styles)(UserBar)
