import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CircularProgress, FormControl, Grid, Typography } from '@mui/material'
import Select from 'react-select'

export default class MaximoBulkUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      workspace: '',
      smartsheet: '',
      message: '',
      loading: true,
    }
    this.validateBulkUploadForm = this.validateBulkUploadForm.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.workspaces !== nextProps.workspaces) {
      this.setState({
        loading: false,
      })
    }
  }

  validateBulkUploadForm = () => {
    if (this.state.workspace !== '' && this.state.smartsheet !== '') {
      this.setState({ uploadButtonEnabled: true })
    }
  }

  async handleUpload() {
    await this.props.getMaximoBulkLocations(this.state.smartsheet.value)
    await this.setState({ message: this.props.bulkLocations.second })
    if (this.props.bulkLocations.second === 'Success') {
      this.props.uploadBulkLocations(this.state.smartsheet.value)
      this.resetForm()
    }
  }

  resetForm = () => {
    this.setState({
      workspace: '',
      smartsheet: '',
      uploadButtonEnabled: false,
      message: '',
      loading: false,
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleBulkUploadClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{
            marginTop: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Bulk upload from Smartsheet
        </DialogTitle>
        <DialogContent>
          {this.state.loading ? (
            <div>
              <Grid
                container
                spacing={16}
                justifyContent={'center'}
                className="progress"
              >
                <CircularProgress />
                <Typography className="loading">&nbsp;Loading...</Typography>
              </Grid>
            </div>
          ) : (
            <div>
              <form>
                <FormControl
                  style={{
                    width: '90%',
                    marginTop: '5%',
                  }}
                >
                  <Select
                    value={this.state.workspace}
                    placeholder="Workspace"
                    options={
                      this.props.workspaces.length > 0
                        ? this.props.workspaces.map((workspace) => ({
                            label: workspace.name,
                            value: workspace.id,
                          }))
                        : ''
                    }
                    onChange={(event) => {
                      this.setState(
                        { workspace: event, smartsheet: '', message: '' },
                        function () {
                          this.validateBulkUploadForm()
                          this.props.getActiveSheets(this.state.workspace.value)
                        },
                      )
                    }}
                    inputProps={{
                      name: 'workspace',
                      id: 'workspace',
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    width: '90%',
                    marginTop: '5%',
                  }}
                >
                  <Select
                    value={this.state.smartsheet}
                    placeholder="Scope Sheet"
                    options={
                      this.props.activeSheets.length > 0
                        ? this.props.activeSheets.map((smartsheet) => ({
                            label: smartsheet.name,
                            value: smartsheet.id,
                          }))
                        : ''
                    }
                    onChange={(event) => {
                      this.setState(
                        { smartsheet: event, message: '' },
                        function () {
                          this.validateBulkUploadForm()
                        },
                      )
                    }}
                    inputProps={{
                      name: 'smartsheet',
                      id: 'smartsheet',
                    }}
                  />
                </FormControl>
                <FormControl
                  style={{
                    width: '90%',
                    marginTop: '5%',
                  }}
                >
                  {this.state.message}
                </FormControl>
                <DialogActions sx={{ marginTop: '12%' }}>
                  <Button
                    variant="contained"
                    onClick={this.props.handleBulkUploadClose}
                    className="bulkUploadClose"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={this.handleUpload}
                    disabled={!this.state.uploadButtonEnabled}
                  >
                    Upload
                  </Button>
                </DialogActions>
              </form>
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}
