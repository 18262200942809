import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function getRdPinData() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/rd_pin_file`)
      .then((resp) => {
        const rdPinFile = resp.data
        dispatch({
          type: types.GET_RD_PIN_FILE,
          payload: rdPinFile,
        })
      })
      .catch((error) => {
        var result = 'No R&D PIN Inventory Data Found'
        dispatch({
          type: types.GET_RD_PIN_FILE,
          payload: result,
        })
      })
  }
}

export function createHwLog(requestData) {
  return async function (dispatch) {
    await axios
      .post(`${apiConfig.api.url}/supplemental_hardware_logs`, requestData)
      .then((resp) => {
        var result = ''
        if (resp.status === 201) {
          result = `Supplemental Hardware Log updated!`
        } else {
          result = 'Failed to create Supplemental Hardware Log, please retry.'
        }
        dispatch({
          type: types.POST_HW_LOG,
          payload: result,
        })
      })
      .catch((error) => {
        const result =
          'Failed to create Supplemental Hardware Log, please retry.'
        dispatch({
          type: types.POST_HW_LOG,
          payload: result,
        })
      })
  }
}

export function getHWLogOptions() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/supplemental_hardware_options`)
      .then((resp) => {
        dispatch({
          type: types.GET_HW_LOG_OPTIONS,
          payload: resp.data,
        })
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: types.GET_HW_LOG_OPTIONS,
            payload: {
              messageString: 'Error loading form data from Smartsheet.',
            },
          })
        }
      })
  }
}

export function getTransactionTrackerData() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/transaction_tracker`)
      .then((resp) => {
        const transactionTracker = resp.data
        dispatch({
          type: types.GET_TRANSACTION_TRACKER,
          payload: transactionTracker,
        })
      })
      .catch((error) => {
        var result = 'No R&D PIN Transaction Data Found'
        dispatch({
          type: types.GET_TRANSACTION_TRACKER,
          payload: result,
        })
      })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}

export function getPoLogs() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/hardware_inventory`)
      .then((resp) => {
        const hardwareInventory = resp.data
        dispatch({
          type: types.GET_HARDWARE_INVENTORY,
          payload: hardwareInventory,
        })
      })
      .catch((error) => {
        var result = 'No PO Log data found!'
        dispatch({
          type: types.GET_HARDWARE_INVENTORY,
          payload: result,
        })
      })
  }
}
