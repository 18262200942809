import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AvailablePortsHelpDialog from './AvailablePortsHelpDialog'
import CreateCutsheetsSuccessModal from './CreateCutsheetsSuccessModal'
import {
  clearPortsMessage,
  getPortInfo,
} from '../../store/CreateSmartsheets/actionCreator'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../config/apiConfig'
import { initializeFireflySDK } from '../../common/FireflyHelper'

export class AvailablePortInfoTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonEnabled: false,
      helpOpen: false,
      locations: '',
      portSheet: [],
      portsMessage: '',
      statusOpen: false,
    }
    initializeFireflySDK()
    this.handleHelpClose = this.handleHelpClose.bind(this)
    this.handleHelpOpen = this.handleHelpOpen.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.submitAvailablePortsForm = this.submitAvailablePortsForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Available Ports Tab' },
      user: { id: userInfo.email },
    })
  }

  handleHelpClose() {
    this.setState({ helpOpen: false })
  }

  handleHelpOpen() {
    this.setState({ helpOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  resetForm = () => {
    this.setState({
      locations: '',
      buttonEnabled: false,
    })
  }

  submitAvailablePortsForm(event, locations, user) {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Available Ports - Submit' },
      user: { id: user.email },
    })
    event.preventDefault()
    this.props.clearPortsMessage()
    this.props.getPortInfo(locations)
    this.setState({ statusOpen: true })
    this.resetForm()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      portSheetData: nextProps.portSheetData,
      portsMessage: nextProps.portsMessage,
    })
  }

  validateForm() {
    if (this.state.locations !== '') {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    let modalTitle
    if (this.state.portsMessage === 'Smartsheet created!') {
      modalTitle = 'Success'
    } else if (
      this.state.portsMessage !== undefined &&
      this.state.portsMessage.length > 1 &&
      this.state.portsMessage !== 'Smartsheet created!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <CardContent
        style={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Enter a list of location numbers with spaces between each.
            </Typography>
            <Tooltip title={'Help'}>
              <IconButton onClick={this.handleHelpOpen} size="large">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </CardActions>
        <form
          onSubmit={(event) =>
            this.submitAvailablePortsForm(
              event,
              this.state.locations,
              this.props.user,
            )
          }
          style={{
            alignItems: 'stretch',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid container item style={{ paddingLeft: 8 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexBasis: '90%',
                }}
              >
                <Grid item xs={14} md={14}>
                  <FormControl style={{ width: '100%' }}>
                    <TextareaAutosize
                      htmlFor="locations"
                      type="text"
                      minRows={13}
                      maxRows={18}
                      value={this.state.locations}
                      placeholder="T0003 T0004 ETC"
                      label="Locations"
                      required
                      onChange={(event) =>
                        this.setState(
                          { locations: event.target.value },
                          function () {
                            this.validateForm()
                          },
                        )
                      }
                      style={{ width: '100%' }}
                    />
                  </FormControl>
                </Grid>
              </div>
              <CardActions
                style={{
                  width: '100%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 20,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.buttonEnabled}
                  >
                    Submit
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <AvailablePortsHelpDialog
          open={this.state.helpOpen}
          handleHelpClose={this.handleHelpClose}
        />
        <CreateCutsheetsSuccessModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventsMessage={this.state.portsMessage}
          smartsheetData={this.state.portSheetData}
          title={modalTitle}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    portSheetData: state.createSmartsheets.portSheetData,
    portsMessage: state.createSmartsheets.portsMessage,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  clearPortsMessage,
  getPortInfo,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailablePortInfoTab)
