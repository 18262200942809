import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class AutomationTestHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Automated Tests Information</DialogTitle>
        <DialogContent>
          <h4>Welcome to the Automated Tests Information page.</h4>
          <p>
            This is where you can see the scope Smartsheets that are in the
            Workspace, *TTS R&D Active Projects. To enable or disable automation
            for a sheet, click the toggle on the far right.
          </p>
          <p>
            To find out more information about Automated Testing, visit the{' '}
            <a
              href="https://app.smartsheet.com/dashboards/wJ4hF5J24pwxvmFgwX94XQ2vHq2XV7PXHf8j8j91"
              target="_blank"
              rel="noopener noreferrer"
            >
              TTS R&D Software Engineering Portal
            </a>
            .
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleAutomationTestHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
