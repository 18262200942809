import React from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import HeaderTitle from '../Header/HeaderTitle'
import {
  getWebhookList,
  getActiveSheets,
  getSheetColumns,
  getWorkspaces,
  createWebhook,
  updateWebhook,
  deleteWebhook,
  checkSheetColumns,
  resetForm,
  resetSuccessMessage,
} from '../../store/WebhooksManagement/actionCreator'
import CircularProgress from '@mui/material/CircularProgress'
import MUIDataTable from 'mui-datatables'
import CustomToolbar from './CustomToolBarAddButton'
import CreateWebhookDialog from './CreateWebhookDialog'
import { Tooltip } from '@mui/material'
import WebhookHelpDialog from './WebhookHelpDialog'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'

export class WebhooksManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      webhooksList: [],
      activeSheets: [],
      sheetColumns: [],
      workspaces: [],
      rowsDeleted: [],
      loading: false,
      createDialogOpen: false,
      webhookHelpOpen: false,
      createHelpOpen: false,
      webhookSuccessOpen: false,
      webhookSuccessMessage: '',
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.handleCreateOpen = this.handleCreateOpen.bind(this)
    this.handleCreateClose = this.handleCreateClose.bind(this)
    this.createNewWebhook = this.createNewWebhook.bind(this)
    this.formatStatus = this.formatStatus.bind(this)
    this.dialogBox = React.createRef()
    this.handleWebhookHelpOpen = this.handleWebhookHelpOpen.bind(this)
    this.handleWebhookHelpClose = this.handleWebhookHelpClose.bind(this)
    this.handleCreateHelpOpen = this.handleCreateHelpOpen.bind(this)
    this.handleCreateHelpClose = this.handleCreateHelpClose.bind(this)
    this.handleSuccessOpen = this.handleSuccessOpen.bind(this)
    this.handleSuccessClose = this.handleSuccessClose.bind(this)
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
      lanId: undefined,
    },
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Webhook Management',
          pageName: 'Webhook Management',
          pageType: 'Webhook Management',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Webhook Management' },
      user: { id: userInfo.email },
    })
    this.setState({ loading: true })
    await this.props.getWebhookList()
    this.setState({ loading: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      webhooksList: JSON.parse(JSON.stringify(nextProps.webhooksList)),
      webhookSuccessMessage: nextProps.webhookSuccessMessage,
    })
  }

  async handleCreateOpen() {
    await this.props.getWorkspaces()
    this.setState({ createDialogOpen: true })
  }

  handleCreateClose() {
    this.setState({ createDialogOpen: false })
    this.props.resetForm()
    this.dialogBox.current.resetForm()
  }

  handleWebhookHelpOpen() {
    this.setState({ webhookHelpOpen: true })
  }

  handleWebhookHelpClose() {
    this.setState({ webhookHelpOpen: false })
  }

  handleCreateHelpOpen() {
    this.setState({ createHelpOpen: true })
  }

  handleCreateHelpClose() {
    this.setState({ createHelpOpen: false })
  }

  handleSuccessOpen() {
    this.setState({ webhookSuccessOpen: true })
  }

  async handleSuccessClose() {
    await this.setState({
      webhookSuccessOpen: false,
      createDialogOpen: false,
    })
    this.props.resetSuccessMessage()
  }

  toggleWebhook = (event, id, callbackUrl, name) => {
    let newWebhookData
    if (event.target.value === 'true') {
      newWebhookData = {
        callbackUrl: callbackUrl,
        name: name,
        enabled: false,
      }
    } else {
      newWebhookData = {
        callbackUrl: callbackUrl,
        name: name,
        enabled: true,
      }
    }
    this.props.updateWebhook(id, newWebhookData)
  }

  createNewWebhook = (event, id, callbackUrl, name, user, columns) => {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Webhook Management - Create' },
      user: { id: user.email },
    })
    event.preventDefault()
    const userName = user.email
      .substring(0, user.email.indexOf('@'))
      .replace('.', ' ')
    const ownerWithoutSpaces = userName.replace(' ', '>')
    let columnIds = []
    if (columns.length > 0) {
      columnIds = columns.map((column) => {
        return column.value
      })
    }
    const newWebhookData = {
      callbackUrl: `${apiConfig.api.rapidUrl}/${callbackUrl}`,
      name: name + '<' + ownerWithoutSpaces,
      smartsheetId: id,
      owner: userName,
      // columnIds: [columns.id], // NEED TO UPDATE THIS WHEN MULTI-SELECT IS ADDED
      columnIds: columnIds,
    }
    this.setState({ webhookSuccessOpen: true })
    this.props.checkSheetColumns(newWebhookData)
    this.dialogBox.current.resetForm()
  }

  deleteWebhook = (rowsDeleted) => {
    rowsDeleted.data.forEach((rowDeletedData) => {
      this.props.deleteWebhook(
        this.state.webhooksList[rowDeletedData.dataIndex].id,
      )
    })
  }

  formatStatus = (input) => {
    let formattedStatus
    if (input === 'ENABLED') {
      formattedStatus = 'Enabled'
    } else if (input === 'NEW_NOT_VERIFIED') {
      formattedStatus = 'Not Verified'
    } else if (input === 'DISABLED_BY_OWNER') {
      formattedStatus = 'Disabled By Owner'
    } else if (input === 'DISABLED_VERIFICATION_FAILED') {
      formattedStatus = 'Verification Failed'
    } else if (input === 'DISABLED_SCOPE_INACCESSIBLE') {
      formattedStatus = 'Smartsheet Scope Not Accessible'
    } else {
      formattedStatus = 'Disabled by Smartsheet'
    }
    return formattedStatus
  }

  render() {
    let currentWebhooks = []
    if (this.state.webhooksList[0] !== 'Error Loading Webhooks..') {
      const webhooksColumns = [
        {
          label: 'Name',
          name: 'name',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let formattedWebhookName
              if (tableMeta.rowData[0].includes('<')) {
                formattedWebhookName = tableMeta.rowData[0].substring(
                  0,
                  tableMeta.rowData[0].lastIndexOf('<'),
                )
              } else {
                formattedWebhookName = tableMeta.rowData[0]
              }
              return (
                <a
                  href={tableMeta.rowData[8]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formattedWebhookName}
                </a>
              )
            },
          },
        },
        {
          label: 'Smartsheet Id',
          name: 'smartsheetId',
          options: { display: false },
        },
        {
          label: 'Owner',
          name: 'name',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let formattedOwnerName
              if (tableMeta.rowData[0].includes('<')) {
                const unformattedOwnerName = tableMeta.rowData[0].substring(
                  tableMeta.rowData[0].lastIndexOf('<') + 1,
                )
                formattedOwnerName = unformattedOwnerName.replace('>', ' ')
              } else {
                formattedOwnerName = ''
              }
              return formattedOwnerName
            },
          },
        },
        {
          label: 'Last Successful Validation',
          name: 'lastSuccessfulCallback',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let displayedDateTime
              if (tableMeta.rowData[3] === '') {
                displayedDateTime = 'N/A'
              } else {
                const formattedDate = new Date(
                  tableMeta.rowData[3],
                ).toLocaleDateString()
                const unformattedDate = new Date(tableMeta.rowData[3])
                const hours = unformattedDate.getHours()
                const minutes = unformattedDate.getMinutes()
                let displayHours
                let amPm
                if (hours > 12) {
                  displayHours = hours - 12
                  amPm = 'PM'
                } else if (hours === 0) {
                  displayHours = 12
                  amPm = 'AM'
                } else {
                  displayHours = hours
                  amPm = 'AM'
                }
                const displayMinutes = (minutes < 10 ? '0' : '') + minutes
                displayedDateTime =
                  formattedDate +
                  ' - ' +
                  displayHours +
                  ':' +
                  displayMinutes +
                  ' ' +
                  amPm
              }
              return displayedDateTime
            },
          },
        },
        {
          label: 'Webhook Type',
          name: 'webhookType',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const rawType = tableMeta.rowData[5].split('/v1/callback_')[1]
              const formattedType = rawType
                .split('_')
                .map(
                  (word) => word[0].toUpperCase() + word.slice(1).toLowerCase(),
                )
                .join(' ')
              return formattedType
            },
          },
        },
        {
          label: 'Callback Url',
          name: 'callbackUrl',
          options: { display: false },
        },
        {
          label: 'Disable/Enable',
          name: 'enabled',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Tooltip title={this.formatStatus(tableMeta.rowData[8])}>
                        <Switch
                          color="success"
                          checked={value}
                          value={value}
                          onChange={(event) =>
                            this.toggleWebhook(
                              event,
                              tableMeta.rowData[7],
                              tableMeta.rowData[5],
                              tableMeta.rowData[0],
                            )
                          }
                        />
                      </Tooltip>
                    }
                  />
                </FormGroup>
              )
            },
          },
        },
        {
          label: 'Webhook Id',
          name: 'id',
          options: { display: false },
        },
        {
          label: 'Smartsheet Url',
          name: 'sheetUrl',
          options: { display: false },
        },
        {
          label: 'Smartsheet Name',
          name: 'sheetName',
          options: { display: false },
        },
        {
          label: 'Status',
          name: 'status',
          options: {
            display: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.formatStatus(tableMeta.rowData[10])
            },
          },
        },
      ]
      currentWebhooks = (
        <MUIDataTable
          title="Webhooks"
          columns={webhooksColumns}
          data={this.props.webhooksList}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            selectableRows: 'multiple',
            rowsPerPageOptions: [10, 20, 50],
            rowsDeleted: this.state.rowsDeleted,
            onRowsDelete: (rowsDeleted) => {
              this.deleteWebhook(rowsDeleted)
            },
            customToolbar: () => {
              return (
                <CustomToolbar
                  openDialog={this.handleCreateOpen}
                  openHelpDialog={this.handleWebhookHelpOpen}
                />
              )
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      currentWebhooks = this.state.webhooksList
    }

    const webhooksPanel = <div>{currentWebhooks}</div>

    return (
      <div>
        <HeaderTitle title="Webhooks Management" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <WebhookHelpDialog
                  open={this.state.webhookHelpOpen}
                  handleWebhookHelpClose={this.handleWebhookHelpClose}
                />
                <CreateWebhookDialog
                  open={this.state.createDialogOpen}
                  handleCreateClose={this.handleCreateClose}
                  createNewWebhook={this.createNewWebhook}
                  getActiveSheets={this.props.getActiveSheets}
                  getSheetColumns={this.props.getSheetColumns}
                  activeSheets={this.props.activeSheets}
                  sheetColumns={this.props.sheetColumns}
                  workspaces={this.props.workspaces}
                  user={this.props.userInfo}
                  ref={this.dialogBox}
                  helpOpen={this.state.createHelpOpen}
                  successOpen={this.state.webhookSuccessOpen}
                  successMessage={this.state.webhookSuccessMessage}
                  handleCreateHelpOpen={this.handleCreateHelpOpen}
                  handleCreateHelpClose={this.handleCreateHelpClose}
                  handleSuccessOpen={this.handleSuccessOpen}
                  handleSuccessClose={this.handleSuccessClose}
                />
                {webhooksPanel}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    webhooksList: state.webhookManagement.webhooksList,
    activeSheets: state.webhookManagement.activeSheets,
    sheetColumns: state.webhookManagement.sheetColumns,
    workspaces: state.webhookManagement.workspaces,
    webhookSuccessMessage: state.webhookManagement.webhookSuccessMessage,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  getWebhookList,
  getWorkspaces,
  createWebhook,
  updateWebhook,
  deleteWebhook,
  getActiveSheets,
  getSheetColumns,
  checkSheetColumns,
  resetForm,
  resetSuccessMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksManagement)
