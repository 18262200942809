import { createTheme } from '@mui/material/styles'

export const ziptieColors = {
  primary: {
    light: '#f05545', // light red
    main: '#8c1f27', // Target red
    dark: '#7f0000', // dark red
    contrastText: '#fff', // white
  },
  secondary: {
    light: '#e0e0e0', // light gray
    main: '#000000de', // slightly lighter black
    dark: '#858585', // gray
  },
  success: {
    light: '#acfca9', // light green
    main: '#52d869', // green
  },
}

const ziptieTheme = createTheme({
  palette: {
    primary: ziptieColors.primary,
    secondary: ziptieColors.secondary,
    success: ziptieColors.success,
  },
  typography: {
    useNextVariants: true,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: ziptieColors.primary.main,
          color: ziptieColors.primary.contrastText,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.helpModalClose': {
            color: ziptieColors.secondary.main,
            '&:hover': {
              backgroundColor: ziptieColors.secondary.light,
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '20px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '80%',
        },
        paperWidthSm: {
          maxWidth: '800px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '30px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: ziptieColors.primary.contrastText,
          backgroundColor: ziptieColors.primary.main,
          marginBottom: '20px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '12px',
          '&.customToolbarIcon': {
            color: ziptieColors.primary.contrastText,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:after': {
            borderBottom: '2px solid',
            borderBottomColor: ziptieColors.primary.contrastText,
          },
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid',
            borderBottomColor: ziptieColors.secondary.light,
          },
          '&:hover': {
            borderBottom: '1px solid',
            borderBottomColor: ziptieColors.secondary.dark,
          },
          '&.redUnderline': {
            '&:hover': {
              borderBottom: '2px solid',
              borderBottomColor: ziptieColors.primary.main,
            },
          },
        },
        input: {
          color: 'inherit',
          '&.formInput': {
            color: ziptieColors.secondary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&.MuiInput': {
            root: {
              '&:hover': {
                borderBottomColor: ziptieColors.primary.main,
              },
            },
          },
        },
        input: {
          color: ziptieColors.primary.contrastText,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.progress': {
            marginTop: 100,
          },
          '&.cardBanner': {
            width: '100%',
            height: '65.5px',
            padding: '20px',
            marginBottom: '2%',
            color: ziptieColors.primary.contrastText,
            backgroundColor: ziptieColors.primary.main,
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '1.75',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& a': {
            '&#praxisLink': {
              color: ziptieColors.primary.main,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: ziptieColors.secondary.light,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: ziptieColors.secondary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: ziptieColors.primary.contrastText,
          opacity: '0.7',
          '&.Mui-selected': {
            color: ziptieColors.primary.contrastText,
            opacity: '1',
          },
          padding: '6px 12px',
          overflow: 'hidden',
          position: 'relative',
          fontSize: '0.875rem',
          maxWidth: '264px',
          minWidth: '72px',
          boxSizing: 'border-box',
          minHeight: '48px',
          textAlign: 'center',
          flexShrink: 0,
          fontWeight: 500,
          lineHeight: 1.75,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          color: ziptieColors.secondary.main,
        },
        menuItem: {
          '&.Mui-selected': {
            backgroundColor: ziptieColors.secondary.light,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: ziptieColors.primary.contrastText,
          width: '100%',
          bottom: 0,
          height: '6px',
          position: 'absolute',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.loading': {
            fontSize: 30,
          },
          '&.modalBody': {
            paddingBottom: '10px',
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          margin: '1px',
        },
      },
    },
    MUIDataTableSearch: {
      styleOverrides: {
        searchIcon: {
          color: ziptieColors.primary.contrastText,
        },
        clearIcon: {
          color: ziptieColors.primary.contrastText,
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          color: ziptieColors.primary.contrastText,
          backgroundColor: ziptieColors.primary.main,
        },
        icon: {
          color: ziptieColors.primary.contrastText,
          '&:hover': {
            color: ziptieColors.secondary.light,
          },
        },
        iconActive: {
          color: ziptieColors.primary.contrastText,
        },
      },
    },
  },
})

export default ziptieTheme
