import React from 'react'
import { connect } from 'react-redux'
import Add from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  // TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'
import HardwareLogFormSuccessModal from './HardwareLogFormSuccessModal'
import {
  getHWLogOptions,
  createHwLog,
  clearMessage,
} from '../../../store/Procurement/actionCreator'

export class HardwareLogFormTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hwLogOptions: this.props.hwLogOptions,
      ttsExecutionTeam: '',
      requestorEmail: '',
      deliveryTime: '',
      deliveryDate: '',
      shippingTo: '',
      ttsExecutionTeamLabel: '',
      requestorEmailLabel: '',
      deliveryTimeLabel: '',
      shippingToLabel: '',
      locations: [],
      attention: '',
      specialInstructions: '',
      rndPin: '',
      period: '',
      businessName: '',
      shippingAddress: '',
      shippingCity: '',
      shippingState: '',
      shippingZip: '',
      partsList: [],
      selectedUpn: '',
      selectedUpnLabel: '',
      reason: '',
      reasonLabel: '',
      quantity: '',
      inventoryPin: '',
      buttonEnabled: false,
      eventsMessage: '',
      statusOpen: false,
      loading: true,
    }
    this.createHwLog = this.createHwLog.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.addPartRow = this.addPartRow.bind(this)
    this.removePart = this.removePart.bind(this)
    this.removeLastPart = this.removeLastPart.bind(this)
  }

  async componentDidMount() {
    await this.props.getHWLogOptions()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Supplemental Hardware Log Form Tab' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    user: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hwLogOptions !== nextProps.hwLogOptions) {
      this.setState({
        hwLogOptions: JSON.parse(JSON.stringify(nextProps.hwLogOptions)),
        loading: false,
      })
    }
    this.setState({
      eventsMessage: nextProps.eventsMessage,
    })
  }

  addPartRow() {
    if (
      this.state.selectedUpn !== '' &&
      this.state.reason !== '' &&
      this.state.quantity !== ''
    ) {
      var partsListNew = [...this.state.partsList]
      var part = {
        upn_description: this.state.selectedUpn,
        reason: this.state.reason,
        quantity: this.state.quantity,
        inventory_pin: this.state.inventoryPin,
      }
      partsListNew.push(part)
      this.setState({
        partsList: partsListNew,
        selectedUpn: '',
        selectedUpnLabel: '',
        reason: '',
        reasonLabel: '',
        quantity: '',
        inventoryPin: '',
      })
    }
  }

  async removePart(index) {
    var partsList = [...this.state.partsList]
    partsList.splice(index, 1)
    await this.setState({ partsList: partsList })
    this.validateForm()
  }

  removeLastPart() {
    if (
      this.state.selectedUpn !== '' ||
      this.state.reason !== '' ||
      this.state.quantity !== '' ||
      this.state.inventoryPin !== ''
    ) {
      this.setState({
        selectedUpn: '',
        selectedUpnLabel: '',
        reason: '',
        reasonLabel: '',
        quantity: '',
        inventoryPin: '',
      })
    }
    this.validateForm()
  }

  formatFreeTextList(textList) {
    let formattedTextList = ''
    if (textList.includes(',')) {
      let removedWhitespace = textList.replaceAll(' ', '')
      let splitItems = removedWhitespace.split(',')
      formattedTextList = [...new Set(splitItems)]
    } else if (textList.includes(' ')) {
      let splitItems = textList.split(' ')
      formattedTextList = [...new Set(splitItems)]
    } else if (textList.includes('\n')) {
      let splitItems = textList.split('\n')
      formattedTextList = [...new Set(splitItems)]
    } else {
      formattedTextList = [textList]
    }
    return formattedTextList
  }

  async createHwLog(event) {
    event.preventDefault()
    const { user } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Supplemental Hardware Log Form - Submit' },
      user: { id: user.email },
    })
    if (this.state.selectedUpn !== '') {
      await this.addPartRow()
    }

    const requestData = {
      requestor_name: this.state.requestorEmail,
      locations: this.formatFreeTextList(this.state.locations),
      tts_execution_team: this.state.ttsExecutionTeam,
      rnd_pin: this.state.rndPin,
      period: this.state.period,
      parts_information: this.state.partsList,
      delivery_date: this.state.deliveryDate,
      delivery_time: this.state.deliveryTime,
      shipping_to: this.state.shippingTo,
      business_name: this.state.businessName,
      shipping_address: this.state.shippingAddress,
      shipping_city: this.state.shippingCity,
      shipping_state: this.state.shippingState,
      shipping_zip: this.state.shippingZip,
      attention: this.state.attention,
      special_instructions: this.state.specialInstructions,
    }
    this.props.createHwLog(requestData)
    this.setState({ statusOpen: true })
    this.resetForm()
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
      eventsMessage: '',
    })
    this.props.clearMessage()
  }

  resetForm = () => {
    this.setState({
      ttsExecutionTeam: '',
      requestorEmail: '',
      partsList: [],
      deliveryTime: '',
      deliveryDate: '',
      shippingTo: '',
      ttsExecutionTeamLabel: '',
      requestorEmailLabel: '',
      deliveryTimeLabel: '',
      shippingToLabel: '',
      locations: [],
      attention: '',
      specialInstructions: '',
      rndPin: '',
      period: '',
      businessName: '',
      shippingAddress: '',
      shippingCity: '',
      shippingState: '',
      shippingZip: '',
      selectedUpn: '',
      selectedUpnLabel: '',
      reason: '',
      reasonLabel: '',
      quantity: '',
      inventoryPin: '',
      buttonEnabled: false,
      loading: false,
    })
  }

  validateForm = () => {
    if (
      this.state.ttsExecutionTeam !== '' &&
      this.state.requestorEmail !== '' &&
      this.state.locations !== [] &&
      this.state.deliveryTime !== '' &&
      this.state.deliveryDate !== '' &&
      this.state.shippingTo !== '' &&
      this.state.attention !== '' &&
      ((this.state.partsList.length > 0 &&
        ((this.state.selectedUpn === '' &&
          this.state.reason === '' &&
          this.state.quantity === '') ||
          (this.state.selectedUpn !== '' &&
            this.state.reason !== '' &&
            this.state.quantity !== ''))) ||
        (this.state.partsList.length === 0 &&
          this.state.selectedUpn !== '' &&
          this.state.reason !== '' &&
          this.state.quantity !== ''))
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    let modalTitle
    if (this.state.eventsMessage === 'Supplemental Hardware Log updated!') {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage !== 'Supplemental Hardware Log updated!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    let errorExists = false
    let ttsExecutionTeams = []
    let requestorEmails = []
    let reasons = []
    let deliveryTimes = []
    let shippingToOptions = []
    let upns = []
    if (this.state.hwLogOptions !== undefined) {
      if (this.state.hwLogOptions.messageString === '') {
        ttsExecutionTeams = this.state.hwLogOptions.ttsExecutionTeams.map(
          (team) => ({
            value: team,
            label: team,
          }),
        )
        requestorEmails = this.state.hwLogOptions.requestorEmails.map(
          (email) => ({
            value: email,
            label: email,
          }),
        )
        reasons = this.state.hwLogOptions.reasons.map((reason) => ({
          value: reason,
          label: reason,
        }))
        deliveryTimes = this.state.hwLogOptions.deliveryTimes.map((time) => ({
          value: time,
          label: time,
        }))
        shippingToOptions = this.state.hwLogOptions.shippingToOptions.map(
          (shippingTo) => ({
            value: shippingTo,
            label: shippingTo,
          }),
        )
        upns = this.state.hwLogOptions.upns.map((upn) => ({
          value: upn,
          label: upn,
        }))
      } else {
        errorExists = true
      }
    }

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {this.state.loading || errorExists ? (
          <div>
            {errorExists ? (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 5,
                }}
                className="progress"
              >
                <Typography className="loading">
                  {this.state.hwLogOptions.messageString +
                    ' Please contact the Smartsheet owner.'}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                spacing={16}
                justifyContent={'center'}
                className="progress"
              >
                <CircularProgress />
                <Typography className="loading">&nbsp;Loading...</Typography>
              </Grid>
            )}
          </div>
        ) : (
          <div>
            <CardActions style={{ width: '100%' }}>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 0,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography gutterBottom>
                  TTS R&D Procurement - Supplemental Hardware Log Form
                </Typography>
              </Grid>
            </CardActions>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              style={{
                alignItems: 'stretch',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'stretch',
                  display: 'flex',
                }}
              >
                <Grid container item style={{ paddingLeft: 8 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      flexBasis: '99%',
                    }}
                  >
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        style={{ width: '100%' }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: 0,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography gutterBottom>Requestor Name</Typography>
                        </Grid>
                        <CreatableSelect
                          value={this.state.requestorEmailLabel}
                          options={requestorEmails}
                          placeholder="Select Value or type your e-mail address if your name is not in the list”"
                          menuPosition="fixed"
                          onChange={(event) => {
                            this.setState(
                              {
                                requestorEmail: event.value,
                                requestorEmailLabel: event,
                              },
                              function () {
                                this.validateForm()
                              },
                            )
                          }}
                        />
                        <br />
                        {/* // This will be used when multiple stores are enabled
                        T#
                        <TextareaAutosize
                          htmlFor="locations"
                          type="text"
                          minRows={1}
                          maxRows={10}
                          value={this.state.locations}
                          placeholder="Enter Locations (Ex: T0003 T0004 ETC)"
                          label="Locations"
                          required
                          onChange={(event) =>
                            this.setState(
                              { locations: event.target.value },
                              function () {
                                this.validateForm()
                              }
                            )
                          }
                          style={{ width: '100%' }}
                        /> */}
                        <TextField
                          htmlFor="locations"
                          type="text"
                          variant="standard"
                          value={this.state.locations}
                          placeholder="Enter Value"
                          label="T#"
                          required
                          style={{ width: '100%' }}
                          onChange={(event) =>
                            this.setState(
                              { locations: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: 0,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography gutterBottom>
                            TTS Execution Team
                          </Typography>
                        </Grid>
                        <Select
                          value={this.state.ttsExecutionTeamLabel}
                          options={ttsExecutionTeams}
                          placeholder="Select Value"
                          menuPosition="fixed"
                          onChange={(event) => {
                            this.setState(
                              {
                                ttsExecutionTeam: event.value,
                                ttsExecutionTeamLabel: event,
                              },
                              function () {
                                this.validateForm()
                              },
                            )
                          }}
                        />
                        {this.state.ttsExecutionTeam === 'Bundle' ||
                        this.state.ttsExecutionTeam === 'CFT' ||
                        this.state.ttsExecutionTeam === 'GSCL' ||
                        this.state.ttsExecutionTeam === 'Headquarters' ||
                        this.state.ttsExecutionTeam === 'Special Projects' ||
                        this.state.ttsExecutionTeam === 'Procurement' ||
                        this.state.ttsExecutionTeam === 'New Stores' ||
                        this.state.ttsExecutionTeam === 'Remodel' ? (
                          <div>
                            <TextField
                              htmlFor="rndPin"
                              type="text"
                              variant="standard"
                              value={this.state.rndPin}
                              placeholder="Enter Value"
                              label="Billing R&D PIN"
                              required
                              style={{ width: '100%' }}
                              onChange={(event) =>
                                this.setState(
                                  { rndPin: event.target.value },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                            {this.state.ttsExecutionTeam !== 'New Stores' &&
                            this.state.ttsExecutionTeam !== 'Remodel' ? (
                              <TextField
                                htmlFor="period"
                                type="text"
                                variant="standard"
                                value={this.state.period}
                                placeholder="Enter Value"
                                label="Period"
                                required
                                style={{ width: '100%' }}
                                onChange={(event) =>
                                  this.setState(
                                    { period: event.target.value },
                                    function () {
                                      this.validateForm()
                                    },
                                  )
                                }
                                InputProps={{
                                  className: 'redUnderline',
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        <br />
                        <TextField
                          type="date"
                          value={this.state.deliveryDate}
                          variant="standard"
                          label="Requested Delivery Date"
                          helperText="Cut Off Times for Shipping Same Day: Configuration Required: 11:55AM (CST) No Configuration: 1:55PM (CST)"
                          style={{ width: '50%' }}
                          required
                          onChange={(event) =>
                            this.setState(
                              { deliveryDate: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <br />
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: 0,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography gutterBottom>
                            Requested Delivery Time
                          </Typography>
                        </Grid>
                        <Select
                          value={this.state.deliveryTimeLabel}
                          options={deliveryTimes}
                          placeholder="Select Value"
                          menuPosition="fixed"
                          onChange={(event) => {
                            this.setState(
                              {
                                deliveryTime: event.value,
                                deliveryTimeLabel: event,
                              },
                              function () {
                                this.validateForm()
                              },
                            )
                          }}
                        />
                        <br />
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: 0,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography gutterBottom>Shipping To</Typography>
                        </Grid>
                        <Select
                          value={this.state.shippingToLabel}
                          options={shippingToOptions}
                          placeholder="Select Value"
                          menuPosition="fixed"
                          onChange={(event) => {
                            this.setState(
                              {
                                shippingTo: event.value,
                                shippingToLabel: event,
                              },
                              function () {
                                this.validateForm()
                              },
                            )
                          }}
                        />
                        {this.state.shippingTo === 'Other' ? (
                          <div>
                            <TextField
                              htmlFor="businessName"
                              type="text"
                              variant="standard"
                              value={this.state.businessName}
                              placeholder="Enter Value"
                              label="Business Name"
                              helperText="Please provide the business name for the address you are shipping to. If shipping to a residential location, please provide the name of the recipient here."
                              required
                              style={{ width: '100%' }}
                              onChange={(event) =>
                                this.setState(
                                  { businessName: event.target.value },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                            <br />
                            <br />
                            <TextField
                              htmlFor="shippingAddress"
                              type="text"
                              variant="standard"
                              value={this.state.shippingAddress}
                              placeholder="Enter Value"
                              label="Shipping Address"
                              required
                              style={{ width: '100%' }}
                              onChange={(event) =>
                                this.setState(
                                  { shippingAddress: event.target.value },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                            <TextField
                              htmlFor="shippingCity"
                              type="text"
                              variant="standard"
                              value={this.state.shippingCity}
                              placeholder="Enter Value"
                              label="Shipping City"
                              required
                              style={{ width: '100%' }}
                              onChange={(event) =>
                                this.setState(
                                  { shippingCity: event.target.value },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                            <TextField
                              htmlFor="shippingState"
                              type="text"
                              variant="standard"
                              value={this.state.shippingState}
                              placeholder="Enter Value"
                              label="Shipping State"
                              required
                              style={{ width: '100%' }}
                              onChange={(event) =>
                                this.setState(
                                  { shippingState: event.target.value },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                            <TextField
                              htmlFor="shippingZip"
                              type="text"
                              variant="standard"
                              value={this.state.shippingZip}
                              placeholder="Enter Value"
                              label="Shipping Zip Code"
                              required
                              style={{ width: '100%' }}
                              onChange={(event) =>
                                this.setState(
                                  { shippingZip: event.target.value },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <br />
                        <TextField
                          htmlFor="attention"
                          type="text"
                          variant="standard"
                          value={this.state.attention}
                          placeholder="Enter Value"
                          label="Attention"
                          required
                          style={{ width: '100%' }}
                          onChange={(event) =>
                            this.setState(
                              { attention: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <TextField
                          htmlFor="specialInstructions"
                          type="text"
                          variant="standard"
                          value={this.state.specialInstructions}
                          label="Special Instructions"
                          helperText="Suggested Things to Include: -Special Shipping Instructions -Config Instructions -Site contact name & phone number (If expected to ship via pallet)"
                          style={{ width: '100%' }}
                          onChange={(event) =>
                            this.setState(
                              { specialInstructions: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <div>
                          UPNs (Universal Part Numbers)
                          <Box
                            sx={{
                              border: 1,
                              borderColor: 'grey.300',
                              borderRadius: 2,
                              display: 'grid',
                            }}
                            style={{ marginTop: '1%' }}
                          >
                            {this.state.partsList.length > 0
                              ? this.state.partsList.map((partInfo, index) => {
                                  return (
                                    <div>
                                      <Box
                                        sx={{
                                          border: '1px solid',
                                          borderColor: 'grey.300',
                                          m: 1,
                                          borderRadius: 2,
                                        }}
                                      >
                                        <Grid
                                          container
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '4%',
                                            width: '100%',
                                          }}
                                        >
                                          <Grid
                                            item
                                            lg={4}
                                            md={12}
                                            style={{
                                              marginBottom: '1%',
                                              marginLeft: '1%',
                                            }}
                                          >
                                            <FormControl
                                              style={{ width: '95%' }}
                                            >
                                              <br />
                                              <TextField
                                                htmlFor="description"
                                                type="text"
                                                variant="standard"
                                                value={partInfo.upn_description}
                                                label="UPN"
                                              />
                                            </FormControl>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={3}
                                            md={12}
                                            style={{
                                              marginBottom: '1%',
                                              marginLeft: '1%',
                                            }}
                                          >
                                            <FormControl
                                              style={{ width: '95%' }}
                                            >
                                              <br />
                                              <TextField
                                                htmlFor="reason"
                                                type="text"
                                                variant="standard"
                                                value={partInfo.reason}
                                                label="Reason for Order"
                                              />
                                            </FormControl>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={2}
                                            md={12}
                                            style={{
                                              marginBottom: '1%',
                                              marginLeft: '1%',
                                            }}
                                          >
                                            <FormControl
                                              style={{ width: '95%' }}
                                            >
                                              <br />
                                              <TextField
                                                htmlFor="inventoryPin"
                                                type="text"
                                                variant="standard"
                                                value={partInfo.inventory_pin}
                                                label="Inventory R&D PIN"
                                              />
                                            </FormControl>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={2}
                                            md={12}
                                            style={{
                                              marginBottom: '1%',
                                              marginLeft: '1%',
                                            }}
                                          >
                                            <FormControl
                                              style={{ width: '95%' }}
                                            >
                                              <br />
                                              <TextField
                                                htmlFor="quantity"
                                                type="text"
                                                variant="standard"
                                                value={partInfo.quantity}
                                                label="Quantity"
                                              />
                                            </FormControl>
                                          </Grid>
                                          <IconButton
                                            onClick={(event) =>
                                              this.removePart(index)
                                            }
                                            title={'Remove UPN'}
                                            size="large"
                                          >
                                            <RemoveIcon />
                                          </IconButton>
                                        </Grid>
                                      </Box>
                                    </div>
                                  )
                                })
                              : ''}
                            <div>
                              <Box
                                sx={{
                                  border: '1px solid',
                                  borderColor: 'grey.300',
                                  m: 1,
                                  borderRadius: 2,
                                }}
                              >
                                <Grid
                                  container
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '4%',
                                    width: '100%',
                                  }}
                                >
                                  <Grid
                                    item
                                    lg={4}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl
                                      sx={{
                                        width: '95%',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          UPN
                                        </Typography>
                                      </Grid>
                                      <Select
                                        value={this.state.selectedUpnLabel}
                                        options={upns}
                                        placeholder="Select Value"
                                        menuPosition="fixed"
                                        required
                                        onChange={(event) => {
                                          this.setState(
                                            {
                                              selectedUpn: event.value,
                                              selectedUpnLabel: event,
                                            },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={3}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '95%' }}>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          Reason for Order
                                        </Typography>
                                      </Grid>
                                      <Select
                                        value={this.state.reasonLabel}
                                        options={reasons}
                                        placeholder="Select Value"
                                        menuPosition="fixed"
                                        required
                                        onChange={(event) => {
                                          this.setState(
                                            {
                                              reason: event.value,
                                              reasonLabel: event,
                                            },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2}
                                    md={12}
                                    style={{
                                      marginTop: '1%',
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '95%' }}>
                                      <TextField
                                        htmlFor="inventoryPin"
                                        type="text"
                                        variant="standard"
                                        value={this.state.inventoryPin}
                                        placeholder="Enter Value"
                                        label="Inventory R&D PIN"
                                        onChange={(event) =>
                                          this.setState(
                                            {
                                              inventoryPin: event.target.value,
                                            },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }
                                        InputProps={{
                                          className: 'redUnderline',
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2}
                                    md={12}
                                    style={{
                                      marginTop: '1%',
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '95%' }}>
                                      <TextField
                                        htmlFor="quantity"
                                        type="text"
                                        variant="standard"
                                        value={this.state.quantity}
                                        placeholder="Enter Value"
                                        label="Quantity"
                                        required
                                        onChange={(event) =>
                                          this.setState(
                                            { quantity: event.target.value },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }
                                        InputProps={{
                                          className: 'redUnderline',
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <IconButton
                                    onClick={this.removeLastPart}
                                    title={'Remove UPN'}
                                    size="large"
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Grid>
                              </Box>
                            </div>
                            <div>
                              <IconButton
                                onClick={this.addPartRow}
                                title={'Add another UPN'}
                                size="large"
                              >
                                <Add />
                              </IconButton>
                            </div>
                          </Box>
                        </div>
                        <br />
                        <CardActions
                          style={{
                            width: '100%',
                            height: '30%',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: 0,
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              disabled={!this.state.buttonEnabled}
                              onClick={this.createHwLog}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </CardActions>
                        <br />
                      </FormControl>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
        <HardwareLogFormSuccessModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventsMessage={this.state.eventsMessage}
          title={modalTitle}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hwLogOptions: state.procurement.hwLogOptions,
    eventsMessage: state.procurement.eventsMessage,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  createHwLog,
  getHWLogOptions,
  clearMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(HardwareLogFormTab)
