// THIS FILE WAS PREVIOUSLY PART OF THE DEPRECATED REACT-PRAXIS-COMPONENTS LIBRARY.
// THE RECOMMENDED FIX FROM THE PRAXIS TEAM IS TO COPY & PASTE NEEDED COMPONENTS
// INTO YOUR PROJECT

import { node } from 'prop-types'

export const Header = (props) => {
  const { children, ...additionalProps } = props
  return <div {...additionalProps}>{children}</div>
}

Header.propTypes = {
  children: node,
}
Header.displayName = 'Header'

export default Header
