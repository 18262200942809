import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class PinHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Control Room PIN Help</DialogTitle>
        <DialogContent>
          <h4>Instructions to setup automated control room PIN generation.</h4>
          <p>
            Before enabling PIN Generation on a Smartsheet, ensure your project
            sheet is setup correctly. Then in ziptie find the project sheet and
            Enable the automation. This is done by clicking the toggle on the
            far right (turns green when enabled, and generates PINs for projects
            in sheet that have all information present). An email with the
            PIN(s) will be sent to PIN Contact.
          </p>
          <h4>Instructions to revoke PINs.</h4>
          <p>
            Click the Revoke PINs button (next to the help button). Enter the
            email address for the PIN contact that was used when PIN was created
            for that location. Enter a comma separated list of stores without T
            or leading zeros. After clicking the Revoke button a message will
            display which locations were successful or failed. If all locations
            for the specific PIN is revoked, the PIN contact will receive a
            revoke email.
          </p>
          <h4>FAQs.</h4>
          <p>
            For more information on PINs or for frequently asked questions,
            please see the{' '}
            <a
              href="https://app.smartsheet.com/dashboards/wJ4hF5J24pwxvmFgwX94XQ2vHq2XV7PXHf8j8j91"
              target="_blank"
              rel="noopener noreferrer"
            >
              TTS R&D Software Engineering Portal
            </a>{' '}
            in Smartsheet.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handlePinHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
