// THIS FILE WAS PREVIOUSLY PART OF THE DEPRECATED REACT-PRAXIS-COMPONENTS LIBRARY.
// THE RECOMMENDED FIX FROM THE PRAXIS TEAM IS TO COPY & PASTE NEEDED COMPONENTS
// INTO YOUR PROJECT

import { node } from 'prop-types'

export const Footer = (props) => {
  const { children, ...additionalProps } = props
  return <div {...additionalProps}>{children}</div>
}

Footer.propTypes = {
  children: node,
}
Footer.displayName = 'Footer'

export default Footer
