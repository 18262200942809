import * as types from './actionType'

export const initialState = {
  result: '',
  activeProjectsList: [],
}

export default function automationTestsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.CHECK_MISSING_COLUMNS: {
      const newState = {
        ...state,
        webhookStatusMessage: action.payload,
        webhookFailureOpen: true,
      }
      return newState
    }
    case types.POST_AUTOMATION_HOOK: {
      const newState = {
        ...state,
        eventsMessage: action.payload,
      }
      return newState
    }
    case types.GET_PROJECT_SHEETS_AND_HOOKS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeProjectsList: action.payload,
            eventsMessage: '',
            newWebhookData: {},
          }
        : {
            ...state,
            activeProjectsList: [action.payload],
            eventsMessage: '',
            newWebhookData: {},
          }
      return newState
    }
    case types.RESET_SUCCESS_MESSAGE: {
      const newState = {
        ...state,
        webhookStatusMessage: action.payload.webhookStatusMessage,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
