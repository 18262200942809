import { object, string, func } from 'prop-types'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import logo from '../../images/Ziptie_White.png'
import { Box } from '@mui/material'

export function Header({ classes, title, menuAction }) {
  return (
    <Box className={classes.header}>
      <AppBar className={classes.appBar} color="primary">
        <Toolbar>
          {menuAction && (
            <IconButton
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
              size="large"
              sx={{ color: 'primary.contrastText' }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img src={logo} alt=" " className={classes.logo} />
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Header.displayName = 'Header'

Header.propTypes = {
  classes: object,
  title: string.isRequired,
  menuAction: func,
}

Header.defaultProps = {
  classes: {},
}

const hasSecondaryNavClass = 'hasSecondaryNav'
const appBarStyles = {
  [`.${hasSecondaryNavClass} &`]: {
    paddingBottom: 48,
  },
}

const styles = (theme) => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  appBar: {
    ...appBarStyles,
  },
  logo: {
    height: 40,
    margin: '0 18px 0 6px',
    width: 30,
  },
})

export default withStyles(styles)(Header)
