// Adds T and leading 0s to location number in display
export function formatLocationNumber(locationNum) {
  if (locationNum.toString().toUpperCase() !== 'LOCATION_ID') {
    var locationId = '' + locationNum.toString().replace(/\D0*/g, '')
    if (locationId.length) {
      return locationId.padStart(5, 'T000')
    } else {
      return ''
    }
  } else {
    return locationNum
  }
}

// Removes T and leading 0s from user Input
export function truncateLocationId(userInput) {
  if (userInput.toString().toUpperCase() !== 'LOCATION_ID') {
    return userInput.replace(/^\D*0*/g, '')
  } else {
    return userInput
  }
}
