import * as types from './actionType'

export const initialState = {
  webhooksList: [],
  activeSheets: [],
  sheetColumns: [],
  workspaces: [],
}

export default function webhooksReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CHECK_MISSING_COLUMNS: {
      const newState = {
        ...state,
        webhookSuccessMessage: action.payload,
        webhookSuccessOpen: true,
      }
      return newState
    }
    case types.GET_WEBHOOK_LIST: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            webhooksList: action.payload,
          }
        : {
            ...state,
            webhooksList: [action.payload],
          }
      return newState
    }
    case types.GET_ACTIVE_SHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeSheets: action.payload,
          }
        : {
            ...state,
            activeSheets: [action.payload],
          }
      return newState
    }
    case types.GET_SHEET_COLUMNS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            sheetColumns: action.payload,
          }
        : {
            ...state,
            sheetColumns: [action.payload],
          }
      return newState
    }
    case types.GET_WORKSPACES: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            workspaces: action.payload,
          }
        : {
            ...state,
            workspaces: [action.payload],
          }
      return newState
    }
    case types.RESET_FORM: {
      const newState = {
        ...state,
        activeSheets: [],
        sheetColumns: [],
        workspaces: [],
      }
      return newState
    }
    case types.RESET_SUCCESS_MESSAGE: {
      const newState = {
        ...state,
        webhookSuccessMessage: action.payload.webhookSuccessMessage,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
