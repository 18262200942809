import React from 'react'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Grid, Typography, CardContent } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import MUIDataTable from 'mui-datatables'
import withStyles from '@mui/styles/withStyles'
import { getDeviceStatus } from '../../../store/DeviceStatuses/actionCreator'
import classNames from 'classnames'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'
import { initializeFireflySDK } from '../../../common/FireflyHelper'

const styles = () => ({
  onlineCell: {
    backgroundColor: '#228B22',
    color: 'white',
    fontSize: 20,
  },
  offlineCell: {
    backgroundColor: '#FF0000',
    color: 'white',
    fontSize: 20,
  },
  otherCell: {
    backgroundColor: '#FFFF33',
    color: 'black',
    fontSize: 20,
  },
})

export class StoreDeviceStatuses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deviceList: [],
      loading: false,
      storesInput: '',
    }
    this.handleOnSearch = this.handleOnSearch.bind(this)
    this.baseState = { ...this.state }
    initializeFireflySDK()
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Store Device Statuses Tab' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.deviceList.length) {
      this.setState({
        deviceList: JSON.parse(JSON.stringify(nextProps.deviceList)),
      })
    }
  }

  async handleOnSearch(event) {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Store Device Statuses - Submit' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    this.setState({ ...this.baseState, loading: true })
    await this.props.getDeviceStatus(this.state.storesInput)
    this.setState({ loading: false })
  }

  render() {
    const { classes } = this.props
    let devices = []
    if (this.state.deviceList.length > 0) {
      const deviceColumns = [
        { label: 'Hostname', name: 'name' },
        { label: 'Type', name: 'type' },
        {
          label: 'Status',
          name: 'status',
          options: {
            setCellProps: (value) => {
              return {
                className: classNames({
                  [classes.onlineCell]: value === 'online',
                  [classes.offlineCell]: value === 'offline',
                  [classes.otherCell]:
                    value !== 'offline' && value !== 'online',
                }),
              }
            },
          },
        },
      ]

      devices = (
        <MUIDataTable
          title="Devices"
          columns={deviceColumns}
          data={this.props.deviceList}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            rowsPerPageOptions: [10, 20, 50],
            selectableRows: 'none',
            sortOrder: {
              name: 'status',
              direction: 'asc',
            },
            tableBodyMaxHeight: '700px',
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      devices = this.state.deviceList
    }

    const devicePanel = <div>{devices}</div>

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item style={{ width: 1100 }}>
                <form onSubmit={this.handleOnSearch}>
                  <TextField
                    type="text"
                    variant="standard"
                    id="standard-multiline-flexible"
                    multiline
                    maxRows="4"
                    value={this.state.storesInput}
                    onChange={(event) =>
                      this.setState({ storesInput: event.target.value })
                    }
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' || event.key === 'Return') {
                        this.handleOnSearch(event)
                      }
                    }}
                    placeholder="Enter a comma separated list of store number(s)"
                    required
                    sx={{ width: 500, height: 80 }}
                    InputProps={{ className: 'redUnderline' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.storesInput}
                    type="submit"
                    sx={{ margin: '8px' }}
                  >
                    submit
                  </Button>
                </form>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                {this.state.deviceList.length > 0 ? (
                  typeof this.state.deviceList[0] === 'string' ? (
                    <Typography variant="h4">
                      {this.state.deviceList}
                    </Typography>
                  ) : (
                    devicePanel
                  )
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    deviceList: state.deviceStatuses.deviceList,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  getDeviceStatus,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(StoreDeviceStatuses))
