import React from 'react'
import Select, { components } from 'react-select'
import { connect } from 'react-redux'
import {
  Button,
  Grid,
  Typography,
  CardActions,
  CardContent,
} from '@mui/material'
import { SsGroupList } from './SsGroupList'
import { SsGroupsModal } from './SsGroupsModal'
import {
  downloadGroupSheet,
  getGroupMembers,
  getGroupNames,
  setResultMessage,
  toggleStatusOpen,
} from '../../../store/AdditionalTools/actionCreator'
import apiConfig from '../../../config/apiConfig'
import Firefly from 'firefly-sdk-ts'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export class SsGroupsAuditTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonEnabled: false,
      downloadButtonEnabled: false,
      fileData: '',
      fileName: '',
      groupsAndMembers: [],
      loading: false,
      menuOpen: false,
      resultMessage: '',
      selectedGroups: [],
      smartsheetGroups: [],
      statusOpen: false,
    }
    this.baseState = this.state
    this.fileDownload = React.createRef()
    this.createMemberListSheet = this.createMemberListSheet.bind(this)
    this.downloadAll = this.downloadAll.bind(this)
    this.handleGroupSelect = this.handleGroupSelect.bind(this)
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.resetMessage = this.resetMessage.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    await this.props.getGroupNames()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Smartsheet Groups Audit Tab' },
      user: { id: userInfo.email },
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.smartsheetGroups !== nextProps.smartsheetGroups) {
      this.setState({
        smartsheetGroups: nextProps.smartsheetGroups,
      })
    }
    if (this.props.fileName !== nextProps.fileName) {
      const url = window.URL.createObjectURL(nextProps.fileData)
      this.setState(
        {
          fileData: url,
          fileName: nextProps.fileName,
        },
        function () {
          this.fileDownload.current.click()
          this.props.toggleStatusOpen(false)
        },
      )
    }
    if (this.props.groupsAndMembers !== nextProps.groupsAndMembers) {
      let buttonEnabled = nextProps.groupsAndMembers.length > 0 ? true : false
      this.setState({
        downloadButtonEnabled: buttonEnabled,
        groupsAndMembers: nextProps.groupsAndMembers,
      })
    }
    if (this.props.statusOpen !== nextProps.statusOpen) {
      this.setState({
        statusOpen: nextProps.statusOpen,
      })
    }
    if (this.props.resultMessage !== nextProps.resultMessage) {
      this.setState({
        resultMessage: nextProps.resultMessage,
      })
    }
  }

  createMemberListSheet(event, groupData) {
    event.preventDefault()
    this.props.toggleStatusOpen(true)
    this.props.downloadGroupSheet(groupData)
  }

  downloadAll(event) {
    this.state.groupsAndMembers.forEach((group) => {
      this.createMemberListSheet(event, group)
    })
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Smartsheet Groups Audit - Download All' },
      user: { id: userInfo.email },
    })
  }

  handleGroupSelect(selected) {
    this.setState(
      {
        menuOpen: true,
        selectedGroups: selected,
      },
      function () {
        this.validateForm()
      },
    )
  }

  handleMenuOpen(open) {
    this.setState({
      menuOpen: open,
    })
  }

  handleStatusClose() {
    this.props.toggleStatusOpen(false)
  }

  handleOnSubmit(event) {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Smartsheet Groups Audit - Submit' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    const formattedGroups = this.state.selectedGroups.map(
      (group) => group.value.id,
    )
    this.props.getGroupMembers(formattedGroups)
  }

  resetMessage() {
    this.props.setResultMessage('')
  }

  validateForm() {
    if (this.state.selectedGroups.length > 0) {
      this.setState({ buttonEnabled: true })
    } else this.setState({ buttonEnabled: false })
    if (this.state.groupsAndMembers.length > 0) {
      this.setState({ downloadButtonEnabled: true })
    } else this.setState({ downloadButtonEnabled: false })
  }

  render() {
    let availableGroups
    if (this.state.smartsheetGroups.length > 0) {
      availableGroups = this.state.smartsheetGroups.map((group) => ({
        value: group,
        label: group.name,
      }))
    } else {
      availableGroups = []
    }

    let groupsAndMembers
    if (this.state.groupsAndMembers.length > 0) {
      groupsAndMembers = this.state.groupsAndMembers.map((group) => {
        return (
          <SsGroupList
            key={group.id}
            group={group}
            onClick={this.createMemberListSheet}
          />
        )
      })
    } else {
      groupsAndMembers = <div></div>
    }

    let modalTitle
    if (
      this.state.resultMessage !== undefined &&
      this.state.resultMessage !== ''
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom sx={{ fontSize: '16px' }}>
              Select Smartsheet Groups you wish to view.
            </Typography>
            <br />
            <br />
          </Grid>
        </CardActions>
        <form onSubmit={this.handleOnSubmit}>
          <Grid container spacing={1} sx={{ margin: '-4px' }}>
            <Grid item xs={12} md={6} lg={8}>
              <Select
                isMulti
                value={this.state.selectedGroups}
                options={availableGroups}
                placeholder="Smartsheet Groups"
                onChange={this.handleGroupSelect}
                inputProps={{
                  name: 'smartsheetGroups',
                  id: 'smartsheetGroups',
                }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                style={{
                  width: '70%',
                  margin: '2%',
                }}
                blurInputOnSelect={false}
                onFocus={() => {
                  this.handleMenuOpen(true)
                }}
                onBlur={() => {
                  this.handleMenuOpen(false)
                }}
                menuIsOpen={this.state.menuOpen}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                allowSelectAll={true}
              />
            </Grid>
            <Grid container item xs={12} md={6} lg={3}>
              <CardActions
                style={{
                  width: '90%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.buttonEnabled}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    className=".download"
                    variant="contained"
                    onClick={this.downloadAll}
                    disabled={!this.state.downloadButtonEnabled}
                    sx={{
                      // color: 'secondary.dark',
                      color: 'secondary.main',
                      backgroundColor: 'secondary.light',
                    }}
                  >
                    Download All
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={1}>
          {groupsAndMembers}
        </Grid>
        <SsGroupsModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          title={modalTitle}
          resultMessage={this.state.resultMessage}
          clearMessage={this.resetMessage}
        />
        <a
          href={this.state.fileData}
          download={this.state.fileName}
          ref={this.fileDownload}
          style={{ display: 'none' }}
        >
          ''
        </a>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fileData: state.additionalTools.fileData,
    fileName: state.additionalTools.fileName,
    groupsAndMembers: state.additionalTools.groupsAndMembers,
    resultMessage: state.additionalTools.resultMessage,
    smartsheetGroups: state.additionalTools.smartsheetGroups,
    statusOpen: state.additionalTools.statusOpen,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  downloadGroupSheet,
  getGroupMembers,
  getGroupNames,
  setResultMessage,
  toggleStatusOpen,
}

export default connect(mapStateToProps, mapDispatchToProps)(SsGroupsAuditTab)
