import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Select from 'react-select'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../config/apiConfig'
import CreateScopeSheetHelpDialog from './CreateScopeSheetHelpDialog'
import CreateScopeSheetSuccessModal from './CreateScopeSheetSuccessModal'
import {
  clearMessage,
  createScopeSheet,
  getAutomationTypes,
  getWorkspaces,
  getWorkspaceFolders,
} from '../../store/CreateSmartsheets/actionCreator'

export class CreateScopeTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      additionalAutomationTypes: '',
      availableAutomationTypes: [],
      buttonEnabled: false,
      destinationFolder: '',
      eventsMessage: '',
      helpOpen: false,
      locations: '',
      selectedAutomationTypes: [],
      sheetName: '',
      smartsheetData: [],
      statusOpen: false,
      workspace: '',
      workspaces: [],
      workspaceFolders: [],
    }
    this.createScopeSheet = this.createScopeSheet.bind(this)
    this.handleHelpClose = this.handleHelpClose.bind(this)
    this.handleHelpOpen = this.handleHelpOpen.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    await this.props.getWorkspaces()
    await this.props.getAutomationTypes()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Create Scope Sheet Tab' },
      user: { id: userInfo.email },
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.availableAutomationTypes !== nextProps.availableAutomationTypes
    ) {
      this.setState({
        availableAutomationTypes: JSON.parse(
          JSON.stringify(nextProps.availableAutomationTypes),
        ),
      })
    }
    if (this.props.workspaces !== nextProps.workspaces) {
      this.setState({
        workspaces: JSON.parse(JSON.stringify(nextProps.workspaces)),
      })
    }
    if (this.props.workspaceFolders !== nextProps.workspaceFolders) {
      this.setState({
        workspaceFolders: JSON.parse(
          JSON.stringify(nextProps.workspaceFolders),
        ),
      })
    }
    if (
      this.props.smartsheetData !== nextProps.smartsheetData ||
      this.props.eventsMessage !== nextProps.eventsMessage
    ) {
      this.setState({
        smartsheetData: nextProps.smartsheetData,
        eventsMessage: nextProps.eventsMessage,
      })
    }
  }

  createScopeSheet = (
    event,
    destinationFolderId,
    sheetName,
    selectedAutomationTypes,
    additionalAutomationTypes,
    locations,
    user,
  ) => {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Create Scope Sheet - Submit' },
      user: { id: user.email },
    })
    event.preventDefault()
    this.props.clearMessage()
    this.props.createScopeSheet(
      destinationFolderId,
      sheetName,
      selectedAutomationTypes.map((type) => type.value),
      additionalAutomationTypes,
      locations,
    )

    this.setState({ statusOpen: true })
    this.resetForm()
  }

  handleHelpClose() {
    this.setState({ helpOpen: false })
  }

  handleHelpOpen() {
    this.setState({ helpOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  resetForm = () => {
    this.setState({
      workspace: '',
      destinationFolder: '',
      sheetName: '',
      selectedAutomationTypes: [],
      additionalAutomationTypes: '',
      locations: '',
      buttonEnabled: false,
    })
  }

  validateForm = () => {
    if (
      this.state.destinationFolder !== '' &&
      this.state.sheetName !== '' &&
      this.state.locations !== '' &&
      (this.state.selectedAutomationTypes.length > 0 ||
        this.state.additionalAutomationTypes !== '')
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    let modalTitle
    if (this.state.eventsMessage === 'Smartsheet(s) created!') {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage !== 'Smartsheet(s) created!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Select all applicable options for the scope sheet to you wish to
              create.
            </Typography>
            <Tooltip title={'Help'}>
              <IconButton onClick={this.handleHelpOpen} size="large">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </CardActions>
        <form
          onSubmit={(event) =>
            this.createScopeSheet(
              event,
              this.state.destinationFolder.value,
              this.state.sheetName,
              this.state.selectedAutomationTypes,
              this.state.additionalAutomationTypes,
              this.state.locations,
              this.props.user,
            )
          }
          style={{
            alignItems: 'stretch',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid container item style={{ paddingLeft: 8 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexBasis: '90%',
                }}
              >
                <Grid item xs={14} md={10}>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <Select
                      value={this.state.workspace}
                      options={
                        this.state.workspaces.length > 0
                          ? this.state.workspaces.map((workspace) => ({
                              label: workspace.name,
                              value: workspace.id,
                            }))
                          : ''
                      }
                      placeholder="Destination Workspace"
                      onChange={(event) => {
                        this.setState(
                          {
                            workspace: event,
                          },
                          function () {
                            this.validateForm()
                            this.props.getWorkspaceFolders(
                              this.state.workspace.value,
                            )
                          },
                        )
                      }}
                    />
                  </FormControl>
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <Select
                      value={this.state.destinationFolder}
                      options={
                        this.state.workspaceFolders.length > 0
                          ? this.state.workspaceFolders.map((folder) => ({
                              label: folder.name,
                              value: folder.id,
                            }))
                          : ''
                      }
                      placeholder="Destination Folder"
                      onChange={(event) => {
                        this.setState(
                          {
                            destinationFolder: event,
                          },
                          function () {
                            this.validateForm()
                          },
                        )
                      }}
                    />
                  </FormControl>
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <TextField
                    htmlFor="sheetName"
                    type="text"
                    variant="standard"
                    value={this.state.sheetName}
                    placeholder="Access Points Flight 1 Scope"
                    label="Sheet Name"
                    required
                    style={{ width: '100%' }}
                    onChange={(event) =>
                      this.setState(
                        { sheetName: event.target.value },
                        function () {
                          this.validateForm()
                        },
                      )
                    }
                    InputProps={{
                      className: 'redUnderline',
                    }}
                  />
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <Select
                    value={this.state.selectedAutomationTypes}
                    isMulti
                    closeMenuOnSelect={false}
                    options={
                      this.state.availableAutomationTypes.length > 0
                        ? this.state.availableAutomationTypes.map(
                            (availableAutomationType) => ({
                              label: availableAutomationType,
                              value: availableAutomationType,
                            }),
                          )
                        : ''
                    }
                    placeholder="Automation Types"
                    onChange={(event) => {
                      this.setState(
                        {
                          selectedAutomationTypes: event,
                        },
                        function () {
                          this.validateForm()
                        },
                      )
                    }}
                  />
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <FormControl style={{ width: '100%' }}>
                    <TextareaAutosize
                      htmlFor="additionalAutomationTypes"
                      type="text"
                      minRows={2}
                      maxRows={18}
                      value={this.state.additionalAutomationTypes}
                      placeholder="Enter Additional Automation Types (Ex: myDevice, Doorbells)"
                      label="Additional Automation Types"
                      onChange={(event) =>
                        this.setState(
                          { additionalAutomationTypes: event.target.value },
                          function () {
                            this.validateForm()
                          },
                        )
                      }
                      style={{ width: '100%' }}
                    />
                  </FormControl>
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <FormControl style={{ width: '100%' }}>
                    <TextareaAutosize
                      htmlFor="locations"
                      type="text"
                      minRows={8}
                      maxRows={18}
                      value={this.state.locations}
                      placeholder="Enter Locations (Ex: T0003 T0004 ETC)"
                      label="Locations"
                      required
                      onChange={(event) =>
                        this.setState(
                          { locations: event.target.value },
                          function () {
                            this.validateForm()
                          },
                        )
                      }
                      style={{ width: '100%' }}
                    />
                  </FormControl>
                </Grid>
              </div>
              <CardActions
                style={{
                  width: '100%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.buttonEnabled}
                  >
                    Submit
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <CreateScopeSheetHelpDialog
          open={this.state.helpOpen}
          handleHelpClose={this.handleHelpClose}
        />
        <CreateScopeSheetSuccessModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventsMessage={this.state.eventsMessage}
          smartsheetData={this.state.smartsheetData}
          title={modalTitle}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    eventsMessage: state.createSmartsheets.eventsMessage,
    smartsheetData: state.createSmartsheets.smartsheetData,
    availableAutomationTypes: state.createSmartsheets.availableAutomationTypes,
    workspaces: state.createSmartsheets.workspaces,
    workspaceFolders: state.createSmartsheets.workspaceFolders,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  clearMessage,
  createScopeSheet,
  getAutomationTypes,
  getWorkspaces,
  getWorkspaceFolders,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateScopeTab)
