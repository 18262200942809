import * as types from './actionType'

export const initialState = {
  locationProjects: [],
  locationIncidents: [],
  locationInformation: [],
}

export default function locationProjectsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.GET_LOCATION_PROJECTS: {
      const newState = Array.isArray(action.payload.projects)
        ? {
            ...state,
            locationProjects: action.payload.projects,
            location: action.payload.locationId,
          }
        : {
            ...state,
            locationProjects: [action.payload.projects],
            location: action.payload.locationId,
          }
      return newState
    }
    case types.GET_LOCATION_INCIDENTS: {
      const newState = Array.isArray(action.payload.incidents)
        ? {
            ...state,
            locationIncidents: action.payload.incidents,
            location: action.payload.locationId,
          }
        : {
            ...state,
            locationIncidents: [action.payload.incidents],
            location: action.payload.locationId,
          }
      return newState
    }
    case types.GET_LOCATION_INFORMATION: {
      const newState = Array.isArray(action.payload.locationInformation)
        ? {
            ...state,
            locationInformation: action.payload.locationInformation,
            location: action.payload.locationId,
          }
        : {
            ...state,
            locationInformation: [action.payload.locationInformation],
            location: action.payload.locationId,
          }
      return newState
    }
    default: {
      return state
    }
  }
}
