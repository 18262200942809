import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function clearResultMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_RESULT_MESSAGE,
      payload: '',
    })
  }
}

export function getActiveSheets() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${apiConfig.scopeSheetWorkspace}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_ACTIVE_SHEETS,
          payload: resp.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets.'
          dispatch({
            type: types.GET_ACTIVE_SHEETS,
            payload: result,
          })
        }
      })
  }
}

export function getConfigurationTypes(sheetId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/configuration_types?smartsheet_id=${sheetId}`)
      .then((resp) => {
        const availableConfigurationTypesLocations = [
          ...new Set(resp.data.map((type) => type)),
        ]
        const typesLocationsSteps = {
          typesAndLocationsList: availableConfigurationTypesLocations.sort(
            (a, b) => (a.first > b.first ? 1 : -1),
          ),
        }
        dispatch({
          type: types.GET_CONFIGURATION_TYPES,
          payload: typesLocationsSteps,
        })
      })
      .catch(() => {
        const errorPayload = {
          typesAndLocationsList: [
            {
              first: 'Error Loading Configuration Types.',
              second: [],
              third: [],
            },
          ],
        }
        dispatch({
          type: types.GET_CONFIGURATION_TYPES,
          payload: errorPayload,
        })
      })
  }
}

export function initiateConfigurationStep(
  type,
  step,
  smartsheetId,
  locationsList,
  lanId,
) {
  const configurationData = {
    lan_id: lanId,
    configuration_type: type,
    locations: locationsList,
    smartsheet_id: smartsheetId,
    step: step,
  }
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/configure_devices`, configurationData)
      .then((resp) => {
        dispatch({
          type: types.INITIATE_CONFIGURATION_STEP,
          payload: resp.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: types.INITIATE_CONFIGURATION_STEP,
          payload: error.response.data,
        })
      })
  }
}

export function toggleStatusOpen(statusOpen) {
  return function (dispatch) {
    dispatch({
      type: types.TOGGLE_STATUS_OPEN,
      payload: statusOpen,
    })
  }
}
