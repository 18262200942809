import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function getActiveSheets(workspaceId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${workspaceId}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_ACTIVE_SHEETS,
          payload: resp.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_ACTIVE_SHEETS,
            payload: result,
          })
        }
      })
  }
}

export function getWorkspaces() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/workspaces`)
      .then((resp) => {
        const workspaces = resp.data
        dispatch({
          type: types.GET_WORKSPACES,
          payload: workspaces,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_WORKSPACES,
            payload: result,
          })
        }
      })
  }
}

export function createCablingTypes(cableTypeData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    var result = []
    await axios
      .get(
        `${apiConfig.api.url}/cabling_types?smartsheet_id=${cableTypeData.smartsheetId}&execution_period=${cableTypeData.executionPeriod}&year=${cableTypeData.year}`,
      )
      .then((resp) => {
        result = { eventMessage: resp.data }
        dispatch({
          type: types.CREATE_CABLING_TYPES,
          payload: result,
        })
      })
      .catch(() => {
        dispatch({
          type: types.CREATE_CABLING_TYPES,
          payload: {
            eventMessage:
              'Encountered an unknown error, please contact the RDSE team.',
          },
        })
      })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}

export function getLayoutCutsheets() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/folder_contents?folder_id=${apiConfig.newStoresCutsheetFolder}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_LAYOUT_CUTSHEETS,
          payload: resp.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        })
      })
      .catch((error) => {
        if (error.response) {
          const result = 'Error loading cutsheets.'
          dispatch({
            type: types.GET_LAYOUT_CUTSHEETS,
            payload: result,
          })
        }
      })
  }
}

export function openErrorDialog(errorMessage) {
  return function (dispatch) {
    const payload = {
      layoutErrorMessage: errorMessage,
      layoutErrorOpen: true,
    }
    dispatch({
      type: types.OPEN_ERROR_DIALOG,
      payload: payload,
    })
  }
}

export function resetCablingForm() {
  return function (dispatch) {
    const payload = {
      layoutCutsheetId: '',
      layoutErrorMessage: '',
      layoutErrorOpen: false,
      swtCount: [''],
    }
    dispatch({
      type: types.RESET_CABLING_FORM,
      payload: payload,
    })
  }
}

function downloadPatchPanelLayout(
  layoutCutsheetId,
  layoutCutsheetName,
  swtCount,
) {
  return async function (dispatch) {
    axios
      .get(
        `${apiConfig.api.url}/patch_panel_layouts?smartsheet_id=${Number(
          layoutCutsheetId,
        )}&swt_count=${swtCount.join(',')}`,
        { responseType: 'arraybuffer' },
      )
      .then((resp) => {
        const responseData = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        const tNumberRegexPattern = new RegExp(/[T][0-9]{1,4}/)
        const cutsheetTNumber = layoutCutsheetName.match(tNumberRegexPattern)
        var excelFileName = 'Patch_Panel_Layout.xlsx'
        if (cutsheetTNumber !== null) {
          excelFileName = cutsheetTNumber[0] + '_' + excelFileName
        }
        if (resp.headers.message === 'Success' || resp.headers.message === '') {
          dispatch({
            type: types.DOWNLOAD_LAYOUT,
            payload: {
              fileData: responseData,
              fileName: excelFileName,
              layoutErrorMessage: '',
              layoutErrorOpen: false,
            },
          })
        } else {
          dispatch({
            type: types.DOWNLOAD_LAYOUT,
            payload: {
              fileData: responseData,
              fileName: 'Incomplete_' + excelFileName,
              layoutErrorMessage: resp.headers.message,
              layoutErrorOpen: true,
            },
          })
        }
      })
      .catch(() => {
        dispatch({
          type: types.DOWNLOAD_LAYOUT,
          payload: {
            fileData: '',
            fileName: '',
            layoutErrorMessage:
              'Something went wrong. Please contact RDSE team for assistance.',
            layoutErrorOpen: true,
          },
        })
      })
  }
}

export function downloadSwitchLayout(
  layoutCutsheetId,
  layoutCutsheetName,
  swtCount,
) {
  return async function (dispatch) {
    axios
      .get(
        `${apiConfig.api.url}/switch_layouts?smartsheet_id=${Number(
          layoutCutsheetId,
        )}&swt_count=${swtCount}`,
        { responseType: 'arraybuffer' },
      )
      .then((resp) => {
        if (resp.headers.message === 'Success' || resp.headers.message === '') {
          const responseData = new Blob([resp.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const tNumberRegexPattern = new RegExp(/[T][0-9]{1,4}/)
          const cutsheetTNumber = layoutCutsheetName.match(tNumberRegexPattern)
          var excelFileName = 'Switch_Layout.xlsx'
          if (cutsheetTNumber !== null) {
            excelFileName = cutsheetTNumber[0] + '_' + excelFileName
          }
          dispatch({
            type: types.DOWNLOAD_LAYOUT,
            payload: {
              fileData: responseData,
              fileName: excelFileName,
              layoutErrorMessage: '',
              layoutErrorOpen: false,
            },
          })
          dispatch(
            downloadPatchPanelLayout(
              layoutCutsheetId,
              layoutCutsheetName,
              swtCount,
            ),
          )
        } else {
          dispatch({
            type: types.DOWNLOAD_LAYOUT,
            payload: {
              fileData: '',
              fileName: '',
              layoutErrorMessage: resp.headers.message,
              layoutErrorOpen: true,
            },
          })
        }
      })
      .catch(() => {
        dispatch({
          type: types.DOWNLOAD_LAYOUT,
          payload: {
            fileData: '',
            fileName: '',
            layoutErrorMessage:
              'Something went wrong. Please contact RDSE team for assistance.',
            layoutErrorOpen: true,
          },
        })
      })
  }
}
