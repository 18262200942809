import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  LinearProgress,
  Typography,
} from '@mui/material/'

export default class IpPhoneModal extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        TransitionProps={{ onExited: this.props.clearMessage }}
      >
        <Fade in={this.props.open}>
          {this.props.resultMessage === '' ? (
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
          ) : (
            <div>
              <DialogTitle>{this.props.title}</DialogTitle>
              <DialogContent>
                <div>
                  <Typography className="modalBody" gutterBottom>
                    {this.props.resultMessage}
                  </Typography>
                  <br />
                  <Button variant="contained" onClick={this.props.handleClose}>
                    OK
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Fade>
      </Dialog>
    )
  }
}
