// THIS FILE WAS PREVIOUSLY PART OF THE DEPRECATED REACT-PRAXIS-COMPONENTS LIBRARY.
// THE RECOMMENDED FIX FROM THE PRAXIS TEAM IS TO COPY & PASTE NEEDED COMPONENTS
// INTO YOUR PROJECT
import ListItemText from '@mui/material/ListItemText'

export const Text = (props) => {
  const { children, ...additionalProps } = props
  return <ListItemText primary={children} {...additionalProps} />
}

Text.displayName = 'Text'

export default Text
