import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Typography, CardContent } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import withStyles from '@mui/styles/withStyles'
import { getImagesoundPlayers } from '../../../store/DeviceStatuses/actionCreator'
import classNames from 'classnames'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'

const styles = () => ({
  onlineCell: {
    backgroundColor: '#228B22',
    color: 'white',
    fontSize: 20,
  },
  offlineCell: {
    backgroundColor: '#FF0000',
    color: 'white',
    fontSize: 20,
  },
  otherCell: {
    backgroundColor: '#FFFF33',
    color: 'black',
    fontSize: 20,
  },
})

export class ImagesoundPlayers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imagesoundPlayers: [],
      loading: true,
    }
    this.baseState = { ...this.state }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    await this.props.getImagesoundPlayers()
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Imagesound Players Tab' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.imagesoundPlayers.length) {
      this.setState({
        imagesoundPlayers: JSON.parse(
          JSON.stringify(nextProps.imagesoundPlayers),
        ),
        loading: false,
      })
    }
  }

  render() {
    const { classes } = this.props
    let imagesoundPlayers = []
    if (this.state.imagesoundPlayers.length > 1) {
      const imagesoundColumns = [
        {
          label: 'T#',
          name: 'locationCode',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let displayedTNum
              if (tableMeta.rowData[0] === '') {
                displayedTNum = 'Spare'
              } else {
                displayedTNum = tableMeta.rowData[0]
              }
              return displayedTNum
            },
          },
        },
        { label: 'Player Name', name: 'playerName' },
        { label: 'Player ID', name: 'playerId' },
        { label: 'MAC Address', name: 'macAddress' },
        {
          label: 'Last transaction',
          name: 'lastTransaction',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let displayedDateTime
              if (tableMeta.rowData[4] === 'N/A') {
                displayedDateTime = 'N/A'
              } else {
                const formattedDate = new Date(
                  tableMeta.rowData[4],
                ).toLocaleDateString()
                const unformattedDate = new Date(tableMeta.rowData[4])
                const hours = unformattedDate.getHours()
                const minutes = unformattedDate.getMinutes()
                let displayHours
                let amPm
                if (hours > 12) {
                  displayHours = hours - 12
                  amPm = 'PM'
                } else if (hours === 0) {
                  displayHours = 12
                  amPm = 'AM'
                } else {
                  displayHours = hours
                  amPm = 'AM'
                }
                const displayMinutes = (minutes < 10 ? '0' : '') + minutes
                displayedDateTime =
                  formattedDate +
                  ' - ' +
                  displayHours +
                  ':' +
                  displayMinutes +
                  ' ' +
                  amPm
              }
              return displayedDateTime
            },
          },
        },
        {
          label: 'Status',
          name: 'status',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let displayedStatus
              if (tableMeta.rowData[5] === 2) {
                displayedStatus = 'online'
              } else {
                displayedStatus = 'offline'
              }
              return displayedStatus
            },
            setCellProps: (value) => {
              return {
                className: classNames({
                  [classes.onlineCell]: value === 'online',
                  [classes.offlineCell]: value === 'offline',
                  [classes.otherCell]:
                    value !== 'offline' && value !== 'online',
                }),
              }
            },
          },
        },
      ]

      imagesoundPlayers = (
        <MUIDataTable
          title="Imagesound Players"
          columns={imagesoundColumns}
          data={this.props.imagesoundPlayers}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            rowsPerPageOptions: [10, 20, 50],
            selectableRows: 'none',
            sortOrder: {
              name: 'locationCode',
              direction: 'asc',
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      imagesoundPlayers = this.state.imagesoundPlayers
    }

    const imagesoundPanel = <div>{imagesoundPlayers}</div>

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                {this.state.imagesoundPlayers.length > 0 ? (
                  typeof this.state.imagesoundPlayers[0] === 'string' ? (
                    <Typography variant="h4">
                      {this.state.imagesoundPlayers}
                    </Typography>
                  ) : (
                    imagesoundPanel
                  )
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    imagesoundPlayers: state.deviceStatuses.imagesoundPlayers,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  getImagesoundPlayers,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ImagesoundPlayers))
