import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Grid,
  Typography,
  CardActions,
  CardContent,
} from '@mui/material'
import IpPhoneModal from './IpPhoneModal'
import {
  downloadIpPhoneData,
  clearMessage,
} from '../../../store/AdditionalTools/actionCreator'
import apiConfig from '../../../config/apiConfig'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../../common/FireflyHelper'

export class IpPhoneExportTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ipFileData: '',
      ipFileName: '',
      resultMessage: '',
      statusOpen: false,
    }
    this.baseState = this.state
    this.fileDownload = React.createRef()
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    initializeFireflySDK()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.ipFileData !== nextProps.ipFileData) {
      const url = window.URL.createObjectURL(nextProps.ipFileData)
      this.setState(
        {
          ipFileData: url,
          ipFileName: nextProps.ipFileName,
        },
        function () {
          this.fileDownload.current.click()
        },
      )
    }
    this.setState({
      resultMessage: nextProps.resultMessage,
    })
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'IP Phone Data Export Tab' },
      user: { id: userInfo.email },
    })
  }

  handleOnSubmit(event) {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'IP Phone Data Export - Download' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    this.props.downloadIpPhoneData()
    this.setState({ statusOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
      resultMessage: '',
    })
    this.props.clearMessage()
  }

  render() {
    let modalTitle
    if (this.state.resultMessage === 'Success') {
      modalTitle = 'Success'
    } else if (
      this.state.resultMessage !== undefined &&
      this.state.resultMessage !== 'Success'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom sx={{ fontSize: '16px' }}>
              Press button to download list of IP Phone Data.
            </Typography>
            <br />
            <br />
          </Grid>
        </CardActions>
        <form onSubmit={this.handleOnSubmit}>
          <Grid container spacing={1} sx={{ margin: '-4px' }}>
            <Grid container item xs={12} md={6} lg={3}>
              <CardActions
                style={{
                  width: '90%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Download
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <IpPhoneModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          title={modalTitle}
          resultMessage={this.state.resultMessage}
        />
        <a
          href={this.state.ipFileData}
          download={this.state.ipFileName}
          ref={this.fileDownload}
          style={{ display: 'none' }}
        >
          ''
        </a>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ipFileData: state.additionalTools.ipFileData,
    ipFileName: state.additionalTools.ipFileName,
    resultMessage: state.additionalTools.resultMessage,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  downloadIpPhoneData,
  clearMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(IpPhoneExportTab)
