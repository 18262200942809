import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import { Typography } from '@mui/material'

export default class WebhookSuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventsMessage: '',
    }
  }

  render() {
    let modalTitle
    if (this.props.successMessage === 'Webhook Created') {
      modalTitle = 'Webhook Created'
    } else if (
      this.props.successMessage === '' ||
      this.props.successMessage === undefined
    ) {
      modalTitle = ''
    } else {
      modalTitle = 'Webhook Not Created'
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.successMessage === '' ||
        this.props.successMessage === undefined ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogContent>
              <div>
                <Typography variant="body1" gutterBottom>
                  {this.props.successMessage}
                </Typography>
                <Button variant="contained" onClick={this.props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
