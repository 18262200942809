import * as types from './actionType'

export const initialState = {
  activeSheets: [],
  resultMessage: '',
  statusOpen: false,
  typesAndLocationsList: [],
}

export default function deviceConfigurationReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.CLEAR_RESULT_MESSAGE: {
      const newState = {
        ...state,
        resultMessage: action.payload,
      }
      return newState
    }
    case types.GET_ACTIVE_SHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeSheets: action.payload,
          }
        : {
            ...state,
            activeSheets: [action.payload],
          }
      return newState
    }
    case types.GET_CONFIGURATION_TYPES: {
      const newState = {
        ...state,
        typesAndLocationsList: action.payload.typesAndLocationsList,
      }
      return newState
    }
    case types.INITIATE_CONFIGURATION_STEP: {
      const newState = {
        ...state,
        resultMessage: action.payload,
      }
      return newState
    }
    case types.TOGGLE_STATUS_OPEN: {
      const newState = {
        ...state,
        statusOpen: action.payload,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
