import * as types from './actionType'

export const initialState = {
  activeSheets: [],
  eventMessage: '',
  layoutCutsheetId: '',
  layoutErrorMessage: '',
  layoutErrorOpen: false,
  fileData: '',
  fileName: '',
  result: '',
  swtCount: [''],
}

export default function cablingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_LAYOUT_CUTSHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            layoutCutsheets: action.payload,
          }
        : {
            ...state,
            layoutCutsheets: [action.payload],
          }
      return newState
    }
    case types.OPEN_ERROR_DIALOG: {
      const newState = {
        ...state,
        layoutErrorMessage: action.payload.layoutErrorMessage,
        layoutErrorOpen: action.payload.layoutErrorOpen,
      }
      return newState
    }
    case types.RESET_CABLING_FORM: {
      const newState = {
        ...state,
        layoutCutsheetId: action.payload.layoutCutsheetId,
        layoutErrorMessage: action.payload.layoutErrorMessage,
        layoutErrorOpen: action.payload.layoutErrorOpen,
        swtCount: action.payload.swtCount,
      }
      return newState
    }
    case types.CREATE_CABLING_TYPES: {
      const newState = {
        ...state,
        eventMessage: action.payload.eventMessage,
      }
      return newState
    }
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        eventMessage: '',
      }
      return newState
    }
    case types.GET_ACTIVE_SHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeSheets: action.payload,
          }
        : {
            ...state,
            activeSheets: [action.payload],
          }
      return newState
    }
    case types.GET_WORKSPACES: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            workspaces: action.payload,
          }
        : {
            ...state,
            workspaces: [action.payload],
          }
      return newState
    }
    case types.DOWNLOAD_LAYOUT: {
      const newState = {
        ...state,
        fileData: action.payload.fileData,
        fileName: action.payload.fileName,
        layoutErrorMessage: action.payload.layoutErrorMessage,
        layoutErrorOpen: action.payload.layoutErrorOpen,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
