import * as types from './actionType'

export const initialState = {
  changeUserList: [],
}

export default function changeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        eventsMessage: '',
        changeNumber: '',
        statusOpen: false,
      }
      return newState
    }
    case types.CREATE_CHANGE: {
      const newState = {
        ...state,
        eventsMessage: action.payload.message,
        changeNumber: action.payload.changeNumber,
        changeUrl: action.payload.changeUrl,
        dialogOpen: false,
      }
      return newState
    }
    case types.GET_CHANGE_USER_LIST: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            changeUserList: action.payload,
          }
        : {
            ...state,
            changeUserList: [action.payload],
          }
      return newState
    }
    case types.GET_CHANGE_TEMPLATES: {
      const newState = {
        ...state,
        eventsMessage: action.payload.messageString,
        changeTemplates: action.payload.templateInfo,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
