import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  LinearProgress,
} from '@mui/material/'

export const DeviceConfigurationSuccessModal = (props) => {
  const splitMessage = props.eventsMessage.split('. ')
  // eslint-disable-next-line array-callback-return
  const formattedMessage = splitMessage.map((sentence) => {
    if (sentence.length > 0) {
      let messageText = sentence + '.'
      if (sentence.includes('running')) {
        messageText += ' Please retrieve status again in a few minutes.'
      } else if (sentence.includes('skipped')) {
        messageText +=
          ' Please select the appropriate step for these locations and try again.'
      }
      return <p key={splitMessage.indexOf(sentence)}>{messageText}</p>
    }
  })

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{ onExited: props.clearMessage }}
    >
      <Fade in={props.open}>
        {props.title === '' ? (
          <div>
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
              <div>
                <div style={{ paddingBottom: '10px' }}>{formattedMessage}</div>
                <br />
                <div style={{ paddingBottom: '10px' }}>
                  For more information, please reference the{' '}
                  <a
                    href={props.selectedSheetLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.selectedSheet}
                  </a>
                  .
                </div>
                <br />
                <Button variant="contained" onClick={props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Fade>
    </Dialog>
  )
}
