import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { FormControl } from '@mui/material'
import CreateWebhookHelpDialog from './CreateWebhookHelpDialog'
import WebhookSuccessModal from './WebhookSuccessModal'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import Select from 'react-select'

export default class CreateWebhookDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callbackEndpoint: '',
      workspace: '',
      smartsheet: '',
      columns: '',
    }
    this.validateForm = this.validateForm.bind(this)
  }

  resetForm = () => {
    this.setState({
      workspace: '',
      smartsheet: '',
      columns: [],
      callbackEndpoint: '',
      buttonEnabled: false,
    })
  }

  validateForm = () => {
    if (
      this.state.workspace !== '' &&
      this.state.smartsheet !== '' &&
      this.state.column !== '' &&
      this.state.callbackEndpoint !== ''
    ) {
      this.setState({ buttonEnabled: true })
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleCreateClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{
            marginTop: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Create Webhook
          <Tooltip title={'Help'}>
            <IconButton
              onClick={this.props.handleCreateHelpOpen}
              className="customToolbarIcon"
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              this.props.createNewWebhook(
                event,
                this.state.smartsheet.value,
                this.state.callbackEndpoint,
                this.state.smartsheet.label,
                this.props.user,
                this.state.columns,
              )
            }}
          >
            <FormControl
              style={{
                width: '90%',
                marginTop: '5%',
              }}
            >
              <Select
                value={this.state.workspace}
                placeholder="Workspace"
                options={
                  this.props.workspaces.length > 0
                    ? this.props.workspaces.map((workspace) => ({
                        label: workspace.name,
                        value: workspace.id,
                      }))
                    : ''
                }
                onChange={(event) => {
                  this.setState({ workspace: event }, function () {
                    this.validateForm()
                    this.props.getActiveSheets(this.state.workspace.value)
                  })
                }}
                inputProps={{
                  name: 'workspace',
                  id: 'workspace',
                }}
              />
            </FormControl>
            <FormControl
              style={{
                width: '90%',
                marginTop: '5%',
              }}
            >
              <Select
                value={this.state.smartsheet}
                placeholder="Sheet"
                options={
                  this.props.activeSheets.length > 0
                    ? this.props.activeSheets.map((smartsheet) => ({
                        label: smartsheet.name,
                        value: smartsheet.id,
                      }))
                    : ''
                }
                onChange={(event) => {
                  this.setState({ smartsheet: event }, function () {
                    this.validateForm()
                    this.props.getSheetColumns(this.state.smartsheet.value)
                  })
                }}
                inputProps={{
                  name: 'smartsheet',
                  id: 'smartsheet',
                }}
              />
            </FormControl>
            <FormControl
              style={{
                width: '90%',
                marginTop: '5%',
              }}
            >
              <Select
                isMulti
                value={this.state.columns}
                placeholder="Sheet Columns"
                closeMenuOnSelect={false}
                options={
                  this.props.sheetColumns.length > 0
                    ? this.props.sheetColumns.map((column) => ({
                        label: column.title,
                        value: column.id,
                      }))
                    : ''
                }
                onChange={(event) => {
                  this.setState({ columns: event }, function () {
                    this.validateForm()
                  })
                }}
                inputProps={{
                  name: 'columns',
                  id: 'columns',
                }}
              />
            </FormControl>
            <FormControl
              style={{
                width: '90%',
                marginTop: '5%',
              }}
            >
              <TextField
                type="text"
                variant="standard"
                value={this.state.callbackEndpoint}
                onChange={(event) => {
                  this.setState(
                    {
                      callbackEndpoint: event.target.value,
                    },
                    function () {
                      this.validateForm()
                    },
                  )
                }}
                placeholder="Example: callback_test_devices"
                label="Endpoint Url"
                inputProps={{
                  name: 'endpointUrl',
                  id: 'endpointUrl',
                }}
                InputProps={{
                  className: 'redUnderline',
                }}
                required
              />
            </FormControl>
            <DialogActions sx={{ marginTop: '12%' }}>
              <Button
                onClick={this.props.handleCreateClose}
                className="helpModalClose"
              >
                Cancel
              </Button>
              <Button type="submit" disabled={!this.state.buttonEnabled}>
                Create
              </Button>
            </DialogActions>
          </form>
          <CreateWebhookHelpDialog
            open={this.props.helpOpen}
            handleCreateHelpClose={this.props.handleCreateHelpClose}
          />
          <WebhookSuccessModal
            open={this.props.successOpen}
            handleClose={this.props.handleSuccessClose}
            successMessage={this.props.successMessage}
          />
        </DialogContent>
      </Dialog>
    )
  }
}
