import React from 'react'
import HeaderTitle from '../Header/HeaderTitle'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Select from 'react-select'
import { connect } from 'react-redux'
import {
  getKitList,
  getActiveSheets,
  getExecutionPeriods,
  getWorkspaces,
  clearMessage,
} from '../../store/KitList/actionCreator'
import KitListSuccessModal from './KitListSuccessModal'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'

export class KitList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      statusOpen: false,
      buttonEnabled: false,
      activeSheets: [],
      executionPeriods: [],
      workspaces: [],
      workspace: '',
      eventMessage: '',
      kitListSmartsheetData: [],
      smartsheetId: [],
      executionPeriod: [],
      year: '',
    }
    initializeFireflySDK()
    this.dialogBox = React.createRef()
    this.baseState = this.state
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.handleOnCreate = this.handleOnCreate.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Kit List',
          pageName: 'Kit List',
          pageType: 'Kit List',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Kit List' },
      user: { id: userInfo.email },
    })
    await this.props.getWorkspaces()
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.workspaces !== nextProps.workspaces) {
      this.setState({
        workspaces: JSON.parse(JSON.stringify(nextProps.workspaces)),
      })
    }
    if (
      this.props.kitListSmartsheetData !== nextProps.kitListSmartsheetData ||
      this.props.eventMessage !== nextProps.eventMessage
    ) {
      this.setState({
        kitListSmartsheetData: nextProps.kitListSmartsheetData,
        eventMessage: nextProps.eventMessage,
      })
    } else {
      this.setState({
        activeSheets: nextProps.activeSheets,
        executionPeriods: nextProps.executionPeriods,
      })
    }
  }

  validateForm = () => {
    if (
      this.state.workspace !== '' &&
      this.state.smartsheetId.length > 0 &&
      this.state.executionPeriod.length > 0
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  async handleOnCreate(event) {
    event.preventDefault()
    this.setState({ statusOpen: true })
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Kit List - Create' },
      user: { id: userInfo.email },
    })
    const kitData = {
      smartsheetId: this.state.smartsheetId.map((e) => e.value),
      executionPeriod: this.state.executionPeriod.map((e) => e.value),
      year: this.state.year,
    }
    await this.props.getKitList(kitData)
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
      executionPeriod: [],
      year: '',
      smartsheetId: [],
      workspace: [],
      buttonEnabled: false,
    })
    this.props.clearMessage()
  }

  render() {
    let modalTitle
    if (this.state.eventMessage === 'Success') {
      modalTitle = 'Success'
    } else if (
      this.state.eventMessage !== 'Success' &&
      this.state.eventMessage !== undefined &&
      this.state.eventMessage.length > 1
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <div>
        <HeaderTitle title="Kit List" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid container spacing={16}>
            <Grid item xs={6} style={{ margin: 10 }}>
              Select input Smartsheet and specify Execution Period to create Kit
              List.
              <br />
              <br />
              <form onSubmit={this.handleOnCreate}>
                <FormControl
                  component="fieldset"
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '40%',
                  }}
                >
                  <div style={{ width: '400px' }}>
                    <Select
                      value={this.state.workspace}
                      options={
                        this.state.workspaces.length > 0
                          ? this.state.workspaces.map((workspace) => ({
                              label: workspace.name,
                              value: workspace.id,
                            }))
                          : ''
                      }
                      placeholder="Workspace"
                      onChange={(event) => {
                        this.setState(
                          {
                            workspace: event,
                          },
                          function () {
                            this.validateForm()
                            this.props.getActiveSheets(
                              this.state.workspace.value,
                            )
                          },
                        )
                      }}
                      inputProps={{
                        name: 'workspace',
                        id: 'workspace',
                      }}
                    />
                  </div>
                </FormControl>
                <br />
                <FormControl
                  component="fieldset"
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '40%',
                  }}
                >
                  <div style={{ width: '400px' }}>
                    <Select
                      value={this.state.smartsheetId}
                      isMulti
                      closeMenuOnSelect={false}
                      options={
                        this.state.activeSheets.length > 0
                          ? this.state.activeSheets.map((activeSheet) => ({
                              label: activeSheet.name,
                              value: activeSheet.id,
                            }))
                          : ''
                      }
                      placeholder="Smartsheet"
                      onChange={(event) => {
                        this.setState(
                          {
                            smartsheetId: event,
                          },
                          function () {
                            this.validateForm()
                            this.props.getExecutionPeriods(
                              this.state.smartsheetId.map((e) => e.value),
                            )
                          },
                        )
                      }}
                      inputProps={{
                        name: 'smartsheetId',
                        id: 'smartsheetId',
                      }}
                    />
                  </div>
                </FormControl>
                <br />
                <FormControl
                  component="fieldset"
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '40%',
                  }}
                >
                  <div style={{ width: '400px' }}>
                    <Select
                      value={this.state.executionPeriod}
                      isMulti
                      closeMenuOnSelect={false}
                      options={
                        this.state.executionPeriods.length > 0
                          ? this.state.executionPeriods.map(
                              (executionPeriod) => ({
                                label: executionPeriod,
                                value: executionPeriod,
                              }),
                            )
                          : ''
                      }
                      placeholder="Execution Period"
                      onChange={(event) => {
                        this.setState(
                          {
                            executionPeriod: event,
                          },
                          function () {
                            this.validateForm()
                          },
                        )
                      }}
                      inputProps={{
                        name: 'executionPeriod',
                        id: 'executionPeriod',
                      }}
                    />
                  </div>
                </FormControl>
                <br />
                <br />
                <TextField
                  htmlFor="year"
                  type="text"
                  variant="standard"
                  value={this.state.year}
                  label="Year (optional)"
                  onChange={(event) =>
                    this.setState({ year: event.target.value }, function () {
                      this.validateForm()
                    })
                  }
                  inputProps={{
                    name: 'year',
                    id: 'year',
                  }}
                  InputProps={{
                    className: 'redUnderline',
                  }}
                />
                <br />
                <br />
                <Button
                  sx={{
                    margin: '8px',
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!this.state.buttonEnabled}
                  type="submit"
                >
                  Create
                </Button>
              </form>
              <KitListSuccessModal
                open={this.state.statusOpen}
                handleClose={this.handleStatusClose}
                eventMessage={this.state.eventMessage}
                kitListSmartsheetData={this.state.kitListSmartsheetData}
                title={modalTitle}
              />
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    kitListSmartsheetData: state.kitList.kitListSmartsheetData,
    eventMessage: state.kitList.eventMessage,
    userInfo: state.auth.userInfo,
    activeSheets: state.kitList.activeSheets,
    executionPeriods: state.kitList.executionPeriods,
    workspaces: state.kitList.workspaces,
  }
}

const mapDispatchToProps = {
  getKitList,
  getActiveSheets,
  getExecutionPeriods,
  getWorkspaces,
  clearMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(KitList)
