import * as types from './actionType'

export const initialState = {
  deviceList: [],
  imagesoundPlayers: [],
}

export default function storeDeviceStatusReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.GET_DEVICE_LIST: {
      const newState = {
        ...state,
        deviceList: action.payload,
      }
      return newState
    }
    case types.GET_IMAGESOUND_PLAYERS: {
      const newState = {
        ...state,
        imagesoundPlayers: action.payload,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
