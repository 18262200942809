import React from 'react'
import { connect } from 'react-redux'
import { CardContent } from '@mui/material'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'
import { initializeFireflySDK } from '../../../common/FireflyHelper'

const poSubmissionLogForm = 'PO Submission Log Form'
const poSubmissionLogFormLink =
  'https://app.smartsheet.com/b/form/130a6bae13fb41619e2062fd1b13cdda'
const poSubmissionLog = 'PO Submission Log'
const poSubmissionLogLink =
  'https://app.smartsheet.com/sheets/qjgpjcgG8PM76563qW9XFj2Pc5qXQv9W2fhJ3W41?view=grid'
const poLog = 'PO Log'
const poLogLink =
  'https://app.smartsheet.com/folders/jrWpGWxfrp85GW8mC4Pvwh4rhjrvQ8MGPR6HJPJ1'

const inventoryExceptionsLog = 'Inventory Exceptions Log'
const inventoryExceptionsLogLink =
  'https://app.smartsheet.com/sheets/28JqQXf95HQvFQ7VCq894PfWJFGMV8xfgHCXXf31?view=grid'

const atrLogForm = 'ATR Log Form'
const atrLogFormLink =
  'https://app.smartsheet.com/b/form/b25aa8fabfc3493a9a6d27960f31f9a5'
const atrLog = 'ATR Log'
const atrLogLink =
  'https://app.smartsheet.com/sheets/7MGC49Gf6RJfg6jgGC66PG745q5vgmvRr2MhM9q1?view=grid'
const atrReturnedToInventory = 'ATR Returned to Inventory (BWK Only)'
const atrReturnedToInventoryLink =
  'https://app.smartsheet.com/sheets/J4J8WJmv4XxQg575m5GFwGfQXx5X3GVCV9JhGqh1?view=grid'

const contactProcurement = "Reach out to your team's procurement partner"
const atMentionProcurement =
  "@mention your team's procurement partner in the row"
const notApplicable = 'N/A'

const supplementalLog = 'Supplemental Log'
const supplementalLogLink =
  'https://app.smartsheet.com/folders/F3cG7m4q89v46vqxW92w2M5f5vj5Qc8Qq2H2mXX1'

const tbrAppropriationsLink =
  'https://app.smartsheet.com/sheets/pv786XWWXw85whrg9x3hpjpMM4fX722fXj95qjW1?view=grid'
const cprApproprationsLink =
  'https://app.smartsheet.com/sheets/xM5Rq9C2G8Mp7r8Wpjc4Rwc2f99gCX46QjCrQcx1?view=grid'

const kittingDocumentForm = 'Kitting Document Request Form'
const kittingDocumentFormLink =
  'https://app.smartsheet.com/b/form/ccc3c6d98810447ba5d373bbed8c1e4e'
const kittingDocumentLog = 'Kitting Document Log'
const kittingDocumentLogLink =
  'https://app.smartsheet.com/sheets/PGXwxQcfjQCQMv3CC932PQ6wjVJmMqRrV2pgh6r1?view=grid'
const lineLogs = 'Line Logs'
const lineLogsLink =
  'https://app.smartsheet.com/folders/P5QhXxmWVjr2cJvf4FfqpH2Qh36cP8WRgj8g8jw1'

const rdPinInventorySsLink =
  'https://app.smartsheet.com/sheets/WR73wrMjFhghFMvQCm37WcJrjHgG6Rq6CmPVqww1?view=grid'

const partIntakeForm = 'Part# Intake Form'
const partIntakeFormLink =
  'https://app.smartsheet.com/b/form/550cd14a74414a528a33a4678dd73416'
const partIntakeLog = 'Part# Intake Log'
const partIntakeLogLink =
  'https://app.smartsheet.com/sheets/gCwWWmG54Qj9583RJ5Gc4Xm72vh2552vPcwxJ8c1?view=grid'
const hardwareCatalog = 'Hardware Catalog'
const hardwareCataloglink =
  'https://app.smartsheet.com/sheets/XvqCg52gQpFhp3h95XwJQ2j2wFx9fRF433J2gMG1?view=grid'

const projectIntakeLog = 'Project Intake Log(by person)'
const projectIntakeLogLink =
  'https://app.smartsheet.com/folders/2fjHFm3CqW4HGw7rXR5H58RRVJC4rFmC6hFxgPj1'

export class QuickLinksTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locationId: '',
      hostname: '',
      selectedFile: null,
      eventsMessage: '',
      loading: false,
      statusOpen: false,
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
  }
  componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Quick Links' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  render() {
    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <div>
          <table border="2">
            <thead>
              <tr>
                <th>
                  <b>I would like to...</b>
                </th>
                <th>
                  <b>Form</b>
                </th>
                <th>
                  <b>Log form goes to</b>
                </th>
                <th>
                  <b>Transaction Log</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>place a PO to go to a consolidator</b>
                </td>
                <td>
                  <a
                    href={poSubmissionLogFormLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLogForm}
                  </a>
                </td>
                <td>
                  <a
                    href={poSubmissionLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLog}
                  </a>
                </td>
                <td>
                  <a href={poLogLink} target="_blank" rel="noreferrer">
                    {poLog}
                  </a>{' '}
                  or see "PO Log Report" above
                </td>
              </tr>
              <tr>
                <td>
                  <b>place a PO to go to site</b>
                </td>
                <td>
                  <a
                    href={poSubmissionLogFormLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLogForm}
                  </a>
                </td>
                <td>
                  <a
                    href={poSubmissionLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLog}
                  </a>
                </td>
                <td>
                  <a href={poLogLink} target="_blank" rel="noreferrer">
                    {poLog}
                  </a>{' '}
                  or see "PO Log Report" above
                </td>
              </tr>
              <tr>
                <td>
                  <b>requesting legacy hardware*</b>
                </td>
                <td>
                  <a
                    href={poSubmissionLogFormLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLogForm}
                  </a>
                </td>
                <td>
                  <a
                    href={poSubmissionLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLog}
                  </a>
                </td>
                <td>
                  <a href={poLogLink} target="_blank" rel="noreferrer">
                    {poLog}
                  </a>{' '}
                  or see "PO Log Report" above or{' '}
                  <a
                    href={inventoryExceptionsLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {inventoryExceptionsLog}
                  </a>{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    transfer hardware from one project to another (PIN to PIN
                    transfer)
                  </b>
                </td>
                <td>
                  <a
                    href={poSubmissionLogFormLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLogForm}
                  </a>
                </td>
                <td>
                  <a
                    href={poSubmissionLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {poSubmissionLog}
                  </a>
                </td>
                <td>
                  <a
                    href={inventoryExceptionsLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {inventoryExceptionsLog}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>return hardware to a consolidator(ATR)*</b>
                </td>
                <td>
                  <a href={atrLogFormLink} target="_blank" rel="noreferrer">
                    {atrLogForm}
                  </a>
                </td>
                <td>
                  <a href={atrLogLink} target="_blank" rel="noreferrer">
                    {atrLog}
                  </a>
                </td>
                <td>
                  <a
                    href={atrReturnedToInventoryLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {atrReturnedToInventory}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>return hardware not to a consolidator</b>
                </td>
                <td>{contactProcurement}</td>
                <td>{contactProcurement}</td>
                <td>{contactProcurement}</td>
              </tr>
              <tr>
                <td>
                  <b>
                    place an initial/large shipment from a consolidator(Kitting
                    Document)**
                  </b>
                </td>
                <td>
                  <a
                    href={kittingDocumentFormLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {kittingDocumentForm}
                  </a>
                </td>
                <td>
                  <a
                    href={kittingDocumentLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {kittingDocumentLog}
                  </a>
                </td>
                <td>
                  <a href={lineLogsLink} target="_blank" rel="noreferrer">
                    {lineLogs}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    place a supplemental/small shipment from a
                    consolidator(Supplemental Request)**
                  </b>
                </td>
                <td>See "Supplemental Form" above</td>
                <td>
                  <a
                    href={supplementalLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {supplementalLog}
                  </a>
                </td>
                <td>
                  <a
                    href={supplementalLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {supplementalLog}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>cancel or adjust a request to procurement</b>
                </td>
                <td>{atMentionProcurement}</td>
                <td>{atMentionProcurement}</td>
                <td>{atMentionProcurement}</td>
              </tr>
              <tr>
                <td>
                  <b>see all R&D PIN inventory transactions</b>
                </td>
                <td>{notApplicable}</td>
                <td>{notApplicable}</td>
                <td>R&D PIN Transactions(Tab Above)</td>
              </tr>
              <tr>
                <td>
                  <b>see inventory R&D PINs</b>
                </td>
                <td>{notApplicable}</td>
                <td>R&D PIN Inventory (Tab Above)</td>
                <td>
                  <a
                    href={rdPinInventorySsLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Also available in SS)
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>see billing R&D PINs</b>
                </td>
                <td>{notApplicable}</td>
                <td>{notApplicable}</td>
                <td>
                  <a
                    href={tbrAppropriationsLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    TBR
                  </a>{' '}
                  or{' '}
                  <a
                    href={cprApproprationsLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    CPR/Other
                  </a>{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    view project specific info and PO reports by R&D PIN/Owner
                  </b>
                </td>
                <td>{notApplicable}</td>
                <td>{notApplicable}</td>
                <td>
                  <a
                    href={projectIntakeLogLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {projectIntakeLog}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <b>provide info for new parts or view current parts</b>
                </td>
                <td>
                  <a href={partIntakeFormLink} target="_blank" rel="noreferrer">
                    {partIntakeForm}
                  </a>
                </td>
                <td>
                  <a href={partIntakeLogLink} target="_blank" rel="noreferrer">
                    {partIntakeLog}
                  </a>
                </td>
                <td>
                  <a
                    href={hardwareCataloglink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {hardwareCatalog}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p>*Bailiwick or HQ Depot</p>
          <p>**Bailiwick, HQ Depot, Telaid, or Lozier/CASI</p>
          <table border="2">
            <thead>
              <tr>
                <th>
                  <b>Execution Team(s)</b>
                </th>
                <th>
                  <b>Consolidation Partner</b>
                </th>
                <th>
                  <b>Procurement Partner</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New Stores</td>
                <td>Heather Vogen</td>
                <td>Jacob Vilenchik</td>
              </tr>
              <tr>
                <td>Remodels</td>
                <td>Heather Vogen</td>
                <td>Matt Kasten</td>
              </tr>
              <tr>
                <td>Bundle</td>
                <td>Heather Vogen</td>
                <td>Vicky Peace</td>
              </tr>
              <tr>
                <td>Special Projects, HQ, CFT, Other</td>
                <td>Heather Vogen</td>
                <td>Michael Bratt</td>
              </tr>
              <tr>
                <td>GSCL</td>
                <td>Allison Eger</td>
                <td>Jessica Urbanski</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinksTab)
