import React from 'react'
import HeaderTitle from '../Header/HeaderTitle'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import {
  getProjectSheetsAndHooks,
  createWebhook,
  deleteWebhook,
  resetSuccessMessage,
  checkSheetColumns,
} from '../../store/AutomationTests/actionCreator'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'
import CustomToolbarHelp from './CustomToolbarHelpButton'
import { FormGroup, FormControlLabel, Tooltip, Switch } from '@mui/material'
import WebhookStatusModal from './WebhookStatusModal'
import AutomationTestHelpDialog from './AutomationTestHelpDialog'

const styles = (theme) => ({
  toggleLoadingCursor: {
    pointerEvents: 'none',
    cursor: 'wait',
  },
  toggleLoadedCursor: {
    pointerEvents: 'auto',
  },
  webhookRowLoading: {
    opacity: 0.3,
    cursor: 'wait',
  },
  webhookRowLoaded: {
    opacity: 1,
  },
})

export class AutomationTests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      smartsheetId: '',
      automationTestHelpOpen: false,
      automationTestSheetsList: [],
      newWebhookData: {},
      rowLoading: [],
      webhookFailureOpen: false,
      webhookStatusMessage: '',
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.handleAutomationTestHelpOpen =
      this.handleAutomationTestHelpOpen.bind(this)
    this.handleAutomationTestHelpClose =
      this.handleAutomationTestHelpClose.bind(this)
    this.handleSuccessClose = this.handleSuccessClose.bind(this)
    this.handleSuccessOpen = this.handleSuccessOpen.bind(this)
    this.toggleWebhook = this.toggleWebhook.bind(this)
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
      lanId: undefined,
    },
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Automated Tests',
          pageName: 'Automated Tests',
          pageType: 'Automated Tests',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Automated Tests' },
      user: { id: userInfo.email },
    })
    this.setState({ loading: true })
    await this.props.getProjectSheetsAndHooks()
    this.setState({ loading: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.webhookStatusMessage !== '' &&
      nextProps.webhookStatusMessage !== undefined &&
      nextProps.webhookStatusMessage !== 'success'
    ) {
      this.setState({
        webhookFailureOpen: true,
        webhookStatusMessage: nextProps.webhookStatusMessage,
      })
    } else if (nextProps.webhookStatusMessage === 'success') {
      this.setState({
        automationTestSheetsList: JSON.parse(
          JSON.stringify(nextProps.automationTestSheetsList),
        ),
        webhookStatusMessage: '',
      })
    } else {
      this.setState({
        automationTestSheetsList: JSON.parse(
          JSON.stringify(nextProps.automationTestSheetsList),
        ),
        rowLoading: [],
        webhookStatusMessage: '',
      })
    }
  }

  async handleSuccessClose() {
    await this.setState({ webhookFailureOpen: false })
    this.props.resetSuccessMessage()
  }

  handleSuccessOpen() {
    this.setState({ webhookFailureOpen: true })
  }

  handleAutomationTestHelpOpen() {
    this.setState({ automationTestHelpOpen: true })
  }

  handleAutomationTestHelpClose() {
    this.setState({ automationTestHelpOpen: false })
  }

  toggleWebhook = (
    event,
    smartsheetId,
    sheetName,
    webhookId,
    user,
    rowIndex,
  ) => {
    if (this.state.rowLoading.includes(rowIndex) !== true) {
      const allLoadingRows = this.state.rowLoading.concat(rowIndex)
      if (event.target.value === 'false') {
        if (webhookId !== 'N/A') {
          this.props.deleteWebhook(webhookId)
        }
        const userName = user.email
          .substring(0, user.email.indexOf('@'))
          .replace('.', ' ')
        const newWebhookData = {
          callbackUrl: `${apiConfig.api.rapidUrl}/callback_test_devices`,
          name: sheetName + '<' + userName,
          smartsheetId: smartsheetId,
          enabled: true,
          webhookType: 'test automation',
        }
        this.setState({
          newWebhookData: newWebhookData,
          rowLoading: allLoadingRows,
        })
        this.props.checkSheetColumns(newWebhookData)
      } else {
        this.setState({ rowLoading: allLoadingRows })
        this.props.deleteWebhook(webhookId)
      }
    }
  }

  formatStatus = (input) => {
    let formattedStatus
    if (input === 'ENABLED') {
      formattedStatus = 'Enabled'
    } else if (input === 'NEW_NOT_VERIFIED') {
      formattedStatus = 'Not Verified'
    } else if (input === 'DISABLED_BY_OWNER') {
      formattedStatus = 'Disabled By Owner'
    } else if (input === 'DISABLED_VERIFICATION_FAILED') {
      formattedStatus = 'Verification Failed'
    } else if (input === 'DISABLED_SCOPE_INACCESSIBLE') {
      formattedStatus = 'Smartsheet Scope Not Accessible'
    } else {
      formattedStatus = 'Enable Automation'
    }
    return formattedStatus
  }

  render() {
    const { classes, userInfo } = this.props

    let projectSheets = []
    if (this.state.automationTestSheetsList.length > 0) {
      const automationTestsColumns = [
        {
          label: 'Sheet Name',
          name: 'sheetName',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <a
                  href={tableMeta.rowData[6]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value}
                </a>
              )
            },
          },
        },
        {
          label: 'Creator',
          name: 'creator',
        },
        {
          label: 'Last Successful Validation',
          name: 'lastSuccessfulCallback',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let displayedDateTime
              if (tableMeta.rowData[2] === 'N/A') {
                displayedDateTime = 'N/A'
              } else {
                const formattedDate = new Date(
                  tableMeta.rowData[2],
                ).toLocaleDateString()
                const unformattedDate = new Date(tableMeta.rowData[2])
                const hours = unformattedDate.getHours()
                const minutes = unformattedDate.getMinutes()
                let displayHours
                let amPm
                if (hours > 12) {
                  displayHours = hours - 12
                  amPm = 'PM'
                } else if (hours === 0) {
                  displayHours = 12
                  amPm = 'AM'
                } else {
                  displayHours = hours
                  amPm = 'AM'
                }
                const displayMinutes = (minutes < 10 ? '0' : '') + minutes
                displayedDateTime =
                  formattedDate +
                  ' - ' +
                  displayHours +
                  ':' +
                  displayMinutes +
                  ' ' +
                  amPm
              }
              return displayedDateTime
            },
          },
        },
        {
          label: 'Disabled/Enabled',
          name: 'webhookEnabled',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Tooltip title={this.formatStatus(tableMeta.rowData[4])}>
                        <Switch
                          color="success"
                          checked={value}
                          value={value}
                          onChange={(event) => {
                            this.toggleWebhook(
                              event,
                              tableMeta.rowData[7],
                              tableMeta.rowData[0],
                              tableMeta.rowData[5],
                              userInfo,
                              tableMeta.rowIndex,
                            )
                          }}
                        />
                      </Tooltip>
                    }
                  />
                </FormGroup>
              )
            },
            setCellProps: (cellValue, rowIndex, columnIndex) => {
              let displayClass
              let loadingRow = this.state.rowLoading.find((currentRow) => {
                return currentRow === rowIndex
              })
              if (loadingRow !== undefined) {
                displayClass = classes.toggleLoadingCursor
              } else {
                displayClass = classes.toggleLoadedCursor
              }
              return {
                className: displayClass,
              }
            },
          },
        },
        {
          label: 'Status',
          name: 'webhookStatus',
          options: { display: false },
        },
        {
          label: 'Webhook Id',
          name: 'webhookId',
          options: { display: false },
        },
        {
          label: 'Smartsheet Link',
          name: 'permalink',
          options: { display: false },
        },
        {
          label: 'Smartsheet Id',
          name: 'sheetId',
          options: { display: false },
        },
      ]
      projectSheets = (
        <MUIDataTable
          title="Automated Tests"
          columns={automationTestsColumns}
          data={this.props.automationTestSheetsList}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            selectableRows: 'none',
            rowsPerPageOptions: [10, 20, 50],
            setRowProps: (row, dataIndex, rowIndex) => {
              let displayClass
              let loadingRow = this.state.rowLoading.find((currentRow) => {
                return currentRow === dataIndex
              })
              if (loadingRow !== undefined) {
                displayClass = classes.webhookRowLoading
              } else {
                displayClass = classes.webhookRowLoaded
              }
              return {
                className: displayClass,
              }
            },
            customToolbar: () => {
              return (
                <CustomToolbarHelp
                  openHelpDialog={this.handleAutomationTestHelpOpen}
                />
              )
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      projectSheets = this.state.automationTestSheetsList
    }

    const automationTestsPanel = <div>{projectSheets}</div>

    return (
      <div>
        <HeaderTitle title="Automated Tests" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <AutomationTestHelpDialog
                  open={this.state.automationTestHelpOpen}
                  handleAutomationTestHelpClose={
                    this.handleAutomationTestHelpClose
                  }
                />
                <WebhookStatusModal
                  open={this.state.webhookFailureOpen}
                  handleClose={this.handleSuccessClose}
                  statusMessage={this.state.webhookStatusMessage}
                />
                {automationTestsPanel}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    automationTestSheetsList: state.automationTests.activeProjectsList,
    userInfo: state.auth.userInfo,
    webhookStatusMessage: state.automationTests.webhookStatusMessage,
  }
}

const mapDispatchToProps = {
  getProjectSheetsAndHooks,
  createWebhook,
  deleteWebhook,
  resetSuccessMessage,
  checkSheetColumns,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AutomationTests))
