import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Grid,
  TableCell,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material'
import HeaderTitle from '../Header/HeaderTitle'
import {
  getLocationInformation,
  getLocationProjects,
  getLocationIncidents,
} from '../../store/LocationProjects/actionCreator'
import { formatLocationNumber } from '../../common/tLocationNumbers'
import MUIDataTable from 'mui-datatables'
import Search from '@mui/icons-material/Search'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'

function withParams(Component) {
  return (props) => (
    <Component {...props} params={useParams()} navigate={useNavigate()} />
  )
}

const shapeCircleStyles = { borderRadius: '50%' }
const redCircle = (
  <Box
    component="span"
    sx={{
      ...{ bgcolor: '#FF0000', width: 40, height: 40 },
      ...shapeCircleStyles,
    }}
  />
)
const greenCircle = (
  <Box
    component="span"
    sx={{
      ...{ bgcolor: '#52d869', width: 40, height: 40 },
      ...shapeCircleStyles,
    }}
  />
)
const blueCircle = (
  <Box
    component="span"
    sx={{
      ...{ bgcolor: '#0000ff', width: 40, height: 40 },
      ...shapeCircleStyles,
    }}
  />
)
const yellowCircle = (
  <Box
    component="span"
    sx={{
      ...{ bgcolor: '#FFFF00', width: 40, height: 40 },
      ...shapeCircleStyles,
    }}
  />
)
const grayCircle = (
  <Box
    component="span"
    sx={{
      ...{ bgcolor: '#808080', width: 40, height: 40 },
      ...shapeCircleStyles,
    }}
  />
)

export class LocationProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: '',
      locationIncidents: [],
      locationProjects: [],
      locationInformation: '',
      loading: false,
      locationNumber: '',
      notFoundMessage: '',
    }
    initializeFireflySDK()
    this.displayHours = this.displayHours.bind(this)
    this.formatTime = this.formatTime.bind(this)
    this.handleOnSearch = this.handleOnSearch.bind(this)
    this.baseState = { ...this.state }
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { locationId } = this.props.params
    if (
      nextProps.locationIncidents.length &&
      nextProps.locationProjects.length &&
      typeof locationId !== 'undefined'
    ) {
      this.displayLocationIncidents(nextProps)
      this.displayLocationProjects(nextProps)
      this.displayLocationInformation(nextProps)
    }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const { locationId } = this.props.params

    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Location Projects',
          pageName: 'Location Projects',
          pageType: 'Location Projects',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Location Projects' },
      user: { id: userInfo.email },
    })
    if (locationId !== '' && typeof locationId !== 'undefined') {
      this.setState({ loading: true })
      await this.props.getLocationInformation(locationId)
      await this.props.getLocationIncidents(locationId)
      await this.props.getLocationProjects(locationId)
      this.setState({ loading: false })
    }
  }

  async handleOnSearch(event) {
    const { userInfo } = this.props
    const navigate = this.props.navigate
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Location Projects - Search' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    this.setState({ ...this.baseState, loading: true })
    var formattedLocationNumber = formatLocationNumber(
      this.state.locationNumber,
    )
    navigate('/LocationProjects/' + formattedLocationNumber)
    await this.props.getLocationInformation(formattedLocationNumber)
    await this.props.getLocationIncidents(formattedLocationNumber)
    await this.props.getLocationProjects(formattedLocationNumber)
    this.setState({ loading: false })
  }

  displayLocationInformation = (nextProps) => {
    if (nextProps.locationInformation[0] === 'Location Not Found!') {
      this.setState({
        location: '',
        locationInformation: '',
        locationIncidents: [],
        locationProjects: [],
        notFoundMessage: 'Location Not Found!',
      })
    } else {
      this.setState({
        location: nextProps.location,
        locationInformation: JSON.parse(
          JSON.stringify(nextProps.locationInformation),
        ),
        notFoundMessage: '',
      })
    }
  }

  displayLocationIncidents = (nextProps) => {
    if (nextProps.locationIncidents[0] === 'Location Not Found!') {
      this.setState({
        location: 'Location Not Found!',
        locationIncidents: [],
      })
    } else if (nextProps.locationIncidents[0] === 'No Active Incidents!') {
      this.setState({
        location: nextProps.location,
        locationIncidents: 'No Active Incidents!',
        notFoundMessage: '',
      })
    } else {
      this.setState({
        location: nextProps.location,
        locationIncidents: JSON.parse(
          JSON.stringify(nextProps.locationIncidents),
        ),
        notFoundMessage: '',
      })
    }
  }

  displayLocationProjects = (nextProps) => {
    if (nextProps.locationProjects[0] === 'Location Not Found!') {
      this.setState({
        location: 'Location Not Found!',
        locationProjects: [],
      })
    } else if (nextProps.locationProjects[0] === 'No Projects Found!') {
      this.setState({
        location: nextProps.location,
        locationProjects: 'No Projects Found!',
        notFoundMessage: '',
      })
    } else {
      this.setState({
        location: nextProps.location,
        locationProjects: JSON.parse(
          JSON.stringify(nextProps.locationProjects),
        ),
        notFoundMessage: '',
      })
    }
  }

  displayHours = () => {
    let operatingHours
    if (this.state.locationInformation[0].hours.length <= 1) {
      operatingHours = <div>N/A</div>
    } else {
      operatingHours = (
        <div>
          <b>{this.state.locationInformation[0].hours[0].day}:</b> {'   '}
          {this.state.locationInformation[0].hours[0].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[0].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[0].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[0].end_time,
              )
            : 'N/A'}
          <br />
          <b>{this.state.locationInformation[0].hours[1].day}:</b> {'   '}
          {this.state.locationInformation[0].hours[1].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[1].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[1].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[1].end_time,
              )
            : 'N/A'}
          <br />
          <b>{this.state.locationInformation[0].hours[2].day}:</b> {'  '}
          {this.state.locationInformation[0].hours[2].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[2].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[2].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[2].end_time,
              )
            : 'N/A'}
          <br />
          <b>{this.state.locationInformation[0].hours[3].day}:</b> {'   '}
          {this.state.locationInformation[0].hours[3].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[3].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[3].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[3].end_time,
              )
            : 'N/A'}
          <br />
          <b>{this.state.locationInformation[0].hours[4].day}:</b> {'   '}
          {this.state.locationInformation[0].hours[4].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[4].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[4].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[4].end_time,
              )
            : 'N/A'}
          <br />
          <b>{this.state.locationInformation[0].hours[5].day}:</b> {'   '}
          {this.state.locationInformation[0].hours[5].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[5].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[5].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[5].end_time,
              )
            : 'N/A'}
          <br />
          <b>{this.state.locationInformation[0].hours[6].day}:</b> {'   '}
          {this.state.locationInformation[0].hours[6].begin_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[6].begin_time,
              )
            : 'N/A'}{' '}
          -{' '}
          {this.state.locationInformation[0].hours[6].end_time !== null
            ? this.formatTime(
                this.state.locationInformation[0].hours[6].end_time,
              )
            : 'N/A'}
        </div>
      )
    }
    return operatingHours
  }

  formatTime = (unformattedTime) => {
    const timeArray = unformattedTime.split(':')
    var hours =
      timeArray[0].charAt(0) === '0' ? timeArray[0].charAt(1) : timeArray[0]
    const minutes = timeArray[1]
    var amPm
    if (hours === 0) {
      hours = 12
      amPm = 'AM'
    } else if (hours === 12) {
      amPm = 'PM'
    } else if (hours > 12) {
      hours = hours - 12
      amPm = 'PM'
    } else {
      amPm = 'AM'
    }
    return hours + ':' + minutes + ' ' + amPm
  }

  expandedRowMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: '#e0e0e0',
              minHeight: '8px',
            },
          },
        },
      },
    })

  render() {
    const ulStyle = {
      columnCount: 2,
      padding: 0,
      marginTop: 0,
    }
    let information
    if (
      this.state.locationInformation.length > 0 &&
      this.state.locationInformation[0] !== 'Location Not Found!'
    ) {
      let capabilities
      if (this.state.locationInformation[0].capabilities[0] !== null) {
        capabilities = this.state.locationInformation[0].capabilities.map(
          (capability, index) => (
            <li key={index}>{capability.capability_name}</li>
          ),
        )
      } else {
        capabilities = 'N/A'
      }
      let osbr
      if (this.state.locationInformation[0].osbr === 'N/A') {
        osbr = this.state.locationInformation[0].osbr
      } else {
        const osbrArray = this.state.locationInformation[0].osbr.split(' ')
        let osbrDate = osbrArray[2].split('-')
        osbrDate = osbrDate[1] + '/' + osbrDate[2] + '/' + osbrDate[0]
        osbr = this.state.locationInformation[0].osbr.replace(
          osbrArray[2],
          osbrDate,
        )
      }
      information = (
        <div>
          <h3 style={{ paddingBottom: '0px' }}>
            <u>
              <b style={{ fontSize: '38px' }}>
                Store {this.state.location}
                {this.state.locationInformation[0].name !== null
                  ? ' - ' + this.state.locationInformation[0].name
                  : ''}
              </b>
            </u>
          </h3>
          <u>
            <b>General</b>
          </u>
          <br />
          <b>Model: </b>{' '}
          {this.state.locationInformation[0].model !== null
            ? this.state.locationInformation[0].model
            : 'N/A'}
          &nbsp;
          <b>Region: </b>
          {this.state.locationInformation[0].region !== null
            ? this.state.locationInformation[0].region
            : 'N/A'}{' '}
          <b>Timezone: </b>
          {this.state.locationInformation[0].timezone !== null
            ? this.state.locationInformation[0].timezone
            : 'N/A'}
          <br />
          <b>Address:</b>{' '}
          {this.state.locationInformation[0].address !== null
            ? this.state.locationInformation[0].address
            : 'N/A'}
          <br />
          <b>Phone:</b>{' '}
          {this.state.locationInformation[0].phone !== null
            ? this.state.locationInformation[0].phone
            : 'N/A'}
          &nbsp;
          <b>OSBR: </b> {osbr}
          &nbsp;
          <b>Last Remodel Date: </b>
          {this.state.locationInformation[0].lastRemodelDate != null
            ? this.state.locationInformation[0].lastRemodelDate
            : 'N/A'}
          <br /> <br />
          <Grid container>
            <Grid item xs={5}>
              <u>
                <b>Hours</b>
              </u>
              {this.displayHours()}
            </Grid>
            <Grid item xs={7}>
              <u>
                <b>Capabilities</b>
              </u>
              <ul style={ulStyle}>{capabilities}</ul>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      information = this.state.locationInformation
    }

    let incidents = []
    if (
      this.state.locationIncidents.length > 0 &&
      this.state.locationIncidents !== 'No Active Incidents!'
    ) {
      const incidentColumns = [
        {
          label: 'Incident Number',
          name: 'incident_number',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <a
                  href={tableMeta.rowData[6]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value}
                </a>
              )
            },
          },
        },
        {
          label: 'Description',
          name: 'short_description',
        },
        { label: 'Business Impact', name: 'business_impact' },
        {
          label: 'Assignment Group',
          name: 'assignment_group',
        },
        { label: 'State', name: 'state' },
        {
          label: 'Time Opened',
          name: 'time_opened',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const formattedDate = new Date(
                tableMeta.rowData[5],
              ).toLocaleDateString()
              return formattedDate
            },
          },
        },
        { label: 'Url', name: 'incident_url', options: { display: false } },
      ]
      incidents = (
        <MUIDataTable
          title="Incidents"
          columns={incidentColumns}
          data={this.props.locationIncidents}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            rowsPerPage: 4,
            rowsPerPageOptions: [4, 10, 20],
            selectableRows: 'none',
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      incidents = this.state.locationIncidents
    }

    let projects = []
    if (
      this.state.locationProjects.length > 0 &&
      this.state.locationProjects !== 'No Projects Found!'
    ) {
      const columns = [
        {
          label: 'Health',
          name: 'health',
          options: {
            filterType: 'multiselect',
            hint: 'Support: Red/Yellow -> send to R&D, Blue/Green -> normal support',
          },
        },
        {
          label: 'Project | Scope Name',
          name: 'project_name',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Execution Period',
          name: 'period',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Execution Period Start Date',
          name: 'start_date',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Execution Period End Date',
          name: 'end_date',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Scope Status',
          name: 'status',
          options: {
            filterType: 'multiselect',
            hint: 'Scope Status: Complete - R&D work is complete; In-Progress - work not yet complete; Delayed - work is planned but is behind schedule',
          },
        },
        {
          label: 'Work Start Date',
          name: 'work_start_date',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Work End Date',
          name: 'work_end_date',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Hours | Time Frame of Work',
          name: 'work_time_frame',
          options: { filterType: 'multiselect' },
        },
        {
          label: '% Complete',
          name: 'percentage_complete',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Scheduled Status',
          name: 'scheduled_status',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Conditional Signoff Notes',
          name: 'conditional_sign_off_notes',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Delay Reason',
          name: 'delay_reason',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Notes',
          name: 'notes',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Turnover Date',
          name: 'turnover',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Go Live Date',
          name: 'go_live_date',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendor PM',
          name: 'vendor_project_manager',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendor Visit Dates',
          name: 'vendor_visit_dates',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Construction PM',
          name: 'construction_pm',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'TTS Project Manager',
          name: 'tts_project_manager',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Consolidation Vendor',
          name: 'consolidation_vendor',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendor Responsible for Scope',
          name: 'deployment_support_vendor',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'TTS Execution Team',
          name: 'tts_project_team',
          options: { filterType: 'multiselect' },
        },
        {
          label: 'Smartsheet URL',
          name: 'sheet_url',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Smartsheet Name',
          name: 'sheet_name',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Project Summary',
          name: 'project_summary',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Store Action Required',
          name: 'store_action_required',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Hardware Arriving',
          name: 'hardware_arriving',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Access Areas',
          name: 'access_areas',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Time to Task',
          name: 'time_to_task',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Time of Day',
          name: 'time_of_day',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Question Resource',
          name: 'question_resource',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Question Address',
          name: 'question_address',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Project has POS Scope',
          name: 'pos_scope',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
        {
          label: 'Vendors will have control room PIN',
          name: 'vendors_have_pin',
          options: {
            display: false,
            filterType: 'multiselect',
          },
        },
      ]
      const projectDetailsColumns = [
        {
          label: 'Health',
          name: 'health',
          options: {
            hint: 'Support: Red/Yellow -> send to R&D, Blue/Green -> normal support',
          },
        },
        {
          label: 'Project | Scope Name',
          name: 'project_name',
        },
        {
          label: 'Execution Period',
          name: 'period',
        },
        {
          label: 'Scope Status',
          name: 'status',
          options: {
            hint: 'Scope Status: Complete - R&D work is complete; In-Progress - work not yet complete; Delayed - work is planned but is behind schedule',
          },
        },
        {
          label: '% Complete',
          name: 'percentage_complete',
        },
        {
          label: 'Scheduled Status',
          name: 'scheduled_status',
        },
        {
          label: 'Conditional Signoff Notes',
          name: 'conditional_sign_off_notes',
        },
        {
          label: 'Delay Reason',
          name: 'delay_reason',
        },
      ]
      const projectTimelinesColumns = [
        {
          label: 'Work Start Date',
          name: 'work_start_date',
        },
        {
          label: 'Work End Date',
          name: 'work_end_date',
        },
        {
          label: 'Execution Period Start Date',
          name: 'start_date',
        },
        {
          label: 'Execution Period End Date',
          name: 'end_date',
        },
        {
          label: 'Hours | Time Frame of Work',
          name: 'work_time_frame',
        },
        {
          label: 'Turnover Date',
          name: 'turnover',
        },
        {
          label: 'Go Live Date',
          name: 'go_live_date',
        },
        {
          label: 'Vendor Visit Dates',
          name: 'vendor_visit_dates',
        },
      ]
      const contactInformationColumns = [
        {
          label: 'Vendor PM',
          name: 'vendor_project_manager',
        },
        {
          label: 'TTS Execution Team',
          name: 'tts_project_team',
        },
        {
          label: 'TTS Project Manager',
          name: 'tts_project_manager',
        },
        {
          label: 'Construction PM',
          name: 'construction_pm',
        },
        {
          label: 'Consolidation Vendor',
          name: 'consolidation_vendor',
        },
        {
          label: 'Vendor Responsible for Scope',
          name: 'deployment_support_vendor',
        },
      ]
      const miscColumns = [
        {
          label: 'Smartsheet Link',
          name: 'sheet_name',
        },
        {
          label: 'Notes',
          name: 'notes',
        },
      ]
      const projectSummaryColumns = [
        {
          label: 'Project Summary',
          name: 'project_summary',
        },
        {
          label: 'Store Action Required',
          name: 'store_action_required',
        },
        {
          label: 'Hardware Arriving',
          name: 'hardware_arriving',
        },
        {
          label: 'Access Areas',
          name: 'access_areas',
        },
        {
          label: 'Time to Task',
          name: 'time_to_task',
        },
        {
          label: 'Time of Day',
          name: 'time_of_day',
        },
        {
          label: 'Question Resource',
          name: 'question_resource',
        },
        {
          label: 'Question Address',
          name: 'question_address',
        },
        {
          label: 'Project has POS Scope',
          name: 'pos_scope',
        },
        {
          label: 'Vendors will have control room PIN',
          name: 'vendors_have_pin',
        },
      ]
      projects = (
        <MUIDataTable
          title="Projects"
          columns={columns}
          data={this.state.locationProjects}
          options={{
            responsive: 'vertical',
            rowsPerPageOptions: [10, 20, 50],
            expandableRows: true,
            jumpToPage: true,
            selectableRows: 'none',
            renderExpandableRow: (rowData) => {
              var projectDetailsData = [
                {
                  health: (
                    <Tooltip title={rowData[5]}>
                      <Badge>
                        {rowData[0] === 'Green'
                          ? greenCircle
                          : rowData[0] === 'Blue'
                            ? blueCircle
                            : rowData[0] === 'Yellow'
                              ? yellowCircle
                              : rowData[0] === 'Red'
                                ? redCircle
                                : grayCircle}
                      </Badge>
                    </Tooltip>
                  ),
                  project_name: rowData[1],
                  period: rowData[2],
                  status: rowData[5],
                  percentage_complete: rowData[9],
                  scheduled_status: rowData[10],
                  conditional_sign_off_notes: rowData[11],
                  delay_reason: (
                    <Grid>
                      {rowData[5].toLowerCase().includes('delay') &&
                      rowData[12] !== '' ? (
                        <Typography
                          style={{ backgroundColor: 'red', color: 'white' }}
                        >
                          {rowData[12]}
                        </Typography>
                      ) : (
                        rowData[12]
                      )}
                    </Grid>
                  ),
                },
              ]
              var projectTimelinesData = [
                {
                  work_start_date: rowData[6],
                  work_end_date: rowData[7],
                  start_date: rowData[3],
                  end_date: rowData[4],
                  work_time_frame: rowData[8],
                  turnover: rowData[14],
                  go_live_date: rowData[15],
                  vendor_visit_dates: rowData[17],
                },
              ]
              var contactInformationData = [
                {
                  vendor_project_manager: rowData[16],
                  tts_project_team: rowData[22],
                  tts_project_manager: (
                    <Grid>
                      {rowData[5].toLowerCase().includes('delay') ? (
                        <Typography
                          style={{ backgroundColor: 'red', color: 'white' }}
                        >
                          {rowData[19]}
                        </Typography>
                      ) : (
                        rowData[19]
                      )}
                    </Grid>
                  ),
                  construction_pm: rowData[18],
                  consolidation_vendor: rowData[20],
                  deployment_support_vendor: rowData[21],
                },
              ]
              var miscData = [
                {
                  sheet_name: (
                    <a
                      href={rowData[23]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rowData[24]}
                    </a>
                  ),
                  notes: (
                    <Grid>
                      {rowData[5].toLowerCase().includes('delay') &&
                      rowData[12] === '' &&
                      rowData[13] !== '' ? (
                        <Typography
                          style={{ backgroundColor: 'red', color: 'white' }}
                        >
                          {rowData[13]}
                        </Typography>
                      ) : (
                        rowData[13]
                      )}
                    </Grid>
                  ),
                },
              ]
              var projectSummaryData = [
                {
                  project_summary: rowData[25],
                  store_action_required: rowData[26],
                  hardware_arriving: rowData[27],
                  access_areas: rowData[28],
                  time_to_task: rowData[29],
                  time_of_day: rowData[30],
                  question_resource: rowData[31],
                  question_address: rowData[32],
                  pos_scope: rowData[33],
                  vendors_have_pin: rowData[34],
                },
              ]
              return (
                <TableRow>
                  <TableCell colSpan={rowData.length}>
                    <Box sx={{ border: 3 }}>
                      <ThemeProvider theme={this.expandedRowMuiTheme()}>
                        <div>
                          <div>
                            <MUIDataTable
                              title="Project Details"
                              columns={projectDetailsColumns}
                              data={projectDetailsData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Project Timelines"
                              columns={projectTimelinesColumns}
                              data={projectTimelinesData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Contact Information"
                              columns={contactInformationColumns}
                              data={contactInformationData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Project Summary"
                              columns={projectSummaryColumns}
                              data={projectSummaryData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                          <div>
                            <MUIDataTable
                              title="Misc"
                              columns={miscColumns}
                              data={miscData}
                              options={{
                                responsive: 'vertical',
                                pagination: false,
                                expandableRows: false,
                                selectableRows: 'none',
                                sort: false,
                                viewColumns: false,
                                download: false,
                                filter: false,
                                search: false,
                                print: false,
                              }}
                            />
                          </div>
                        </div>
                      </ThemeProvider>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      projects = this.state.locationProjects
    }

    const incidentPanel = <div>{incidents}</div>
    const projectPanel = <div>{projects}</div>

    return (
      <div>
        <HeaderTitle title="Location Projects" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container>
              <Grid item xs={4}>
                <form onSubmit={this.handleOnSearch}>
                  <TextField
                    type="text"
                    variant="standard"
                    value={this.state.locationNumber}
                    onChange={(event) =>
                      this.setState({ locationNumber: event.target.value })
                    }
                    placeholder="enter location number"
                    required
                    InputProps={{
                      className: 'redUnderline',
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.locationNumber}
                    type="submit"
                  >
                    <Search />
                    search
                  </Button>
                </form>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4">
                  {this.state.notFoundMessage}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={5}>
                {information}
              </Grid>
              <Grid item xs={12} md={7}>
                {incidentPanel}
              </Grid>
              <Grid item xs={12}>
                {projectPanel}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locationInformation: state.locationProjects.locationInformation,
    locationProjects: state.locationProjects.locationProjects,
    locationIncidents: state.locationProjects.locationIncidents,
    location: state.locationProjects.location,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  getLocationInformation,
  getLocationProjects,
  getLocationIncidents,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withParams(LocationProjects))
