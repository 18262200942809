import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { FormControl } from '@mui/material'
import RevokeMessageModal from './RevokeMessageModal'
import RevokePinHelpDialog from './RevokePinHelpDialog'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'

export default class RevokePinDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: [],
      email: '',
    }
    this.validateForm = this.validateForm.bind(this)
  }

  resetForm = () => {
    this.setState({
      email: '',
      locations: [],
      buttonEnabled: false,
    })
  }

  validateForm = () => {
    if (this.state.email !== '' && this.state.locations !== []) {
      this.setState({ buttonEnabled: true })
    }
  }

  render() {
    const inputProps = {
      className: 'redUnderline',
      style: { margin: '16px 10% 10% 10%' },
    }
    const labelProps = { style: { paddingLeft: '11%' } }
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleRevokeClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{
            marginTop: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Revoke PINs
          <Tooltip title={'Help'}>
            <IconButton
              onClick={this.props.handleRevokePinHelpOpen}
              className="customToolbarIcon"
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              this.props.revokePinDialogData(
                event,
                this.state.email,
                this.state.locations,
                this.props.user,
              )
            }}
          >
            <FormControl style={{ width: '90%' }}>
              <TextField
                type="text"
                variant="standard"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value }, function () {
                    this.validateForm()
                  })
                }}
                placeholder="Example: name@target.com"
                label="Email"
                required
                style={{
                  width: '99%',
                  margin: 0,
                }}
                InputProps={inputProps}
                InputLabelProps={labelProps}
              />
              <TextField
                type="text"
                variant="standard"
                value={this.state.locations}
                onChange={(event) => {
                  this.setState({ locations: event.target.value }, function () {
                    this.validateForm()
                  })
                }}
                placeholder="Enter comma separated locations without T or leading zeros"
                label="Locations"
                required
                style={{
                  width: '99%',
                  margin: 0,
                }}
                InputProps={inputProps}
                InputLabelProps={labelProps}
              />
            </FormControl>
            <br />
            <DialogActions>
              <Button
                onClick={this.props.handleRevokeClose}
                className="helpModalClose"
              >
                Cancel
              </Button>
              <Button type="submit" disabled={!this.state.buttonEnabled}>
                Revoke
              </Button>
            </DialogActions>
          </form>
          <RevokeMessageModal
            open={this.props.messageOpen}
            handleClose={this.props.handleMessageClose}
            revokeMessage={this.props.revokeMessage}
          />
          <RevokePinHelpDialog
            open={this.props.helpOpen}
            handleRevokePinHelpClose={this.props.handleRevokePinHelpClose}
          />
        </DialogContent>
      </Dialog>
    )
  }
}
