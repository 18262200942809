import React from 'react'
import { connect } from 'react-redux'
import HeaderTitle from '../Header/HeaderTitle'
import {
  createPrintJob,
  getPrinterNames,
  clearMessage,
} from '../../store/PrintFile/actionCreator'
import {
  Grid,
  Typography,
  Card,
  CardActions,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from '@mui/material'
import PrintSuccessModal from './PrintSuccessModal'
import { truncateLocationId } from '../../common/tLocationNumbers'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'

export class PrintFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      printerNameList: [],
      locationId: '',
      hostname: '',
      selectedFile: null,
      eventsMessage: '',
      loading: false,
      statusOpen: false,
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.createNewPrint = this.createNewPrint.bind(this)
    this.loadPrinters = this.loadPrinters.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.dialogBox = React.createRef()
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Print File',
          pageName: 'Print File',
          pageType: 'Print File',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: {
        name: 'ziptie',
      },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Print File' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      lanId: undefined,
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      printerNameList: JSON.parse(JSON.stringify(nextProps.printerNameList)),
      eventsMessage: nextProps.eventsMessage,
    })
  }

  handleStatusOpen() {
    this.setState({ statusOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  loadPrinters(locationId) {
    if (truncateLocationId(locationId) !== '') {
      this.props.getPrinterNames(locationId)
    }
  }

  fileData = () => {
    if (this.state.selectedFile !== null) {
      return <p>{this.state.selectedFile.name}</p>
    }
  }

  createNewPrint = (event, selectedFile, locationId, hostname, user) => {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Print File - Print' },
      user: { id: user.email },
    })
    event.preventDefault()
    this.props.clearMessage()
    const userId = user.lanId
    const printFileData = {
      locationId: parseInt(truncateLocationId(locationId)),
      printerName: hostname,
      userId: userId,
    }
    this.props.createPrintJob(printFileData, selectedFile)
    this.setState({ statusOpen: true })
    this.resetForm()
  }

  resetForm = () => {
    this.setState({
      locationId: '',
      selectedFile: null,
      hostname: '',
      buttonEnabled: false,
    })
  }

  validateForm = () => {
    if (
      this.state.locationId !== '' &&
      this.state.selectedFile !== null &&
      this.state.hostname !== '' &&
      this.state.hostname !== 'Error Loading Printer Names'
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    const { userInfo } = this.props
    let modalTitle
    if (this.state.eventsMessage === 'Print Job Sent!') {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage !== 'Print Job Sent!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <div>
        <HeaderTitle title="Print Files" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={5}>
                <Card style={{ height: '60vh' }}>
                  <Grid container className="cardBanner">
                    <Grid item sm={12} lg={4}>
                      PRINT FILE TO LABEL
                    </Grid>
                  </Grid>
                  <form
                    onSubmit={(event) =>
                      this.createNewPrint(
                        event,
                        this.state.selectedFile,
                        this.state.locationId,
                        this.state.hostname,
                        userInfo,
                      )
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '6%',
                    }}
                  >
                    <Grid
                      container
                      style={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        display: 'flex',
                      }}
                    >
                      <Grid
                        container
                        style={{
                          paddingLeft: 8,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flexBasis: '90%',
                          }}
                        >
                          <Grid
                            item
                            xs={14}
                            md={10}
                            style={{
                              width: '70%',
                              margin: '2%',
                            }}
                          >
                            <TextField
                              htmlFor="locationId"
                              type="text"
                              variant="standard"
                              value={this.state.locationId}
                              placeholder="Example: 3"
                              label="Store Number"
                              required
                              style={{ width: '50%' }}
                              onBlur={(event) => {
                                this.setState(function () {
                                  if (this.state.locationId !== '') {
                                    this.loadPrinters(this.state.locationId)
                                  }
                                })
                              }}
                              onChange={(event) => {
                                this.setState(
                                  {
                                    locationId: event.target.value,
                                  },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }}
                              inputProps={{
                                name: 'locationId',
                                id: 'locationId',
                              }}
                              InputProps={{
                                className: 'redUnderline',
                              }}
                            />
                          </Grid>
                          <br />
                          <Grid
                            item
                            xs={14}
                            md={10}
                            style={{
                              width: '70%',
                              margin: '2%',
                            }}
                          >
                            <FormControl
                              variant="standard"
                              style={{
                                width: '90%',
                                display: 'inline-flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                flexBasis: '90%',
                                position: 'relative',
                              }}
                            >
                              <InputLabel
                                htmlFor="hostname"
                                style={{
                                  maxWidth: '100%',
                                }}
                              >
                                Printer Hostname
                              </InputLabel>
                              <Select
                                value={this.state.hostname}
                                onChange={(event) => {
                                  this.setState(
                                    { hostname: event.target.value },
                                    function () {
                                      this.validateForm()
                                    },
                                  )
                                }}
                                inputProps={{
                                  name: 'hostname',
                                  id: 'hostname',
                                }}
                              >
                                {this.state.printerNameList.length > 0
                                  ? this.state.printerNameList.map(
                                      (printerName) => (
                                        <MenuItem
                                          value={
                                            printerName.printer_name != null &&
                                            printerName.printer_name.includes(
                                              'T',
                                            )
                                              ? printerName.printer_name.split(
                                                  '.',
                                                  1,
                                                )[0]
                                              : printerName.printer_name
                                          }
                                        >
                                          {printerName.printer_name != null &&
                                          printerName.printer_name.includes('T')
                                            ? printerName.printer_name.split(
                                                '.',
                                                1,
                                              )[0]
                                            : printerName.printer_name}
                                          {' - '}
                                          {printerName.display_name}
                                        </MenuItem>
                                      ),
                                    )
                                  : ''}
                              </Select>
                            </FormControl>
                          </Grid>
                          <br />
                          <Grid
                            item
                            xs={14}
                            md={10}
                            style={{
                              width: '70%',
                              margin: '2%',
                            }}
                          >
                            <input
                              id="selectedFile"
                              type="file"
                              hidden
                              key={this.state.selectedFile}
                              accept="application/pdf"
                              onChange={(event) => {
                                this.setState(
                                  { selectedFile: event.target.files[0] },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }}
                            />
                            <label htmlFor="selectedFile">
                              <Button
                                variant="contained"
                                type="file"
                                color="primary"
                                component="span"
                              >
                                Choose File
                              </Button>
                            </label>
                            {this.fileData()}
                          </Grid>
                        </div>
                        <CardActions style={{ width: '100%' }}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: 20,
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              disabled={!this.state.buttonEnabled}
                            >
                              Print
                            </Button>
                          </Grid>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </form>
                </Card>
                <PrintSuccessModal
                  open={this.state.statusOpen}
                  handleClose={this.handleStatusClose}
                  eventsMessage={this.state.eventsMessage}
                  title={modalTitle}
                />
              </Grid>
            </div>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
    printerNameList: state.printFile.printerNameList,
    eventsMessage: state.printFile.eventsMessage,
  }
}

const mapDispatchToProps = {
  createPrintJob,
  getPrinterNames,
  clearMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintFile)
