import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class WebhookHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Webhook Information</DialogTitle>
        <DialogContent>
          <h4>Welcome to the Webhook Management page.</h4>
          <p>
            This is where you can see the webhooks are currently available for
            R&D Smartsheets. Each row in the table shows information for an
            individual webhook regarding the name as well as a link to the sheet
            it is referencing, the Owner or creator of the webhook, the last
            time that the webhook has successfully run validation, and a toggle
            switch to disable or enable the webhook. Columns displaying
            additional data can be shown by selecting them from the View Columns
            in the toolbar.
          </p>
          <p>
            Additional webhooks can be created on the Create Webhook pop-up by
            clicking on the PLUS icon in the Webhooks toolbar.
          </p>
          <p>
            To delete a webhook or webhooks, select the applicable row or rows
            by clicking on its corresponding CHECKBOX to the immediate left of
            its name. Once you have selected the webhook or webhooks you wish to
            delete, click on the TRASH CAN icon in the Webhooks Toolbar.
          </p>
          <p>
            If you wish to disable a webhook without deleting it, or to enable
            an existing webhook, select the corresponding TOGGLE button on the
            right end of the row. A gray TOGGLE, switched to the left means that
            its webhook is in a disabled state, whereas a green TOGGLE, switched
            to the right means that its webhook is currently enabled.
            Additionally, by hovering your cursor over the TOGGLE, a tooltip
            will appear giving additional information regarding the state of the
            webhook.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleWebhookHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
