import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material/'

export default class CreateCutsheetsSuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventsMessage: '',
      smartsheetData: [],
      smartsheetDataCopy: [],
      copied: false,
    }
  }

  render() {
    const smartsheetData =
      this.props.smartsheetData !== undefined &&
      this.props.smartsheetData !== []
        ? this.props.smartsheetData.map((link) => (
            <div>
              <a
                href={link.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            </div>
          ))
        : ''
    const smartsheetDataCopy =
      this.props.smartsheetData !== undefined &&
      this.props.smartsheetData !== []
        ? this.props.smartsheetData.map(
            (link) => `${link.name} - ${link.id} - ${link.permalink}`,
          )
        : ''
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.eventsMessage === '' ||
        this.props.eventsMessage === undefined ? (
          <div>
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
            {''}
          </div>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                {this.props.title === 'Success'
                  ? smartsheetData
                  : this.props.eventsMessage}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ copied: false })
                    this.props.handleClose()
                  }}
                >
                  OK
                </Button>
                <CopyToClipboard
                  text={smartsheetDataCopy}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <Tooltip title={'Copy'} placement="right">
                    <IconButton color="primary" aria-label="copy" size="large">
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
                {this.state.copied ? (
                  <Typography
                    sx={{
                      color: 'secondary',
                      width: '40px',
                      display: 'inline-block',
                    }}
                  >
                    Copied!
                  </Typography>
                ) : null}
              </Typography>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
