import auth from './auth'
import { combineReducers } from 'redux'
import layout from '../store/layout/reducer'
import notification from './notification/reducer'
import homePage from './HomePage/reducer'
import locationProjects from './LocationProjects/reducer'
import cabling from './Cabling/reducer'
import deviceStatuses from './DeviceStatuses/reducer'
import webhookManagement from './WebhooksManagement/reducer'
import changeRequests from './ChangeRequests/reducer'
import automationTests from './AutomationTests/reducer'
import pinGeneration from './PinGeneration/reducer'
import printFile from './PrintFile/reducer'
import kitList from './KitList/reducer'
import createSmartsheets from './CreateSmartsheets/reducer'
import deviceConfiguration from './DeviceConfiguration/reducer'
import additionalTools from './AdditionalTools/reducer'
import procurement from './Procurement/reducer'

const rootReducer = combineReducers({
  auth,
  layout,
  notification,
  homePage,
  locationProjects,
  cabling,
  deviceStatuses,
  webhookManagement,
  changeRequests,
  automationTests,
  pinGeneration,
  printFile,
  kitList,
  createSmartsheets,
  deviceConfiguration,
  additionalTools,
  procurement,
})

export default rootReducer
