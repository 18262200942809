import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'

export default class WebhookStatusModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statusMessage: '',
    }
  }

  render() {
    let modalTitle
    if (this.props.statusMessage === '') {
      modalTitle = ''
    } else {
      modalTitle = 'Automation Tests Not Enabled'
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <div>
            <Typography className="modalBody" gutterBottom>
              {this.props.statusMessage}
            </Typography>
            <Button variant="contained" onClick={this.props.handleClose}>
              OK
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}
