// THIS FILE WAS PREVIOUSLY PART OF THE DEPRECATED REACT-PRAXIS-COMPONENTS LIBRARY.
// THE RECOMMENDED FIX FROM THE PRAXIS TEAM IS TO COPY & PASTE NEEDED COMPONENTS
// INTO YOUR PROJECT

import * as React from 'react'
import { string, bool, node, func } from 'prop-types'
import classnames from 'classnames'
import { grey } from '@mui/material/colors'
import List from '@mui/material/List'
import Drawer from '@mui/material/Drawer'
import Link from './Link'

const styles = () => ({
  activeLink: {
    backgroundColor: grey[400],
  },
  linkRoot: {
    color: grey[900],
    '&:Mui-focus, &:Mui-Hover': {
      outline: 'none',
    },
  },
  list: {
    overflow: 'visible',
    flex: '1 1 auto',
  },
  paper: {
    width: 250,
    position: 'static',
  },
  paper__isFixed: {
    position: 'fixed',
  },
})

export const SideNav = (props) => {
  const { children, className, isOpen, handleClose, variant } = props
  const { activeLink, linkRoot, list, paper, paper__isFixed } = styles

  const filteredArray = children.filter((element) => element.key !== 'footer')
  const listItems = filteredArray.map((_child, key) => {
    let child = _child

    if (child.type === Link) {
      const childClasses = child.props.classes || {}

      child = React.cloneElement(child, {
        activeClassName: child.props.activeClassName || activeLink,
        classes: {
          root: childClasses.root || linkRoot,
          ...childClasses,
        },
      })
    }
    return <li key={key}>{child}</li>
  })
  const navFooter = children.filter((element) => element.key === 'footer')

  return (
    <Drawer
      open={isOpen}
      onClick={() => handleClose()}
      classes={{
        paper: classnames(
          paper,
          {
            [paper__isFixed]: variant === 'temporary',
          },
          className,
        ),
      }}
      variant={variant}
    >
      {listItems.length > 0 ? (
        <List
          aria-label="navigation"
          disablePadding
          role="navigation"
          classes={{
            root: list,
          }}
        >
          {listItems}
        </List>
      ) : null}
      {navFooter}
    </Drawer>
  )
}

SideNav.propTypes = {
  children: node,
  className: string,
  isOpen: bool,
  onClick: func,
  variant: string,
}

SideNav.defaultProps = {
  classes: {},
  variant: 'temporary',
}

export default SideNav
