import { PropTypes, shape, string, func, object } from 'prop-types'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { ProtectedElement, useAuth } from '@praxis/component-auth'
import { IdleTimer } from '../IdleTimer'
import { useTheme } from '@mui/material/styles'
import HomeIcon from '@mui/icons-material/Home'
import SideNav, {
  Footer,
  Header,
  Icon,
  Link,
  Text,
} from '../../common/PraxisComponents/SideNav'
import UserBar from '../../common/PraxisComponents/UserBar/UserBar'
import { Box, Grid, List } from '@mui/material'
import { closeSideNav, openSideNav } from '../../store/layout/actionCreator'
import AppHeader from '../Header/Header'
import HomePage from '../HomePage/HomePage'
import NotFound from '../NotFoundPage/NotFoundPage'
import { signInSuccess } from '../../store/auth'
import { jwtDecode } from 'jwt-decode'
import ProjectsIcon from '@mui/icons-material/Assignment'
import CableIcon from '@mui/icons-material/Cable'
import TvsIcon from '@mui/icons-material/ListAlt'
import PingIcon from '@mui/icons-material/ImportExport'
import ChangeIcon from '@mui/icons-material/Update'
import BuildIcon from '@mui/icons-material/Build'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PrintFileIcon from '@mui/icons-material/Print'
import KitListIcon from '@mui/icons-material/MenuBook'
import SettingsIcon from '@mui/icons-material/Settings'
import WebhookIcon from '@mui/icons-material/Webhook'
import LocationProjects from '../LocationProjects/LocationProjects'
import Avatar from '@mui/material/Avatar'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Cabling from '../Cabling/Cabling'
import HeaderTitle from '../Header/HeaderTitle'
import DeviceStatuses from '../DeviceStatuses/DeviceStatuses'
import WebhooksManagement from '../WebhooksManagement/WebhooksManagement'
import ChangeRequests from '../ChangeRequests/ChangeRequests'
import AutomationTests from '../AutomationTests/AutomationTests'
import PinGeneration from '../PinGeneration/PinGeneration'
import logo from '../../images/Ziptie_Red.png'
import PrintFile from '../PrintFile/PrintFile'
import KitList from '../KitList/KitList'
import CreateSmartsheets from '../CreateSmartsheets/CreateSmartsheets'
import DeviceConfiguration from '../DeviceConfiguration/DeviceConfiguration'
import AdditionalTools from '../AdditionalTools/AdditionalTools'
import Procurement from '../Procurement/Procurement'

export function Layout(props) {
  const { headerTitle, sideNavIsOpen, userInfo } = props

  // Connect to the `AuthContext` provided by `App` above, using a React hook:
  const { login, logout, isAuthenticated, session } = useAuth()

  // Access theme properties with a React hook:
  const theme = useTheme()

  const handleMenuButtonClick = () => {
    if (props.sideNavIsOpen) {
      props.closeSideNav()
    } else {
      props.openSideNav()
    }
  }

  const handleCloseSideNav = () => {
    if (props.sideNavIsOpen) {
      props.closeSideNav()
    }
  }

  const handleSignOut = () => {
    logout()
    props.handleCloseSideNav()
    props.history.push('/')
  }

  const onIdle = () => {
    if (session.isAuthenticated) {
      logout()
    }
  }

  const email = (isAuthenticated() && session?.userInfo?.email) || ''
  const company = (isAuthenticated() && session?.userInfo?.company) || ''

  if (isAuthenticated() && props.userInfo.email === '') {
    props.signInSuccess(session)
  }

  let userToken = session?.userInfo ? session.userInfo.accessToken : null

  if (userToken !== undefined && userToken !== null) {
    // Check against expire time
    let tokenExpUtc = jwtDecode(userToken.replace('Bearer ', '')).exp
    let tokenExpDateTime = new Date(0)
    let currentTime = new Date()
    tokenExpDateTime = tokenExpDateTime.setUTCSeconds(tokenExpUtc)
    currentTime = currentTime.setUTCSeconds(999)
    if (tokenExpDateTime < currentTime) {
      // force a logout!
      logout()
    }
  }

  return (
    <IdleTimer element={document} onIdle={onIdle} timeout={1000 * 60 * 30}>
      <HeaderTitle title="ziptie" />
      {isAuthenticated() ? (
        <SideNav isOpen={sideNavIsOpen} handleClose={handleCloseSideNav}>
          <Header>
            <UserBar
              displayName={email}
              company={company}
              handleSignIn={login}
              handleSignOut={logout}
              isAuthenticated={isAuthenticated}
            />
          </Header>
          <Link to="/" exact>
            <Icon>
              <HomeIcon />
            </Icon>
            <Text>Home</Text>
          </Link>
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-READ') ? (
            <Link to="/LocationProjects" exact>
              <Icon>
                <ProjectsIcon />
              </Icon>
              <Text>Location Projects</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ? (
            <Link to="/PinGeneration" exact>
              <Icon>
                <LockOpenIcon />
              </Icon>
              <Text>Pin Generation</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ? (
            <Link to="/Cabling" exact>
              <Icon>
                <CableIcon />
              </Icon>
              <Text>Cabling</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-READ') ? (
            <Link to="/PrintFile" exact>
              <Icon>
                <PrintFileIcon />
              </Icon>
              <Text>Print File</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ? (
            <Link to="/KitList" exact>
              <Icon>
                <KitListIcon />
              </Icon>
              <Text>Kit List</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-NE') ? (
            <Link to="/CreateSmartsheets" exact>
              <Icon>
                <TvsIcon />
              </Icon>
              <Text>Create Smartsheets</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-READ') ? (
            <Link to="/DeviceStatuses" exact>
              <Icon>
                <PingIcon />
              </Icon>
              <Text>Device Status</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-ZIPTIE-CONFIGURATOR') ? (
            <Link to="/DeviceConfiguration" exact>
              <Icon>
                <SettingsIcon />
              </Icon>
              <Text>Device Configuration</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-READ') ? (
            <Link to="/ChangeRequests" exact>
              <Icon>
                <ChangeIcon />
              </Icon>
              <Text>Change Requests</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes('APP-OAUTH2-ZIPTIE-CAD') ? (
            <Link to="/AdditionalTools" exact>
              <Icon>
                <BuildIcon />
              </Icon>
              <Text>Additional Tools</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ? (
            <Link to="/AutomatedTests" exact>
              <Icon>
                <WebhookIcon />
              </Icon>
              <Text>Automated Tests</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          session.userInfo.memberOf.includes(
            'APP-OAUTH2-ZIPTIE-PROCUREMENT',
          ) ? (
            <Link to="/Procurement" exact>
              <Icon>
                <ProjectsIcon />
              </Icon>
              <Text>Procurement</Text>
            </Link>
          ) : (
            ''
          )}
          {session.userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ? (
            <Link to="/WebhooksManagement" exact>
              <Icon>
                <WebhookIcon />
              </Icon>
              <Text>Webhooks Management</Text>
            </Link>
          ) : (
            ''
          )}
          <Footer
            key="footer"
            id="footerId"
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
            }}
          >
            <List
              sx={{
                color: theme.palette.primary,
                display: 'flex',
                flexWrap: 'wrap',
                fontSize: theme.typography.caption.fontSize,
                listStyle: 'none',
                margin: 0,
                padding: theme.spacing(1.5),
                '& li': {
                  padding: theme.spacing(0.5),
                },
                '& a': {
                  color: theme.typography.caption.color,
                  textDecoration: 'none',
                  transition: theme.transitions.create('color', {
                    duration: theme.transitions.duration.shorter,
                  }),
                  ':hover': {
                    color: theme.palette.primary[500],
                  },
                },
              }}
            >
              <li>
                <a href="http://praxis.target.com" id="praxisLink">
                  Made with Praxis
                </a>
              </li>
            </List>
          </Footer>
        </SideNav>
      ) : (
        ''
      )}
      {isAuthenticated() ? (
        <AppHeader
          title={headerTitle}
          menuAction={() => handleMenuButtonClick()}
        />
      ) : (
        ''
      )}
      {isAuthenticated() ? (
        <Box sx={{ padding: theme.spacing() }}>
          <Grid container>
            <Grid item xs={12}>
              {/* routes */}
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/LocationProjects/:locationId"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-READ']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <LocationProjects />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/LocationProjects"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-READ']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <LocationProjects />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/PinGeneration"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <PinGeneration />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/Cabling"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <Cabling />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/PrintFile"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-READ']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <PrintFile />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/KitList"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <KitList />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/CreateSmartsheets"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-NE']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <CreateSmartsheets />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/DeviceStatuses"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-READ']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <DeviceStatuses />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/DeviceConfiguration"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'App-ziptie-CONFIGURATOR']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <DeviceConfiguration />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/ChangeRequests"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-READ']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <ChangeRequests />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/AdditionalTools"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN', 'APP-OAUTH2-ZIPTIE-CAD']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <AdditionalTools />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/Procurement"
                  element={
                    <ProtectedElement
                      allowed={[
                        'APP-ZIPTIE-ADMIN',
                        'APP-OAUTH2-ZIPTIE-PROCUREMENT',
                      ]}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <Procurement />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/AutomatedTests"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <AutomationTests />
                    </ProtectedElement>
                  }
                />
                <Route
                  path="/WebhooksManagement"
                  element={
                    <ProtectedElement
                      allowed={['APP-ZIPTIE-ADMIN']}
                      session={session}
                      unauthorizedRoute={'*'}
                    >
                      <WebhooksManagement />
                    </ProtectedElement>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Grid>
          </Grid>
        </Box>
      ) : userInfo.lanId !== undefined && userInfo.lanId !== '' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '90vh',
            flexDirection: 'column',
          }}
        >
          <div>
            <Avatar sx={{ margin: 10, width: 80, height: 80 }}>
              <AccountCircle style={{ width: '80px', height: '80px' }} />
            </Avatar>
          </div>
          <div>
            <Typography variant="h4" gutterBottom>
              <center>You are not an authorized user.</center> <br />
              Please raise a{' '}
              <a
                href="https://myaccess.target.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                MyAccess
              </a>{' '}
              request for <b>APP-OAUTH2-ziptie-Read</b>.
            </Typography>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignOut}
              sx={{
                height: 50,
                width: '40vh',
                fontSize: 22,
                marginTop: '80px',
              }}
            >
              Log Out
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '90vh',
            flexDirection: 'column',
          }}
        >
          <AppHeader title="ziptie" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '90vh',
              minHeight: '60vh',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontSize: '13vh', fontWeight: 500 }}
              >
                Ziptie
              </Typography>
            </div>
            <img
              alt="Ziptie logo"
              src={logo}
              onClick={() => login({ redirect: window.location.href })}
              style={{
                marginBottom: 30,
                height: '50%',
                width: 'auto',
                cursor: 'pointer',
              }}
            />
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => login({ redirect: window.location.href })}
                sx={{ height: 50, width: '40vh', fontSize: 22 }}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      )}
    </IdleTimer>
  )
}

Layout.propTypes = {
  classes: object,
  headerTitle: string,
  accessToken: string,
  openSideNav: func,
  closeSideNav: func,
  userInfo: shape({
    email: string,
    company: string,
    memberOf: PropTypes.arrayOf(string),
  }),
}

const mapStateToProps = (state) => ({
  headerTitle: state.layout.headerTitle,
  sideNavIsOpen: state.layout.sideNavIsOpen,
  accessToken: state.auth.accessToken,
  userInfo: state.auth.userInfo,
})

const mapDispatchToProps = {
  openSideNav,
  closeSideNav,
  signInSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
