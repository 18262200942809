import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function createPrintJob(printData, selectedFile) {
  return async function (dispatch) {
    var formData = new FormData()
    formData.append('file', selectedFile)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    await axios
      .post(
        `${apiConfig.api.url}/print_files?location_id=${printData.locationId}&printer_name=${printData.printerName}&user_id=${printData.userId}`,
        formData,
        config,
      )
      .then((resp) => {
        var result = {
          message: '',
        }
        if (resp.status === 201) {
          result = {
            message: `Print Job Sent!`,
          }
        } else {
          result = {
            message: 'Failed to send print job, please retry.',
          }
        }
        dispatch({
          type: types.CREATE_PRINT_JOB,
          payload: result,
        })
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_PRINT_JOB,
          payload: { message: 'Failed to send print job, please retry.' },
        })
      })
  }
}

export function getPrinterNames(locationId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/printers?location_id=${locationId}`)
      .then((resp) => {
        dispatch({
          type: types.GET_PRINTER_NAME_LIST,
          payload: resp.data,
        })
      })
      .catch((error) => {
        var result = [{ printer_name: 'Error Loading Printer Names' }]
        dispatch({
          type: types.GET_PRINTER_NAME_LIST,
          payload: result,
        })
      })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}
