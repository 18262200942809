import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class CreatePlatformDataSheetHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Create Platform Data Sheet Help</DialogTitle>
        <DialogContent>
          <h4>Creating A Platform Data Sheet Is Easy!</h4>
          <div>
            <ol>
              <li>
                In the Platform Data Sheet Name text field, enter the name of
                the sheet you wish to create. Note that the final sheet will
                have '- Platform Team Data' added to the name as part of the
                final process.
              </li>
              <li>
                Once all of the sheet name has been entered, click the Submit
                button. A link to your newly created platform data sheet will be
                generated. Congratulations!
              </li>
            </ol>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
