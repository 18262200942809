import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  LinearProgress,
  Typography,
} from '@mui/material/'

export const SsGroupsModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{ onExited: props.clearMessage }}
    >
      <Fade in={props.open}>
        {props.title === '' ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
              <div>
                <Typography className="modalBody" gutterBottom>
                  {props.resultMessage}
                </Typography>
                <br />
                <Button variant="contained" onClick={props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Fade>
    </Dialog>
  )
}
