import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Typography, CardContent } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { getPoLogs } from '../../../store/Procurement/actionCreator'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'

export class PoLogReportTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hardwareInventoryList: [],
      loading: true,
    }
    this.baseState = { ...this.state }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    await this.props.getPoLogs()
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'PO Log Report' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hardwareInventoryList.length) {
      this.setState({
        hardwareInventoryList: JSON.parse(
          JSON.stringify(nextProps.hardwareInventoryList),
        ),
        loading: false,
      })
    }
  }

  render() {
    let inventoryList = []
    if (
      this.state.hardwareInventoryList.length > 0 &&
      this.state.hardwareInventoryList !== 'No PO Log Data Found!'
    ) {
      const columns = [
        { label: 'Year', name: 'year', options: { display: false } },
        { label: 'PO #', name: 'po_num' },
        { label: 'TTS Execution Team', name: 'tts_execution_team' },
        { label: 'Billing R&D PIN', name: 'randDPin' },
        { label: 'Qty', name: 'quantity' },
        { label: 'Universal Part #', name: 'universal_part_num' },
        {
          label: 'Supplier',
          name: 'hardware_vendor',
          options: { display: false },
        },
        {
          label: 'Part Description',
          name: 'part_description',
          options: { display: false },
        },
        { label: 'Ship To', name: 'ship_to' },
        { label: 'Inventory R&D PIN', name: 'inventory_r_and_d_pin' },
        { label: 'Inventory Warehouse', name: 'inventory_warehouse' },
        { label: 'Estimated Delivery Date', name: 'requested_delivery_date' },
        {
          label: 'Qty Received',
          name: 'quantity_received',
          options: { display: false },
        },
        { label: 'Qty Outstanding', name: 'quantity_outstanding' },
        { label: 'Hardware Received Date', name: 'hardware_received_date' },
      ]

      inventoryList = (
        <MUIDataTable
          title="PO Log Report"
          columns={columns}
          data={this.state.hardwareInventoryList}
          options={{
            responsive: 'vertical',
            rowsPerPageOptions: [10, 50, 100],
            expandableRows: false,
            selectableRows: 'none',
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      inventoryList = this.state.hardwareInventoryList
    }

    let inventoryPanel
    if (
      this.state.hardwareInventoryList.length > 0 &&
      !this.state.hardwareInventoryList.includes('No PO Log data found')
    ) {
      inventoryPanel = <div>{inventoryList}</div>
    } else if (
      this.state.hardwareInventoryList.includes('No PO Log data found')
    ) {
      inventoryPanel = (
        <div>
          <Typography variant="h4">
            {this.state.hardwareInventoryList}
          </Typography>
        </div>
      )
    } else {
      inventoryPanel = ''
    }

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                {this.state.hardwareInventoryList.length > 0 ? (
                  typeof this.state.hardwareInventoryList[0] === 'string' ? (
                    <Typography variant="h4">
                      {this.state.hardwareInventoryList}
                    </Typography>
                  ) : (
                    inventoryPanel
                  )
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hardwareInventoryList: state.procurement.hardwareInventoryList,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  getPoLogs,
}

export default connect(mapStateToProps, mapDispatchToProps)(PoLogReportTab)
