import React from 'react'
import Add from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Select from 'react-select'
import { connect } from 'react-redux'
import LayoutErrorModal from './LayoutErrorModal'
import {
  TextField,
  Button,
  Grid,
  Typography,
  CardActions,
  CardContent,
  InputLabel,
  IconButton,
} from '@mui/material'
import {
  downloadSwitchLayout,
  getLayoutCutsheets,
  openErrorDialog,
  resetCablingForm,
} from '../../store/Cabling/actionCreator'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../config/apiConfig'

export class CablingLayoutsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      smartsheetId: '',
      swtCount: [''],
      result: [],
      fileData: '',
      fileName: '',
      layoutCutsheets: [],
      layoutCutsheetId: '',
      layoutCutsheetName: '',
      layoutErrorMessage: '',
      layoutErrorOpen: false,
    }
    this.baseState = this.state
    this.fileDownload = React.createRef()
    this.addIdfStack = this.addIdfStack.bind(this)
    this.addIdfSwitch = this.addIdfSwitch.bind(this)
    this.handleErrorModalClose = this.handleErrorModalClose.bind(this)
    this.removeIdfStack = this.removeIdfStack.bind(this)
    this.formatData = this.formatData.bind(this)
    this.getSwitchLayout = this.getSwitchLayout.bind(this)
  }

  async componentDidMount() {
    await this.props.getLayoutCutsheets()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Cable Layouts Tab' },
      user: { id: userInfo.email },
    })
  }
  static defaultProps = {
    user: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.result !== nextProps.result) {
      this.setState({
        layoutCutsheets: nextProps.layoutCutsheets,
        result: nextProps.result,
      })
    } else {
      this.setState({
        layoutCutsheetId: nextProps.layoutCutsheetId,
        layoutCutsheets: nextProps.layoutCutsheets,
        layoutErrorMessage: nextProps.layoutErrorMessage,
        layoutErrorOpen: nextProps.layoutErrorOpen,
        swtCount: nextProps.swtCount,
      })
    }
    if (this.props.fileName !== nextProps.fileName) {
      const url = window.URL.createObjectURL(nextProps.fileData)
      this.setState(
        {
          fileData: url,
          fileName: nextProps.fileName,
        },
        function () {
          this.fileDownload.current.click()
        },
      )
    }
    if (this.props.layoutErrorMessage !== nextProps.layoutErrorMessage) {
      this.setState({
        layoutErrorMessage: nextProps.layoutErrorMessage,
        layoutErrorOpen: nextProps.layoutErrorOpen,
      })
    }
  }

  handleErrorModalClose() {
    this.props.resetCablingForm()
  }

  formatData() {
    var swtCount = []
    // eslint-disable-next-line
    this.state.swtCount.map((idf) => {
      swtCount.push(parseInt(idf))
    })
    var result = {}
    result.smartsheetId = this.state.smartsheetId
    result.swtCount = swtCount
    return result
  }

  addIdfStack() {
    var swtCount = [...this.state.swtCount]
    if (swtCount.length < 7) {
      swtCount.push('')
      this.setState({ swtCount: swtCount })
    }
  }

  addIdfSwitch(event, index) {
    var swtCount = [...this.state.swtCount]
    swtCount[index] = parseInt(event.target.value)
    this.setState({ swtCount: swtCount })
  }

  removeIdfStack() {
    var swtCount = [...this.state.swtCount]
    swtCount.pop()
    this.setState({ swtCount: swtCount })
  }

  getSwitchLayout(event) {
    event.preventDefault()
    const { user } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Cable Layouts - Create' },
      user: { id: user.email },
    })
    this.props.downloadSwitchLayout(
      this.state.layoutCutsheetId.value,
      this.state.layoutCutsheetName,
      this.state.swtCount,
    )
  }

  render() {
    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Select cutsheet and enter switch counts to create Switch and Patch
              Panel Layouts.
            </Typography>
            <br />
            <br />
          </Grid>
        </CardActions>
        <form onSubmit={this.getSwitchLayout}>
          <Select
            value={this.state.layoutCutsheetId}
            options={
              this.state.layoutCutsheets.length > 0
                ? this.state.layoutCutsheets.map((layoutCutsheet) => ({
                    label: layoutCutsheet.name,
                    value: layoutCutsheet.id,
                  }))
                : ''
            }
            placeholder="Cutsheet"
            onChange={(event) => {
              this.setState({
                layoutCutsheetId: event,
                layoutCutsheetName: event.label,
              })
            }}
            inputProps={{
              name: 'layoutCutsheetId',
              id: 'layoutCutsheetId',
            }}
            style={{ width: '70%', margin: '2%' }}
          />
          <br />
          {this.state.swtCount.map((idf, index) => {
            const a = 97
            let charIndex = String.fromCharCode(a + index)
            return (
              <div
                key={charIndex}
                style={{
                  width: '51%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InputLabel>
                  {'SWT' + charIndex.toUpperCase() + ' Count'}
                </InputLabel>
                <TextField
                  key={charIndex.toUpperCase()}
                  type="number"
                  variant="standard"
                  InputProps={
                    charIndex === 'a'
                      ? { inputProps: { min: 2, max: 6 } }
                      : { inputProps: { min: 2, max: 3 } }
                  }
                  value={idf}
                  onChange={(event) => this.addIdfSwitch(event, index)}
                  style={{
                    width: '20%',
                    marginLeft: '5%',
                    marginBottom: '2%',
                  }}
                  required
                />
              </div>
            )
          })}
          <div>
            {this.state.swtCount.length < 7 ? (
              <IconButton
                onClick={this.addIdfStack}
                title={'Add an IDF Stack'}
                size="large"
              >
                <Add />
              </IconButton>
            ) : (
              ''
            )}
            {this.state.swtCount.length > 1 ? (
              <IconButton onClick={this.removeIdfStack} size="large">
                <RemoveIcon />
              </IconButton>
            ) : (
              ''
            )}
          </div>
          <CardActions
            style={{
              width: '90%',
              height: '30%',
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginTop: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.layoutCutsheetId}
                type="submit"
              >
                Create
              </Button>
            </Grid>
          </CardActions>
        </form>
        <LayoutErrorModal
          open={this.state.layoutErrorOpen}
          handleErrorModalClose={this.handleErrorModalClose}
          message={this.state.layoutErrorMessage}
        />
        <a
          href={this.state.fileData}
          download={this.state.fileName}
          ref={this.fileDownload}
          style={{ display: 'none' }}
        >
          ''
        </a>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    layoutCutsheetId: state.cabling.layoutCutsheetId,
    layoutCutsheets: state.cabling.layoutCutsheets,
    layoutErrorMessage: state.cabling.layoutErrorMessage,
    layoutErrorOpen: state.cabling.layoutErrorOpen,
    fileData: state.cabling.fileData,
    fileName: state.cabling.fileName,
    result: state.cabling.result,
    swtCount: state.cabling.swtCount,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  downloadSwitchLayout,
  getLayoutCutsheets,
  openErrorDialog,
  resetCablingForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(CablingLayoutsTab)
