import * as types from './actionType'

export const initialState = {
  activeSheets: [],
  availableAutomationTypes: [],
  eventsMessage: '',
  portSheetData: [],
  portsMessage: '',
  smartsheetData: [],
  workspaces: [],
  workspaceFolders: [],
}

export default function createSmartsheetsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        eventsMessage: '',
        smartsheetData: [],
      }
      return newState
    }
    case types.CLEAR_PORTS_MESSAGE: {
      const newState = {
        ...state,
        portsMessage: '',
        portSheetData: [],
      }
      return newState
    }
    case types.CREATE_SHEETS: {
      const newState = Array.isArray(action.payload.smartsheetData)
        ? {
            ...state,
            eventsMessage: action.payload.message,
            smartsheetData: action.payload.smartsheetData,
          }
        : {
            ...state,
            eventsMessage: action.payload.message,
            smartsheetData: [action.payload.smartsheetData],
          }
      return newState
    }
    case types.GET_AUTOMATION_TYPES: {
      const newState = Array.isArray(action.payload.availableAutomationTypes)
        ? {
            ...state,
            availableAutomationTypes: action.payload.availableAutomationTypes,
          }
        : {
            ...state,
            availableAutomationTypes: [action.payload.availableAutomationTypes],
          }
      return newState
    }
    case types.GET_PORT_INFO: {
      const newState = Array.isArray(action.payload.portSheetData)
        ? {
            ...state,
            portsMessage: action.payload.message,
            portSheetData: action.payload.portSheetData,
          }
        : {
            ...state,
            portsMessage: action.payload.message,
            portSheetData: [action.payload.portSheetData],
          }
      return newState
    }
    case types.GET_WORKSPACES: {
      const newState = Array.isArray(action.payload.workspaces)
        ? {
            ...state,
            workspaces: action.payload.workspaces,
          }
        : {
            ...state,
            workspaces: [action.payload.workspaces],
          }
      return newState
    }
    case types.GET_WORKSPACE_FOLDERS: {
      const newState = Array.isArray(action.payload.workspaceFolders)
        ? {
            ...state,
            workspaceFolders: action.payload.workspaceFolders,
          }
        : {
            ...state,
            workspaceFolders: [action.payload.workspaceFolders],
          }
      return newState
    }
    case types.GET_ACTIVE_SHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeSheets: action.payload,
          }
        : {
            ...state,
            activeSheets: [action.payload],
          }
      return newState
    }
    default: {
      return state
    }
  }
}
