import merge from 'lodash/merge'
const internalappConfigDev = require('../config/fireflyConfigStage.json')
const internalappConfigProd = require('../config/fireflyConfigProd.json')

const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/authorize',
    externalAuthorizationPath: '/auth/oauth/v2/tgt/authorize/we2w/1',
    logoutPath: '/login/responses/logoff.html',
    popupOptions: { width: 482, height: 680 },
    redirectUri: `${window.location.origin}`,
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
    externalDomain: 'partnersonline.com',
  },
  cutsheetTypes: [
    {
      name: 'Access Points - Cutsheet',
      type: 'AP-Cutsheet',
    },
    {
      name: 'Access Points - Supplemental Sheet (New Stores)',
      type: 'AP-Smartsheet',
    },
    {
      name: 'Phones - Supplemental Sheet',
      type: 'Phone',
    },
    {
      name: 'TVS - Supplemental Sheet',
      type: 'TVS',
    },
  ],
}

const envConfigs = {
  local: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      externalHost: 'https://oauth.iam.perf.partnersonline.com',
      clientId: 'ziptie_npe_im',
      nonce: '1234',
      apiKey: 'b7780bf95267ef03386a4f4b9782388ba5f6cafa',
    },
    api: {
      url: 'http://localhost:8080',
      gatewayUrl: 'https://stage-api.target.com/rollout_deployment_projects/v1',
      rapidUrl: 'https://stage-api.target.com/rollout_hardware_statuses/v1',
    },
    newStoresCutsheetFolder: '7799191922599812',
    scopeSheetWorkspace: '2166354225719172',
  },
  qa: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      externalHost: 'https://oauth.iam.perf.partnersonline.com',
      clientId: 'ziptie_npe_im',
      nonce: '1234',
      apiKey: 'b7780bf95267ef03386a4f4b9782388ba5f6cafa',
    },
    api: {
      url: 'https://stgapi-internal.target.com/rollout_deployment_projects/v1',
      noApiGatewayUrl:
        'https://rolloutdeploymentprojects.dev.target.com/rollout_deployment_projects/v1',
      // url: 'http://localhost:8080',
      gatewayUrl: 'https://stage-api.target.com/rollout_deployment_projects/v1',
      rapidUrl: 'https://stage-api.target.com/rollout_hardware_statuses/v1',
    },
    newStoresCutsheetFolder: '7799191922599812',
    scopeSheetWorkspace: '2166354225719172',
    fireflyConfig: internalappConfigDev,
  },
  stg: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      externalHost: 'https://oauth.iam.perf.partnersonline.com',
      clientId: 'ziptie_npe_im',
      nonce: '1234',
      apiKey: 'b7780bf95267ef03386a4f4b9782388ba5f6cafa',
    },
    api: {
      url: 'https://stage-api.target.com/rollout_deployment_projects/v1',
      noApiGatewayUrl:
        'https://rolloutdeploymentprojects.dev.target.com/rollout_deployment_projects/v1',
      gatewayUrl: 'https://stage-api.target.com/rollout_deployment_projects/v1',
      rapidUrl: 'https://stage-api.target.com/rollout_hardware_statuses/v1',
    },
    newStoresCutsheetFolder: '7799191922599812',
    scopeSheetWorkspace: '2166354225719172',
    fireflyConfig: internalappConfigDev,
  },
  prod: {
    auth: {
      host: 'https://oauth.iam.target.com',
      logoutHost: 'https://logonservices.iam.target.com',
      externalHost: 'https://oauth.iam.partnersonline.com',
      clientId: 'ziptie_prod_im',
      nonce: '1234',
      apiKey: 'f6645326fc8da0e22a80d867130d4bc8504e5e2b',
    },
    api: {
      url: 'https://api.target.com/rollout_deployment_projects/v1',
      noApiGatewayUrl:
        'https://rolloutdeploymentprojects.prod.target.com/rollout_deployment_projects/v1',
      gatewayUrl: 'https://api.target.com/rollout_deployment_projects/v1',
      rapidUrl: 'https://api.target.com/rollout_hardware_statuses/v1',
    },
    newStoresCutsheetFolder: '7799191922599812',
    scopeSheetWorkspace: '6520292496435076',
    fireflyConfig: internalappConfigProd,
  },
}

// env.js sets APP_ENV
const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]
const apiConfig = merge(commonConfig, config)

export default apiConfig
