import React from 'react'
import { connect } from 'react-redux'
import Add from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import Select from 'react-select'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'
import MaximoStatusModal from './MaximoStatusModal'
import MaximoBulkUpload from './MaximoBulkUpload'
import {
  getMaximoOptions,
  createMaximoWorkOrder,
  clearMessage,
  getWorkspaces,
  getMaximoBulkLocations,
  getActiveSheets,
} from '../../../store/AdditionalTools/actionCreator'

export class MaximoWorkOrderTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      workOrderOptions: this.props.workOrderOptions,
      workOrderType: '',
      vendor: '',
      locationType: '',
      scopeOfWork: '',
      workOrderTypeLabel: '',
      vendorLabel: '',
      locationTypeLabel: '',
      location: '',
      projectAccountCode: '',
      contactInfo: '',
      rndPin: '',
      amount: '$700',
      locations: [],
      startDate: '',
      endDate: '',
      buttonEnabled: false,
      eventsMessage: '',
      statusOpen: false,
      loading: true,
      fileData: '',
      fileName: '',
      bulkUploadOpen: false,
      workspaces: [],
      activeSheets: [],
      bulkLocations: [],
      smartsheetId: '',
    }
    this.fileDownload = React.createRef()
    this.createMaximoWorkOrder = this.createMaximoWorkOrder.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.handleBulkUploadClose = this.handleBulkUploadClose.bind(this)
    this.handleBulkUploadOpen = this.handleBulkUploadOpen.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.addLocationRow = this.addLocationRow.bind(this)
    this.removeLocation = this.removeLocation.bind(this)
    this.removeLastLocation = this.removeLastLocation.bind(this)
    this.uploadBulkLocations = this.uploadBulkLocations.bind(this)
  }

  async componentDidMount() {
    await this.props.getMaximoOptions()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Maximo Work Order Tab' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.workOrderOptions !== nextProps.workOrderOptions) {
      this.setState({
        workOrderOptions: JSON.parse(
          JSON.stringify(nextProps.workOrderOptions),
        ),
        loading: false,
      })
    }
    if (
      this.props.fileData !== nextProps.fileData &&
      nextProps.fileData !== ''
    ) {
      const url = window.URL.createObjectURL(nextProps.fileData)
      this.setState(
        {
          fileData: url,
          fileName: nextProps.fileName,
        },
        function () {
          this.fileDownload.current.click()
        },
      )
    }
    if (this.props.eventsMessage !== nextProps.eventsMessage) {
      this.setState({
        eventsMessage: nextProps.eventsMessage,
      })
    }
  }

  addLocationRow() {
    if (
      this.state.location !== '' &&
      this.state.startDate !== '' &&
      this.state.endDate !== '' &&
      this.state.locationType !== '' &&
      this.state.vendor !== ''
    ) {
      var locationsNew = [...this.state.locations]
      var newLocation = {
        location: this.state.location,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        location_type: this.state.locationType,
        vendor: this.state.vendor,
      }
      locationsNew.push(newLocation)
      if (this.state.workOrderType.toLowerCase().includes('elevator')) {
        this.setState({
          locations: locationsNew,
          location: '',
          startDate: '',
          endDate: '',
          locationType: '',
          locationTypeLabel: '',
          vendor: '0000000000 VTRAN – Vendor TBD',
          vendorLabel: {
            value: '0000000000 VTRAN – Vendor TBD',
            label: 'VTRAN – Vendor TBD',
          },
        })
      } else {
        this.setState({
          locations: locationsNew,
          location: '',
          startDate: '',
          endDate: '',
          locationType: '',
          locationTypeLabel: '',
          vendor: '',
          vendorLabel: '',
        })
      }
    }
  }

  async removeLocation(index) {
    var locations = [...this.state.locations]
    locations.splice(index, 1)
    await this.setState({ locations: locations })
    this.validateForm()
  }

  removeLastLocation() {
    if (
      this.state.location !== '' ||
      this.state.startDate !== '' ||
      this.state.endDate !== '' ||
      this.state.locationType !== '' ||
      this.state.locationTypeLabel !== '' ||
      this.state.vendor !== '' ||
      this.state.vendorLabel !== ''
    ) {
      this.setState({
        location: '',
        startDate: '',
        endDate: '',
        locationType: '',
        locationTypeLabel: '',
        vendor: '',
        vendorLabel: '',
      })
    }
    this.validateForm()
  }

  async createMaximoWorkOrder(event) {
    event.preventDefault()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Maximo Work Order - Submit' },
      user: { id: userInfo.email },
    })
    if (this.state.location !== '') {
      await this.addLocationRow()
    }

    const requestData = {
      requestor_name: userInfo.email,
      work_order_type: this.state.workOrderType,
      rnd_pin: this.state.rndPin,
      amount: this.state.amount,
      locations: this.state.locations,
      scope_of_work: this.state.scopeOfWork,
      contact_info: this.state.contactInfo,
      account_code: this.state.projectAccountCode,
      smartsheet_id: this.state.smartsheetId,
    }
    this.props.createMaximoWorkOrder(requestData)
    this.setState({ statusOpen: true })
    this.resetForm()
  }

  async handleBulkUploadOpen() {
    await this.props.getWorkspaces()
    this.setState({ bulkUploadOpen: true, vendor: '', vendorLabel: '' })
  }

  handleBulkUploadClose() {
    this.setState({ bulkUploadOpen: false })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
      eventsMessage: '',
    })
    this.props.clearMessage()
  }

  async uploadBulkLocations(smartsheetId) {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Maximo Bulk Upload - Upload' },
      user: { id: userInfo.email },
    })
    await this.setState(
      {
        locations: this.props.bulkLocations.first,
        smartsheetId: smartsheetId,
        bulkUploadOpen: false,
      },
      this.validateForm,
    )
  }

  resetForm = () => {
    this.setState({
      workOrderType: '',
      vendor: '',
      locations: [],
      locationType: '',
      scopeOfWork: '',
      workOrderTypeLabel: '',
      vendorLabel: '',
      locationTypeLabel: '',
      location: '',
      projectAccountCode: '',
      contactInfo: '',
      rndPin: '',
      amount: '$700',
      startDate: '',
      endDate: '',
      buttonEnabled: false,
      loading: false,
      smartsheetId: '',
    })
  }

  validateForm = () => {
    if (
      this.state.workOrderType !== '' &&
      this.state.rndPin !== '' &&
      this.state.amount !== '' &&
      this.state.scopeOfWork !== '' &&
      this.state.projectAccountCode !== '' &&
      this.state.contactInfo !== '' &&
      ((this.state.locations.length > 0 &&
        ((this.state.location === '' &&
          this.state.startDate === '' &&
          this.state.endDate === '' &&
          this.state.locationType === '' &&
          this.state.vendor === '') ||
          (this.state.location !== '' &&
            this.state.startDate !== '' &&
            this.state.endDate !== '' &&
            this.state.locationType !== '' &&
            this.state.vendor !== ''))) ||
        (this.state.locations.length === 0 &&
          this.state.location !== '' &&
          this.state.startDate !== '' &&
          this.state.endDate !== '' &&
          this.state.locationType !== '' &&
          this.state.vendor !== ''))
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    let date = new Date()
    date.setDate(date.getDate() + 3)
    let datePlus3 = date.toISOString().split('T')[0]
    let modalTitle
    if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage.includes('Maximo Work Order Created!')
    ) {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      !this.state.eventsMessage.includes('Maximo Work Order Created!')
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    let errorExists = false
    let workOrderTypes = []
    let vendorOptions = []
    if (this.state.workOrderOptions !== undefined) {
      if (this.state.workOrderOptions.messageString === '') {
        workOrderTypes = this.state.workOrderOptions.workOrderTypes.map(
          (type) => ({
            value: type,
            label: type,
          }),
        )
        vendorOptions = this.state.workOrderOptions.vendors.map((vendor) => ({
          value: vendor,
          label: vendor.slice(11),
        }))
      } else {
        errorExists = true
      }
    }

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {this.state.loading || errorExists ? (
          <div>
            {errorExists ? (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 5,
                }}
                className="progress"
              >
                <Typography className="loading">
                  {this.state.workOrderOptions.messageString +
                    ' Please try again.'}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                spacing={16}
                justifyContent={'center'}
                className="progress"
              >
                <CircularProgress />
                <Typography className="loading">&nbsp;Loading...</Typography>
              </Grid>
            )}
          </div>
        ) : (
          <div>
            <CardActions style={{ width: '100%' }}>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 0,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography gutterBottom>Maximo Work Order Creation</Typography>
              </Grid>
            </CardActions>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              style={{
                alignItems: 'stretch',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                style={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'stretch',
                  display: 'flex',
                }}
              >
                <Grid container item style={{ paddingLeft: 8 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      flexBasis: '99%',
                    }}
                  >
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        style={{ width: '100%' }}
                      >
                        <TextField
                          htmlFor="rndPin"
                          type="text"
                          variant="standard"
                          value={this.state.rndPin}
                          placeholder="Enter Value"
                          label="R&D PIN"
                          required
                          style={{ width: '60%' }}
                          onChange={(event) =>
                            this.setState(
                              { rndPin: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <TextField
                          htmlFor="scopeOfWork"
                          type="text"
                          value={this.state.scopeOfWork}
                          variant="standard"
                          label="Scope of Work"
                          placeholder="Enter Value"
                          style={{ width: '60%' }}
                          required
                          onChange={(event) =>
                            this.setState(
                              { scopeOfWork: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <TextField
                          htmlFor="projectAccountCode"
                          type="text"
                          variant="standard"
                          value={this.state.projectAccountCode}
                          placeholder="Enter Value"
                          label="Project Account Code"
                          required
                          style={{ width: '60%' }}
                          onChange={(event) =>
                            this.setState(
                              { projectAccountCode: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <TextField
                          htmlFor="contactInfo"
                          type="text"
                          variant="standard"
                          value={this.state.contactInfo}
                          placeholder="Enter Value"
                          label="Preferred contact method"
                          helperText="provide your full email address or slack channel name"
                          required
                          style={{ width: '60%' }}
                          onChange={(event) =>
                            this.setState(
                              { contactInfo: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <TextField
                          htmlFor="amount"
                          type="text"
                          variant="standard"
                          value={this.state.amount}
                          label="Forecasted Amount per Location (Default is $700 but it can be updated)"
                          required
                          style={{ width: '60%' }}
                          onChange={(event) =>
                            this.setState(
                              { amount: event.target.value },
                              function () {
                                this.validateForm()
                              },
                            )
                          }
                          InputProps={{
                            className: 'redUnderline',
                          }}
                        />
                        <br />
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: 0,
                            width: '60%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography gutterBottom>Work Order Type</Typography>
                        </Grid>
                        <Select
                          value={this.state.workOrderTypeLabel}
                          options={workOrderTypes}
                          menuPosition="fixed"
                          onChange={(event) => {
                            if (
                              event.value.toLowerCase().includes('elevator')
                            ) {
                              this.setState(
                                {
                                  workOrderType: event.value,
                                  workOrderTypeLabel: event,
                                  vendor: '0000000000 VTRAN – Vendor TBD',
                                  vendorLabel: {
                                    value: '0000000000 VTRAN – Vendor TBD',
                                    label: 'VTRAN – Vendor TBD',
                                  },
                                },
                                function () {
                                  this.validateForm()
                                },
                              )
                            } else {
                              this.setState(
                                {
                                  workOrderType: event.value,
                                  workOrderTypeLabel: event,
                                },
                                function () {
                                  this.validateForm()
                                },
                              )
                            }
                          }}
                        />
                        <br />
                        <div>
                          Location Information &nbsp;&nbsp;&nbsp;&nbsp;
                          <Button
                            variant="contained"
                            onClick={this.handleBulkUploadOpen}
                          >
                            Bulk Upload from Smartsheet
                          </Button>
                          <Box
                            sx={{
                              border: 1,
                              borderColor: 'grey.300',
                              borderRadius: 2,
                              display: 'grid',
                            }}
                            style={{ marginTop: '1%' }}
                          >
                            {this.state.locations.length > 0
                              ? this.state.locations.map(
                                  (locationInfo, index) => {
                                    return (
                                      <div>
                                        <Box
                                          sx={{
                                            border: '1px solid',
                                            borderColor: 'grey.300',
                                            m: 1,
                                            borderRadius: 2,
                                          }}
                                        >
                                          <Grid
                                            container
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              height: '4%',
                                              width: '100%',
                                            }}
                                          >
                                            <Grid
                                              item
                                              lg={1}
                                              md={12}
                                              style={{
                                                marginBottom: '1%',
                                                marginLeft: '1%',
                                              }}
                                            >
                                              <FormControl
                                                style={{ width: '95%' }}
                                              >
                                                <br />
                                                <TextField
                                                  htmlFor="location"
                                                  type="text"
                                                  variant="standard"
                                                  value={locationInfo.location}
                                                  label="Location"
                                                />
                                              </FormControl>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={2}
                                              md={12}
                                              style={{
                                                marginBottom: '1%',
                                                marginLeft: '1%',
                                              }}
                                            >
                                              <FormControl
                                                style={{ width: '95%' }}
                                              >
                                                <br />
                                                <TextField
                                                  htmlFor="type"
                                                  type="text"
                                                  variant="standard"
                                                  value={
                                                    locationInfo.location_type
                                                  }
                                                  label="Location Type"
                                                />
                                              </FormControl>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              md={12}
                                              style={{
                                                marginBottom: '1%',
                                                marginLeft: '1%',
                                              }}
                                            >
                                              <FormControl
                                                style={{ width: '95%' }}
                                              >
                                                <br />
                                                <TextField
                                                  htmlFor="vendor"
                                                  type="text"
                                                  variant="standard"
                                                  value={locationInfo.vendor.slice(
                                                    11,
                                                  )}
                                                  label="Vendor"
                                                />
                                              </FormControl>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={2}
                                              md={12}
                                              style={{
                                                marginBottom: '1%',
                                              }}
                                            >
                                              <FormControl
                                                style={{ width: '95%' }}
                                              >
                                                <br />
                                                <TextField
                                                  htmlFor="startDate"
                                                  type="text"
                                                  variant="standard"
                                                  value={
                                                    locationInfo.start_date
                                                  }
                                                  label="Start Date"
                                                />
                                              </FormControl>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={2}
                                              md={12}
                                              style={{
                                                marginBottom: '1%',
                                              }}
                                            >
                                              <FormControl
                                                style={{ width: '95%' }}
                                              >
                                                <br />
                                                <TextField
                                                  htmlFor="endDate"
                                                  type="text"
                                                  variant="standard"
                                                  value={locationInfo.end_date}
                                                  label="End Date"
                                                />
                                              </FormControl>
                                            </Grid>
                                            <IconButton
                                              onClick={(event) =>
                                                this.removeLocation(index)
                                              }
                                              title={'Remove Location'}
                                              size="large"
                                            >
                                              <RemoveIcon />
                                            </IconButton>
                                          </Grid>
                                        </Box>
                                      </div>
                                    )
                                  },
                                )
                              : ''}
                            <div>
                              <Box
                                sx={{
                                  border: '1px solid',
                                  borderColor: 'grey.300',
                                  m: 1,
                                  borderRadius: 2,
                                }}
                              >
                                <Grid
                                  container
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '4%',
                                    width: '100%',
                                  }}
                                >
                                  <Grid
                                    item
                                    lg={1}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl sx={{ width: '95%' }}>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          Location
                                        </Typography>
                                      </Grid>
                                      <TextField
                                        htmlFor="location"
                                        type="text"
                                        variant="standard"
                                        value={this.state.location}
                                        required
                                        onChange={(event) =>
                                          this.setState(
                                            { location: event.target.value },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }
                                        InputProps={{
                                          className: 'redUnderline',
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '95%' }}>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          Location Type
                                        </Typography>
                                      </Grid>
                                      <Select
                                        value={this.state.locationTypeLabel}
                                        options={[
                                          {
                                            value: 'Store',
                                            label: 'Store',
                                          },
                                          {
                                            value: 'OSBR',
                                            label: 'OSBR',
                                          },
                                        ]}
                                        menuPosition="fixed"
                                        required
                                        onChange={(event) => {
                                          this.setState(
                                            {
                                              locationType: event.value,
                                              locationTypeLabel: event,
                                            },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={4}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                      marginLeft: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '95%' }}>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          Vendor
                                        </Typography>
                                      </Grid>
                                      <Select
                                        value={this.state.vendorLabel}
                                        options={vendorOptions}
                                        menuPosition="fixed"
                                        required
                                        onChange={(event) => {
                                          this.setState(
                                            {
                                              vendor: event.value,
                                              vendorLabel: event,
                                            },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '90%' }}>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          Start Date
                                        </Typography>
                                      </Grid>
                                      <TextField
                                        type="date"
                                        variant="standard"
                                        value={this.state.startDate}
                                        inputProps={{
                                          min: datePlus3,
                                        }}
                                        required
                                        helperText="Must be 3+ days from today"
                                        onChange={(event) =>
                                          this.setState(
                                            { startDate: event.target.value },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2}
                                    md={12}
                                    style={{
                                      marginBottom: '1%',
                                    }}
                                  >
                                    <FormControl style={{ width: '90%' }}>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          marginTop: 0,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Typography gutterBottom>
                                          End Date
                                        </Typography>
                                      </Grid>
                                      <TextField
                                        type="date"
                                        variant="standard"
                                        value={this.state.endDate}
                                        inputProps={{
                                          min: this.state.startDate,
                                        }}
                                        required
                                        helperText="Must be after start date"
                                        onChange={(event) =>
                                          this.setState(
                                            { endDate: event.target.value },
                                            function () {
                                              this.validateForm()
                                            },
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <IconButton
                                    onClick={this.removeLastLocation}
                                    title={'Remove Location'}
                                    size="large"
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Grid>
                              </Box>
                            </div>
                            <div>
                              <IconButton
                                onClick={this.addLocationRow}
                                title={'Add another Location'}
                                size="large"
                              >
                                <Add />
                              </IconButton>
                            </div>
                          </Box>
                        </div>
                        <br />
                        <CardActions
                          style={{
                            width: '100%',
                            height: '30%',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: 0,
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              disabled={!this.state.buttonEnabled}
                              onClick={this.createMaximoWorkOrder}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </CardActions>
                        <br />
                      </FormControl>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
        <MaximoBulkUpload
          open={this.state.bulkUploadOpen}
          handleBulkUploadClose={this.handleBulkUploadClose}
          workspaces={this.props.workspaces}
          activeSheets={this.props.activeSheets}
          getActiveSheets={this.props.getActiveSheets}
          uploadBulkLocations={this.uploadBulkLocations}
          bulkLocations={this.props.bulkLocations}
          getMaximoBulkLocations={this.props.getMaximoBulkLocations}
        />
        <MaximoStatusModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventsMessage={this.state.eventsMessage}
          title={modalTitle}
        />
        <a
          href={this.state.fileData}
          download={this.state.fileName}
          ref={this.fileDownload}
          style={{ display: 'none' }}
        >
          ''
        </a>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    workOrderOptions: state.additionalTools.workOrderOptions,
    eventsMessage: state.additionalTools.eventsMessage,
    fileData: state.additionalTools.fileData,
    fileName: state.additionalTools.fileName,
    userInfo: state.auth.userInfo,
    workspaces: state.additionalTools.workspaces,
    activeSheets: state.additionalTools.activeSheets,
    bulkLocations: state.additionalTools.bulkLocations,
  }
}

const mapDispatchToProps = {
  createMaximoWorkOrder,
  getMaximoOptions,
  clearMessage,
  getWorkspaces,
  getMaximoBulkLocations,
  getActiveSheets,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaximoWorkOrderTab)
