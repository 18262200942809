import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material/'

export default class MaximoStatusModal extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.eventsMessage === undefined ||
        this.props.eventsMessage === '' ? (
          <div>
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <div>
                <div style={{ paddingBottom: '10px' }}>
                  {this.props.eventsMessage}
                </div>
                <Button variant="contained" onClick={this.props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
