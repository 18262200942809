export default function compare(a, b) {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()
  let comparison = 0
  if (nameA > nameB) {
    comparison = 1
  } else if (nameB > nameA) {
    comparison = -1
  }
  return comparison
}
