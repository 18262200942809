import React from 'react'
import SuccessModal from './SuccessModal'
import {
  TextField,
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
  FormControl,
} from '@mui/material'
import Select from 'react-select'
import { connect } from 'react-redux'
import {
  createCablingTypes,
  getActiveSheets,
  getWorkspaces,
  clearMessage,
} from '../../store/Cabling/actionCreator'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../config/apiConfig'

export class CablingTypesTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statusOpen: false,
      buttonEnabled: false,
      activeSheets: [],
      workspaces: [],
      workspace: '',
      eventMessage: '',
      smartsheetId: '',
      executionPeriod: '',
      year: '',
    }
    this.dialogBox = React.createRef()
    this.baseState = this.state
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.handleOnCreate = this.handleOnCreate.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    await this.props.getWorkspaces()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Cabling Type Tab' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    user: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.workspaces !== nextProps.workspaces) {
      this.setState({
        workspaces: JSON.parse(JSON.stringify(nextProps.workspaces)),
      })
    }
    if (this.props.eventMessage !== nextProps.eventMessage) {
      this.setState({
        eventMessage: nextProps.eventMessage,
      })
    } else {
      this.setState({
        activeSheets: nextProps.activeSheets,
      })
    }
  }

  validateForm = () => {
    if (
      this.state.workspace !== '' &&
      this.state.smartsheetId !== '' &&
      this.state.executionPeriod !== '' &&
      this.state.year !== ''
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  async handleOnCreate(event) {
    event.preventDefault()
    this.setState({ statusOpen: true })
    const { user } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Cabling Type - Create' },
      user: { id: user.email },
    })
    const cablingTypeData = {
      smartsheetId: this.state.smartsheetId.value,
      executionPeriod: this.state.executionPeriod,
      year: this.state.year,
    }
    await this.props.createCablingTypes(cablingTypeData)
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
      executionPeriod: '',
      year: '',
      smartsheetId: '',
      workspace: [],
      buttonEnabled: false,
    })
    this.props.clearMessage()
  }

  render() {
    let modalTitle
    if (
      this.state.eventMessage === 'Cabling Scope Sheet updated successfully.'
    ) {
      modalTitle = 'Success'
    } else if (
      this.state.eventMessage !== 'Cabling Scope Sheet updated successfully.' &&
      this.state.eventMessage !== undefined &&
      this.state.eventMessage.length > 1
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <CardContent
        sx={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions sx={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 0,
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Select input Smartsheet, specify Execution Period and year to add
              cable types to the Smartsheet.
            </Typography>
          </Grid>
        </CardActions>
        <form onSubmit={this.handleOnCreate} style={{ width: '100%' }}>
          <Grid
            sx={{
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid
              container
              sx={{
                paddingLeft: 1,
                alignItems: 'stretch',
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexBasis: '80%',
                }}
              >
                <Grid item xs={14} md={10}>
                  <FormControl
                    component="fieldset"
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '90%',
                    }}
                  >
                    <Select
                      value={this.state.workspace}
                      options={
                        this.state.workspaces.length > 0
                          ? this.state.workspaces.map((workspace) => ({
                              label: workspace.name,
                              value: workspace.id,
                            }))
                          : ''
                      }
                      placeholder="Workspace"
                      onChange={(event) => {
                        this.setState(
                          {
                            workspace: event,
                          },
                          function () {
                            this.validateForm()
                            this.props.getActiveSheets(
                              this.state.workspace.value,
                            )
                          },
                        )
                      }}
                      inputProps={{
                        name: 'workspace',
                        id: 'workspace',
                      }}
                    />
                  </FormControl>
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <FormControl
                    component="fieldset"
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '90%',
                    }}
                  >
                    <Select
                      value={this.state.smartsheetId}
                      options={
                        this.state.activeSheets.length > 0
                          ? this.state.activeSheets.map((activeSheet) => ({
                              label: activeSheet.name,
                              value: activeSheet.id,
                            }))
                          : ''
                      }
                      placeholder="Smartsheet"
                      onChange={(event) => {
                        this.setState(
                          {
                            smartsheetId: event,
                          },
                          function () {
                            this.validateForm()
                          },
                        )
                      }}
                      inputProps={{
                        name: 'smartsheetId',
                        id: 'smartsheetId',
                      }}
                    />
                  </FormControl>
                </Grid>
                <br />
                <Grid item xs={14} md={10}>
                  <TextField
                    htmlFor="executionPeriod"
                    type="text"
                    variant="standard"
                    value={this.state.executionPeriod}
                    placeholder="Ex: Cycle 1, Pilot, 1"
                    label="Execution Period"
                    required
                    onChange={(event) =>
                      this.setState(
                        { executionPeriod: event.target.value },
                        function () {
                          this.validateForm()
                        },
                      )
                    }
                    inputProps={{
                      className: 'formInput',
                      name: 'executionPeriod',
                      id: 'executionPeriod',
                    }}
                    InputProps={{
                      className: 'redUnderline',
                    }}
                  />
                </Grid>
                <br />
                <br />
                <Grid item xs={14} md={10}>
                  <TextField
                    htmlFor="year"
                    type="text"
                    variant="standard"
                    value={this.state.year}
                    label="Year"
                    required
                    onChange={(event) =>
                      this.setState({ year: event.target.value }, function () {
                        this.validateForm()
                      })
                    }
                    inputProps={{
                      className: 'formInput',
                      name: 'year',
                      id: 'year',
                    }}
                    InputProps={{
                      className: 'redUnderline',
                    }}
                  />
                </Grid>
                <br />
                <br />
              </div>
              <CardActions
                style={{
                  width: '90%',
                  height: '20%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.buttonEnabled}
                    type="submit"
                  >
                    Create
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <SuccessModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventMessage={this.state.eventMessage}
          title={modalTitle}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    eventMessage: state.cabling.eventMessage,
    activeSheets: state.cabling.activeSheets,
    workspaces: state.cabling.workspaces,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  createCablingTypes,
  getActiveSheets,
  getWorkspaces,
  clearMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(CablingTypesTab)
