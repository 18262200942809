// THIS FILE WAS PREVIOUSLY PART OF THE DEPRECATED REACT-PRAXIS-COMPONENTS LIBRARY.
// THE RECOMMENDED FIX FROM THE PRAXIS TEAM IS TO COPY & PASTE NEEDED COMPONENTS
// INTO YOUR PROJECT

import { string, element } from 'prop-types'
import ListItemIcon from '@mui/material/ListItemIcon'

export const Icon = (props) => {
  const { children, classes, className, ...additionalProps } = props
  return (
    <ListItemIcon classes={classes} className={className} {...additionalProps}>
      {children}
    </ListItemIcon>
  )
}

Icon.propTypes = {
  children: element,
  className: string,
}
Icon.displayName = 'Icon'

export default Icon
