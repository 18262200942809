import * as types from './actionType'

export const initialState = {
  printerNameList: [],
  eventsMessage: '',
}

export default function printReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        eventsMessage: '',
        printerNameList: [],
      }
      return newState
    }
    case types.CREATE_PRINT_JOB: {
      const newState = {
        ...state,
        eventsMessage: action.payload.message,
      }
      return newState
    }
    case types.GET_PRINTER_NAME_LIST: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            printerNameList: action.payload,
          }
        : {
            ...state,
            printerNameList: [action.payload],
          }
      return newState
    }
    default: {
      return state
    }
  }
}
