import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

class CustomToolbar extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Tooltip title={'Create Webhook'}>
          <IconButton
            className="customToolbarIcon"
            onClick={this.props.openDialog}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Help'}>
          <IconButton
            className="customToolbarIcon"
            onClick={this.props.openHelpDialog}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }
}

export default CustomToolbar
