import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../config/apiConfig'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import CreatePlatformDataSheetHelpDialog from './CreatePlatformDataSheetHelpDialog'
import CreatePlatformDataSheetSuccessModal from './CreatePlatformDataSheetSuccessModal'
import {
  clearMessage,
  createPlatformDataSheet,
} from '../../store/CreateSmartsheets/actionCreator'

export class GeneratePlatformDataSheetsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonEnabled: false,
      dataSheetName: '',
      eventsMessage: '',
      helpOpen: false,
      smartsheetData: [],
      statusOpen: false,
    }
    initializeFireflySDK()
    this.createPlatformDataSheet = this.createPlatformDataSheet.bind(this)
    this.handleHelpClose = this.handleHelpClose.bind(this)
    this.handleHelpOpen = this.handleHelpOpen.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    await this.props.clearMessage()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Platform Data Sheet Tab' },
      user: { id: userInfo.email },
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.smartsheetData !== nextProps.smartsheetData ||
      this.props.eventsMessage !== nextProps.eventsMessage
    ) {
      this.setState({
        smartsheetData: nextProps.smartsheetData,
        eventsMessage: nextProps.eventsMessage,
      })
    }
  }

  createPlatformDataSheet = (event, user) => {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Platform Data Sheet - Submit' },
      user: { id: user.email },
    })
    event.preventDefault()
    this.props.clearMessage()
    this.props.createPlatformDataSheet(this.state.dataSheetName)

    this.setState({ statusOpen: true })
    this.resetForm()
  }

  handleHelpClose() {
    this.setState({ helpOpen: false })
  }

  handleHelpOpen() {
    this.setState({ helpOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  resetForm = () => {
    this.setState({
      dataSheetName: '',
      buttonEnabled: false,
    })
  }

  validateForm = () => {
    if (this.state.dataSheetName !== '') {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    let modalTitle
    if (this.state.eventsMessage === 'Smartsheet created!') {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage !== 'Smartsheet created!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Enter the name for the Platform Data Sheet you wish to create.
            </Typography>
            <Tooltip title={'Help'}>
              <IconButton onClick={this.handleHelpOpen} size="large">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </CardActions>
        <form
          onSubmit={(event) =>
            this.createPlatformDataSheet(event, this.props.user)
          }
          style={{
            alignItems: 'stretch',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid container item style={{ paddingLeft: 8 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexBasis: '90%',
                }}
              >
                <Grid item xs={14} md={10}>
                  <TextField
                    htmlFor="dataSheetName"
                    type="text"
                    variant="standard"
                    value={this.state.dataSheetName}
                    placeholder="AP Chromebox"
                    label="Platform Data Sheet Name"
                    required
                    style={{ width: '100%' }}
                    onChange={(event) =>
                      this.setState(
                        { dataSheetName: event.target.value },
                        function () {
                          this.validateForm()
                        },
                      )
                    }
                    InputProps={{
                      className: 'redUnderline',
                    }}
                  />
                </Grid>
                <br />
              </div>
              <CardActions
                style={{
                  width: '100%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.buttonEnabled}
                  >
                    Submit
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <CreatePlatformDataSheetHelpDialog
          open={this.state.helpOpen}
          handleHelpClose={this.handleHelpClose}
        />
        <CreatePlatformDataSheetSuccessModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventsMessage={this.state.eventsMessage}
          smartsheetData={this.state.smartsheetData}
          title={modalTitle}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    eventsMessage: state.createSmartsheets.eventsMessage,
    smartsheetData: state.createSmartsheets.smartsheetData,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  clearMessage,
  createPlatformDataSheet,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneratePlatformDataSheetsTab)
