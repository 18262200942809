import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from '@praxis/component-auth'
import Layout from './Layout/Layout'
import ziptieTheme from '../config/themeConfig'
import { ThemeProvider } from '@mui/material/styles'
import apiConfig from '../config/apiConfig'
import { getDomainName } from './Utils/GetDomainName'

class App extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)
  }

  render() {
    const isExternalDomain =
      getDomainName() !== apiConfig.auth.externalDomain ? false : true
    return (
      <AuthProvider
        authorizationUrl={
          !isExternalDomain
            ? apiConfig.auth.host + apiConfig.auth.authorizationPath
            : apiConfig.auth.externalHost +
              apiConfig.auth.externalAuthorizationPath
        }
        clientId={apiConfig.auth.clientId}
        loginRedirect={
          isExternalDomain
            ? apiConfig.auth.redirectUri
            : `${apiConfig.auth.redirectUri}/`
        }
        logoutRedirect={
          isExternalDomain
            ? apiConfig.auth.redirectUri
            : `${apiConfig.auth.redirectUri}/`
        }
      >
        <ThemeProvider theme={ziptieTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Layout />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    )
  }
}

export default App
