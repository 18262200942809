import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function getKitList(kitData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    var result = []
    var responseMessage = []
    var responseData = []
    var message = ''
    await axios
      .get(
        `${apiConfig.api.url}/generate_kits?smartsheet_id=${kitData.smartsheetId}&execution_period=${kitData.executionPeriod}&year=${kitData.year}`,
      )
      .then((resp) => {
        responseMessage = resp.data.map((e) => e.second)
        responseData = resp.data.map((e) => e.first)
        if (responseMessage.map((e) => e !== '').indexOf(true) > -1) {
          message = responseMessage.join('')
        } else if (responseData.map((e) => e.id === null).indexOf(true) > -1) {
          message = 'No Kit List created due to an unknown error.'
        } else {
          message = 'Success'
        }
        result = {
          eventMessage: message,
          kitListSmartsheetData: responseData,
        }
        dispatch({
          type: types.GET_KIT_LIST,
          payload: result,
        })
      })
      .catch(() => {
        dispatch({
          type: types.GET_KIT_LIST,
          payload: {
            eventMessage:
              'Encountered an unknown error, please contact the RDSE team.',
          },
        })
      })
  }
}

export function getActiveSheets(workspaceId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${workspaceId}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_ACTIVE_SHEETS,
          payload: resp.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets.'
          dispatch({
            type: types.GET_ACTIVE_SHEETS,
            payload: result,
          })
        }
      })
  }
}

export function getExecutionPeriods(smartsheetId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/column_data_values?smartsheet_id=${smartsheetId}&column_name=Execution Period`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_EXECUTION_PERIODS,
          payload: resp.data.sort((a, b) => (a > b ? 1 : -1)),
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error getting execution period.'
          dispatch({
            type: types.GET_EXECUTION_PERIODS,
            payload: result,
          })
        }
      })
  }
}

export function getWorkspaces() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/workspaces`)
      .then((resp) => {
        const workspaces = resp.data
        dispatch({
          type: types.GET_WORKSPACES,
          payload: workspaces,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_WORKSPACES,
            payload: result,
          })
        }
      })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}
