import {
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import GetAppIcon from '@mui/icons-material/GetApp'
import Tooltip from '@mui/material/Tooltip'

export const SsGroupList = (props) => {
  return (
    <Grid key={props.group.name} item xs={12} md={6} lg={4}>
      <List
        style={{
          outlineStyle: 'solid',
          outlineColor: 'black',
          outlineWidth: '1px',
          overflow: 'auto',
          maxHeight: 500,
          margin: '4px',
        }}
        subheader={
          <ListSubheader
            style={{
              width: '100%',
              height: '60px',
              padding: '20px',
              color: '#fff',
              backgroundColor: '#8c1f27',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '1.75',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={11}>
                {props.group.name + ' (Owner: ' + props.group.owner + ')'}
              </Grid>
              <Grid item xs={12} md={1}>
                <Tooltip title={'Download'}>
                  <IconButton
                    style={{ color: '#fff' }}
                    onClick={(event) => {
                      props.onClick(event, props.group)
                    }}
                    size="large"
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </ListSubheader>
        }
      >
        {props.group.members.map((member) => {
          let memberName = member.name === null ? member.email : member.name
          return (
            <div key={member.email}>
              <ListItem>
                <ListItemText primary={memberName} secondary={member.email} />
              </ListItem>
              <Divider variant="middle" component="li" />
            </div>
          )
        })}
      </List>
    </Grid>
  )
}
