import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material/'

export default class PrintSuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventsMessage: '',
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.eventsMessage === '' ||
        this.props.eventsMessage === undefined ? (
          <div>
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <div>
                <Typography variant="body1" gutterBottom>
                  {this.props.title === 'Success'
                    ? 'Successfully Printed'
                    : this.props.eventsMessage}
                </Typography>
                <Button variant="contained" onClick={this.props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
