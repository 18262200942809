import * as types from './actionType'

export const initialState = {
  result: '',
  pinsList: [],
}

export default function pinGenerationReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case types.POST_PINS: {
      const newState = {
        ...state,
        pinsMessage: action.payload,
      }
      return newState
    }
    case types.GET_PINS_AND_HOOKS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            pinsList: action.payload,
            pinsMessage: '',
            newWebhookData: {},
          }
        : {
            ...state,
            pinsList: [action.payload],
            pinsMessage: '',
            newWebhookData: {},
          }
      return newState
    }
    case types.RETURN_MESSAGE: {
      const newState = {
        ...state,
        revokeMessage: action.payload,
      }
      return newState
    }
    default: {
      return state
    }
  }
}
