import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material/'

export default class SuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventMessage: '',
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.eventMessage === '' ||
        this.props.eventMessage === undefined ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                {this.props.eventMessage}
              </Typography>
              <Button variant="contained" onClick={this.props.handleClose}>
                OK
              </Button>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
