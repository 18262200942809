import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function checkSheetColumns(newWebhookData) {
  return async function (dispatch) {
    const columnCheckData = {
      scopeObjectId: newWebhookData.smartsheetId,
      scope: 'ziptie-webhook-validation',
    }
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(newWebhookData.callbackUrl, columnCheckData)
      .then((resp) => {
        if (resp.data.smartsheetHookResponse === '') {
          const successMessage = 'success'
          dispatch({
            type: types.CHECK_MISSING_COLUMNS,
            payload: successMessage,
          })
          dispatch(createWebhook(newWebhookData))
        } else {
          const missingColumnMessage =
            'Your Smartsheet is missing the column(s): ' +
            resp.data.smartsheetHookResponse +
            '. Please make sure that all of the required columns are present ' +
            'and try again.'
          dispatch({
            type: types.CHECK_MISSING_COLUMNS,
            payload: missingColumnMessage,
          })
        }
      })
  }
}

export function createWebhook(newWebhookData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/webhooks`, newWebhookData)
      .then((resp) => {
        dispatch(updateWebhook(resp.data.id, newWebhookData))
      })
  }
}

export function deleteWebhook(webhookId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .delete(`${apiConfig.api.url}/webhooks/${webhookId}`)
      .then((resp) => {
        dispatch(getProjectSheetsAndHooks())
      })
  }
}

export function updateWebhook(webhookId, newWebhookData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .put(`${apiConfig.api.url}/webhooks/${webhookId}`, newWebhookData)
      .then((resp) => {
        dispatch(getProjectSheetsAndHooks())
      })
  }
}

export function getProjectSheetsAndHooks() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    let automationTestSheets = []
    let projectSheets = []
    let webhooks = []
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${apiConfig.scopeSheetWorkspace}`,
      )
      .then((resp) => {
        resp.data.forEach((sheet) => {
          projectSheets.push(sheet)
        })
      })
      .catch((error) => {
        if (error.response) {
          const result = 'Error loading project sheets.'
          dispatch({
            type: types.GET_PROJECT_SHEETS_AND_HOOKS,
            payload: result,
          })
        }
      })
    await axios
      .get(`${apiConfig.api.url}/webhooks`)
      .then((resp) => {
        webhooks = resp.data.filter((hook) => {
          return (
            hook.webhook.callbackUrl.toLowerCase() ===
            `${apiConfig.api.rapidUrl}/callback_test_devices`
          )
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Webhooks..'
          dispatch({
            type: types.GET_PROJECT_SHEETS_AND_HOOKS,
            payload: result,
          })
        }
      })
    projectSheets.forEach((sheet) => {
      const hook = webhooks.find((hook) => {
        return hook.webhook.scopeObjectId === sheet.id
      })
      let automationTestObj = {}
      automationTestObj.sheetName = sheet.name
      automationTestObj.sheetId = sheet.id
      automationTestObj.permalink = sheet.permalink
      automationTestObj.webhookId = hook !== undefined ? hook.webhook.id : 'N/A'
      automationTestObj.webhookName =
        hook !== undefined ? hook.webhook.name : ''
      if (hook !== undefined && hook.webhook.name.includes('<')) {
        const unformattedCreatorName = hook.webhook.name.substring(
          hook.webhook.name.lastIndexOf('<') + 1,
        )
        automationTestObj.creator = unformattedCreatorName.replace('>', ' ')
      } else {
        automationTestObj.creator = 'N/A'
      }
      if (
        hook !== undefined &&
        hook.webhook.stats !== undefined &&
        hook.webhook.stats !== null
      ) {
        automationTestObj.lastSuccessfulCallback =
          hook.webhook.stats.lastSuccessfulCallback
      } else {
        automationTestObj.lastSuccessfulCallback = 'N/A'
      }
      automationTestObj.webhookEnabled =
        hook !== undefined ? hook.webhook.enabled : false
      automationTestObj.webhookStatus =
        hook !== undefined ? hook.webhook.status : 'N/A'
      automationTestSheets.push(automationTestObj)
    })
    dispatch(resetSuccessMessage())
    dispatch({
      type: types.GET_PROJECT_SHEETS_AND_HOOKS,
      payload: automationTestSheets,
    })
  }
}

export function resetSuccessMessage() {
  const webhookSuccessMessage = ''
  return async function (dispatch) {
    dispatch({
      type: types.RESET_SUCCESS_MESSAGE,
      payload: webhookSuccessMessage,
    })
  }
}
