import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function createChange(newChangeData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/changes`, newChangeData)
      .then((resp) => {
        var result = {
          changeNumber: '',
          changeUrl: '',
          message: '',
        }
        if (resp.status === 201) {
          result = {
            changeNumber: resp.data.number,
            changeUrl: resp.data.url,
            message: `Change created!`,
          }
        } else {
          result = {
            changeNumber: '',
            changeUrl: '',
            message: 'An error occurred. Please try again later.',
          }
        }
        dispatch({
          type: types.CREATE_CHANGE,
          payload: result,
        })
      })
      .catch((error) => {
        var result
        if (error.response.status === 400) {
          result = {
            changeNumber: '',
            changeUrl: '',
            message: error.response.data.message.slice(12, -2),
          }
        } else {
          result = {
            changeNumber: '',
            changeUrl: '',
            message: error.response.data.message,
          }
        }
        dispatch({
          type: types.CREATE_CHANGE,
          payload: result,
        })
      })
  }
}

export function getChangeUsers(assignmentGroupId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/change_users?assignment_group_id=${assignmentGroupId}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_CHANGE_USER_LIST,
          payload: resp.data.sort((a, b) =>
            a.user_name > b.user_name ? 1 : -1,
          ),
        })
      })
      .catch((error) => {
        var result = 'Error Loading Change Users..'
        dispatch({
          type: types.GET_CHANGE_USER_LIST,
          payload: result,
        })
      })
  }
}

export function getChangeTemplates() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/change_templates`)
      .then((resp) => {
        dispatch({
          type: types.GET_CHANGE_TEMPLATES,
          payload: resp.data,
        })
      })
      .catch((error) => {
        var result = 'Error Loading Change Templates..'
        dispatch({
          type: types.GET_CHANGE_TEMPLATES,
          payload: result,
        })
      })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}
