import React from 'react'
import { connect } from 'react-redux'
import HeaderTitle from '../Header/HeaderTitle'
import {
  createChange,
  getChangeUsers,
  clearMessage,
  getChangeTemplates,
} from '../../store/ChangeRequests/actionCreator'
import {
  TextField,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  FormControl,
} from '@mui/material'
import Select from 'react-select'
import ChangeSuccessModal from './ChangeSuccessModal'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'

export class ChangeRequests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      changeUserList: [],
      eventsMessage: '',
      changeNumber: '',
      changeUrl: '',
      loading: false,
      statusOpen: false,
      changeTemplate: '',
      changeTemplates: [],
      implementer: '',
      duration: '30',
      configurationItem: '',
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.createNewChange = this.createNewChange.bind(this)
    this.loadUsers = this.loadUsers.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.dialogBox = React.createRef()
  }

  async componentDidMount() {
    await this.props.getChangeTemplates()
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Change Requests',
          pageName: 'Change Requests',
          pageType: 'Change Requests',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: {
        name: 'ziptie',
      },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Change Requests' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
    },
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.changeTemplates !== nextProps.changeTemplates) {
      this.setState({
        changeTemplates: nextProps.changeTemplates,
      })
    }
    this.setState({
      changeUserList: JSON.parse(JSON.stringify(nextProps.changeUserList)),
      eventsMessage: nextProps.eventsMessage,
      changeNumber: nextProps.changeNumber,
      changeUrl: nextProps.changeUrl,
    })
  }

  handleStatusOpen() {
    this.setState({ statusOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  async loadUsers(assignmentGroupId) {
    await this.props.getChangeUsers(assignmentGroupId)
  }

  createNewChange = (
    event,
    changeTemplateName,
    implementer,
    duration,
    configurationItem,
  ) => {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Change Requests - Create' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    this.props.clearMessage()
    const newChangeData = {
      change_owner: this.props.userInfo.email,
      request_duration: duration,
      change_template: changeTemplateName,
      implementer: implementer,
      configuration_item_name: configurationItem,
    }
    this.props.createChange(newChangeData)
    this.setState({ statusOpen: true })
    this.resetForm()
  }

  resetForm = () => {
    this.setState({
      changeTemplate: '',
      implementer: '',
      duration: '30',
      configurationItem: '',
      buttonEnabled: false,
    })
  }

  validateForm = () => {
    if (
      this.state.changeTemplate !== '' &&
      this.state.implementer !== '' &&
      this.state.duration !== '' &&
      this.state.configurationItem !== ''
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    let modalTitle
    if (this.state.eventsMessage === 'Change created!') {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage !== 'Change created!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <div>
        <HeaderTitle title="Change Requests" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ margin: 10 }}>
            <Grid
              container
              spacing={16}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid item xs={10}>
                <Card sx={{ height: '60vh' }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      padding: 0,
                      flexDirection: 'column',
                    }}
                  >
                    <Grid container className="cardBanner">
                      <Grid item sm={12} lg={4}>
                        CREATE CHANGE
                      </Grid>
                    </Grid>
                    <form
                      onSubmit={(event) =>
                        this.createNewChange(
                          event,
                          this.state.changeTemplate.value,
                          this.state.implementer.value,
                          this.state.duration,
                          this.state.configurationItem,
                        )
                      }
                      style={{
                        alignItems: 'stretch',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          width: '100%',
                          height: '100%',
                          padding: '10px',
                          alignContent: 'space-around',
                          display: 'flex',
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '4%',
                            width: '90%',
                          }}
                        >
                          <Grid
                            item
                            lg={6}
                            md={12}
                            style={{ marginBottom: '1%' }}
                          >
                            <FormControl
                              sx={{
                                width: '90%',
                              }}
                            >
                              <Select
                                value={this.state.changeTemplate}
                                options={
                                  this.state.changeTemplates.length > 0
                                    ? this.state.changeTemplates.map(
                                        (template) => ({
                                          label: template.ziptieTemplateName,
                                          value:
                                            template.serviceNowTemplateName,
                                          id: template.assignmentGroupId,
                                        }),
                                      )
                                    : [
                                        {
                                          label: this.state.eventsMessage,
                                        },
                                      ]
                                }
                                placeholder="Change Template"
                                onChange={(event) => {
                                  this.setState(
                                    { changeTemplate: event },
                                    function () {
                                      this.validateForm()
                                      this.loadUsers(event.id)
                                    },
                                  )
                                }}
                                inputProps={{
                                  name: 'changeTemplate',
                                  id: 'changeTemplate',
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={12}
                            style={{ marginBottom: '1%' }}
                          >
                            <FormControl style={{ width: '90%' }}>
                              <Select
                                value={this.state.implementer}
                                options={
                                  this.state.changeUserList.length > 0
                                    ? this.state.changeUserList.map(
                                        (changeUser) => ({
                                          label: changeUser.user_name,
                                          value: changeUser.user_lan_id,
                                        }),
                                      )
                                    : []
                                }
                                placeholder="Implementer"
                                onChange={(event) => {
                                  this.setState(
                                    { implementer: event },
                                    function () {
                                      this.validateForm()
                                    },
                                  )
                                }}
                                inputProps={{
                                  name: 'implementer',
                                  id: 'implementer',
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="text"
                            variant="standard"
                            className="formInput"
                            value={this.state.configurationItem}
                            onChange={(event) => {
                              this.setState(
                                {
                                  configurationItem: event.target.value,
                                },
                                function () {
                                  this.validateForm()
                                },
                              )
                            }}
                            placeholder="Example: TxxxxSWT0001, TxxxxUPS0001"
                            label="Configuration Item"
                            required
                            style={{ width: '94%' }}
                            InputProps={{
                              className: 'redUnderline',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="text"
                            variant="standard"
                            className="blackText"
                            value={this.state.duration}
                            onChange={(event) => {
                              this.setState(
                                { duration: event.target.value },
                                function () {
                                  this.validateForm()
                                },
                              )
                            }}
                            label="Duration (in Minutes)"
                            InputProps={{
                              className: 'redUnderline',
                            }}
                          />
                        </Grid>
                        <br />
                        <CardActions style={{ width: '100%' }}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: 20,
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              disabled={!this.state.buttonEnabled}
                            >
                              Create
                            </Button>
                          </Grid>
                        </CardActions>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
                <ChangeSuccessModal
                  open={this.state.statusOpen}
                  handleClose={this.handleStatusClose}
                  eventsMessage={this.state.eventsMessage}
                  title={modalTitle}
                  changeNumber={this.state.changeNumber}
                  changeUrl={this.state.changeUrl}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    changeTemplates: state.changeRequests.changeTemplates,
    changeUserList: state.changeRequests.changeUserList,
    eventsMessage: state.changeRequests.eventsMessage,
    changeNumber: state.changeRequests.changeNumber,
    changeUrl: state.changeRequests.changeUrl,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  createChange,
  getChangeUsers,
  clearMessage,
  getChangeTemplates,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequests)
