import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function getProjectData(totalPages) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    var promises = []
    var activeProjects = []
    var page = 1
    do {
      promises.push(
        axios.get(
          `${apiConfig.api.url}/location_projects?page=${page}&per_page=1000`,
        ),
      )
      page++
    } while (page <= totalPages)
    const promisesResolved = promises.map((promise) =>
      promise.catch((error) => ({ error })),
    )
    await axios.all(promisesResolved).then((resp) => {
      resp.forEach((response, index) => {
        if (response.status === 200) {
          activeProjects = activeProjects.concat(response.data)
        }
        if (index === totalPages - 1) {
          if (activeProjects.length === 0) {
            activeProjects = 'No Projects Found!'
          }
          dispatch({
            type: types.GET_PROJECTS,
            payload: {
              projects: activeProjects,
            },
          })
        }
      })
    })
  }
}

export function getProjects() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    var totalPages = 1
    await axios
      .get(`${apiConfig.api.url}/count_location_projects`)
      .then((resp) => {
        totalPages = Math.ceil(resp.data / 1000)
        dispatch(getProjectData(totalPages))
      })
  }
}
