/* eslint-disable prettier/prettier */
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'
import compare from '../../common/Compare'

export function getWebhookList() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/webhooks`)
      .then((resp) => {
        var formattedWebhookList = []
        const hooks = resp.data
        hooks.forEach((hook) => {
          var formattedWebhook = {}
          formattedWebhook.id = hook.webhook.id
          formattedWebhook.name = hook.webhook.name
          formattedWebhook.smartsheetId = hook.webhook.scopeObjectId
          formattedWebhook.callbackUrl = hook.webhook.callbackUrl
          formattedWebhook.enabled = hook.webhook.enabled
          if (hook.webhook.stats !== null) {
            formattedWebhook.lastSuccessfulCallback =
              hook.webhook.stats.lastSuccessfulCallback
          } else {
            formattedWebhook.lastSuccessfulCallback = ''
          }
          formattedWebhook.status = hook.webhook.status
          formattedWebhook.disabledDetails = hook.webhook.disabledDetails
          formattedWebhook.sheetUrl = hook.sheetUrl
          formattedWebhook.sheetName = hook.sheetName
          formattedWebhookList.push(formattedWebhook)
        })
        dispatch({
          type: types.GET_WEBHOOK_LIST,
          payload: formattedWebhookList,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Webhooks..'
          dispatch({
            type: types.GET_WEBHOOK_LIST,
            payload: result,
          })
        }
      })
  }
}

export function checkSheetColumns(newWebhookData) {
  return async function (dispatch) {
    const columnCheckData = {
      scopeObjectId: newWebhookData.smartsheetId,
      scope: 'ziptie-webhook-validation',
    }
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(newWebhookData.callbackUrl, columnCheckData)
      .then((resp) => {
        if (resp.data.smartsheetHookResponse === '') {
          const successMessage = 'Webhook Created'
          dispatch({
            type: types.CHECK_MISSING_COLUMNS,
            payload: successMessage,
          })
          dispatch(createWebhook(newWebhookData))
        } else {
          const missingColumnMessage =
            'Your Smartsheet is missing the column(s): ' +
            resp.data.smartsheetHookResponse +
            '. Please make sure that all of the required columns are present ' +
            'and try again.'
          dispatch({
            type: types.CHECK_MISSING_COLUMNS,
            payload: missingColumnMessage,
          })
        }
      })
      .catch((error) => {
        if (error.response) {
          const result = 'Callback Url not found. Please try again.'
          dispatch({
            type: types.CHECK_MISSING_COLUMNS,
            payload: result,
          })
        }
      })
  }
}

export function createWebhook(newWebhookData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/webhooks`, newWebhookData)
      .then((resp) => {
        dispatch(updateWebhook(resp.data.id, newWebhookData))
      })
  }
}

export function updateWebhook(webhookId, newWebhookData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .put(`${apiConfig.api.url}/webhooks/${webhookId}`, newWebhookData)
      .then((resp) => {
        dispatch(getWebhookList())
      })
  }
}

export function deleteWebhook(webhookId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .delete(`${apiConfig.api.url}/webhooks/${webhookId}`)
      .then((resp) => {
        dispatch(getWebhookList())
      })
  }
}

export function getActiveSheets(workspaceID) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${workspaceID}`
      )
      .then((resp) => {
        const sortedSheets = resp.data.sort(compare)
        dispatch({
          type: types.GET_ACTIVE_SHEETS,
          payload: sortedSheets,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_WEBHOOK_LIST,
            payload: result,
          })
        }
      })
  }
}

export function getSheetColumns(sheetId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/smartsheet_columns?smartsheet_id=${sheetId}`)
      .then((resp) => {
        const sheetColumns = resp.data.sort(function (a, b) {
          return a.title.toUpperCase().localeCompare(b.title.toUpperCase())
        })
        dispatch({
          type: types.GET_SHEET_COLUMNS,
          payload: sheetColumns,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_WEBHOOK_LIST,
            payload: result,
          })
        }
      })
  }
}

export function getWorkspaces() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/workspaces`)
      .then((resp) => {
        const workspaces = resp.data
        dispatch({
          type: types.GET_WORKSPACES,
          payload: workspaces,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_WORKSPACES,
            payload: result,
          })
        }
      })
  }
}

export function resetForm() {
  const emptyFormValue = ''
  return async function (dispatch) {
    dispatch({
      type: types.RESET_FORM,
      payload: emptyFormValue,
    })
  }
}

export function resetSuccessMessage() {
  const webhookSuccessMessage = ''
  return async function (dispatch) {
    dispatch({
      type: types.RESET_SUCCESS_MESSAGE,
      payload: webhookSuccessMessage,
    })
  }
}
