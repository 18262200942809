import React from 'react'
import { shape, func } from 'prop-types'
import { Typography } from '@mui/material'
import HeaderTitle from '../Header/HeaderTitle'

class NotFoundPage extends React.Component {
  static propTypes = {
    layoutActions: shape({
      setHeaderTitle: func,
    }),
  }

  render() {
    return (
      <div>
        <HeaderTitle title="Not Found or No Rights to View" />
        <Typography variant="h4" gutterBottom>
          <br />
          <center>
            Page not found or you are not an authorized user.
            <br />
            <br />
            To get access, please raise a{' '}
            <a
              href="https://myaccess.target.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              MyAccess
            </a>{' '}
            request for appropriate ziptie group.
          </center>
        </Typography>
      </div>
    )
  }
}

export default NotFoundPage
