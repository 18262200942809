import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import {
  clearDhcpMessage,
  downloadDhcpFile,
  resetDhcpGeneration,
} from '../../../store/AdditionalTools/actionCreator'
import { DhcpModal } from './DhcpModal'
import apiConfig from '../../../config/apiConfig'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../../common/FireflyHelper'

export class DhcpExcelGenerationTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonEnabled: false,
      xlsxFileData: null,
      xlsxFileName: '',
      dhcpErrorMessage: '',
      dhcpErrorOpen: false,
      selectedFile: null,
    }
    initializeFireflySDK()
    this.createXlsx = this.createXlsx.bind(this)
    this.fileDownload = React.createRef()
    this.fileData = this.fileData.bind(this)
    this.handleErrorModalClose = this.handleErrorModalClose.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.xlsxFileData !== nextProps.xlsxFileData &&
      nextProps.xlsxFileData !== '' &&
      nextProps.xlsxFileData != null
    ) {
      const url = window.URL.createObjectURL(nextProps.xlsxFileData)
      this.setState(
        {
          dhcpErrorOpen: false,
          xlsxFileData: url,
          xlsxFileName: nextProps.xlsxFileName,
        },
        function () {
          this.fileDownload.current.click()
        },
      )
    }
    if (this.props.dhcpErrorMessage !== nextProps.dhcpErrorMessage) {
      this.setState({
        dhcpErrorMessage: nextProps.dhcpErrorMessage,
      })
    }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'DHCP Excel Generation Tab' },
      user: { id: userInfo.email },
    })
  }

  createXlsx = (event) => {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'DHCP Excel Generation - Submit' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    this.props.clearDhcpMessage()
    this.setState({ dhcpErrorOpen: true })
    this.props.downloadDhcpFile(this.state.selectedFile)
    this.resetForm()
  }

  fileData = () => {
    if (this.state.selectedFile !== null) {
      return <p>{this.state.selectedFile.name}</p>
    }
  }

  handleErrorModalClose = () => {
    this.setState({
      dhcpErrorOpen: false,
    })
    this.props.resetDhcpGeneration()
  }

  resetForm = () => {
    this.setState({
      buttonEnabled: false,
      selectedFile: '',
    })
  }

  validateForm = () => {
    if (this.state.selectedFile === '') {
      this.setState({ buttonEnabled: false })
    } else {
      this.setState({ buttonEnabled: true })
    }
  }

  render() {
    return (
      <CardContent
        style={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Select file you wish to use to generate formatted DHCP sheet.
            </Typography>
          </Grid>
        </CardActions>
        <form
          onSubmit={this.createXlsx}
          style={{
            alignItems: 'stretch',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid container item style={{ paddingLeft: 8 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  paddingTop: '10px',
                  flexBasis: '500px',
                }}
              >
                <input
                  id="selectedFile"
                  type="file"
                  hidden
                  key={this.state.selectedFile}
                  accept="application/csv"
                  onChange={(event) => {
                    this.setState(
                      { selectedFile: event.target.files[0] },
                      function () {
                        this.validateForm()
                      },
                    )
                  }}
                />
                <label htmlFor="selectedFile">
                  <Button
                    variant="contained"
                    type="file"
                    color="primary"
                    component="span"
                  >
                    Choose File
                  </Button>
                </label>
                {this.fileData()}
                <CardActions
                  style={{
                    width: '100%',
                    height: '30%',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 20,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!this.state.buttonEnabled}
                    >
                      Submit
                    </Button>
                  </Grid>
                </CardActions>
              </div>
            </Grid>
          </Grid>
        </form>
        <DhcpModal
          open={this.state.dhcpErrorOpen}
          handleClose={this.handleErrorModalClose}
          message={this.state.dhcpErrorMessage}
          clearMessage={this.props.clearDhcpMessage}
        />
        <a
          href={this.state.xlsxFileData}
          download={this.state.xlsxFileName}
          ref={this.fileDownload}
          style={{ display: 'none' }}
        >
          ''
        </a>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dhcpErrorMessage: state.additionalTools.dhcpErrorMessage,
    xlsxFileData: state.additionalTools.xlsxFileData,
    xlsxFileName: state.additionalTools.xlsxFileName,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  clearDhcpMessage,
  downloadDhcpFile,
  resetDhcpGeneration,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DhcpExcelGenerationTab)
