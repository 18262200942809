import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function postPins(smartsheetId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/generate_pins`, {
        smartsheetId: smartsheetId,
      })
      .then((resp) => {
        var result = ''
        if (
          resp.status === 200 &&
          resp.data[0].Message.includes(
            'RnD Batch PIN job has been triggered successfully',
          )
        ) {
          result =
            'PIN request submitted. You will receive an email with the results.'
        } else if (
          resp.status === 200 &&
          resp.data[0].Message.includes('Missing data for')
        ) {
          result = `PIN request submitted for some locations. ${resp.data[0].Message}`
        } else if (
          resp.status === 200 &&
          (resp.data[0].Message.includes('This sheet is missing') ||
            resp.data[0].Message.includes('No PIN(s) generated'))
        ) {
          result = resp.data[0].Message
        } else if (resp.status === 200 && resp.data[0].Message.includes('')) {
          result =
            'PIN request submitted. Due to large amount of locations, be aware that it may take some time before receiving an email.'
        } else if (resp.status === 404) {
          result = 'PIN(s) not found'
        } else {
          result =
            'An error occurred. Check the Smartsheet or PIN Generation API availability.'
        }
        dispatch({
          type: types.POST_PINS,
          payload: result,
        })
      })
      .catch(() => {
        var result =
          'An error occurred. Check the Smartsheet ID and API availability.'
        dispatch({
          type: types.POST_PINS,
          payload: result,
        })
      })
  }
}

export function getPinsWithHooks() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    let pinSheets = []
    let pins = []
    let webhooks = []
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${apiConfig.scopeSheetWorkspace}`,
      )
      .then((resp) => {
        resp.data.forEach((sheet) => {
          pins.push(sheet)
        })
      })
      .catch((error) => {
        if (error.response) {
          const result = 'Error loading pins.'
          dispatch({
            type: types.GET_PINS_AND_HOOKS,
            payload: result,
          })
        }
      })
    await axios
      .get(`${apiConfig.api.url}/webhooks`)
      .then((resp) => {
        resp.data.forEach((hook) => {
          webhooks.push(hook)
        })
      })
      .catch((error) => {
        if (error.response) {
          const result = 'Error loading webhooks.'
          dispatch({
            type: types.GET_PINS_AND_HOOKS,
            payload: result,
          })
        }
      })
    pins.forEach((sheet) => {
      let pinObj = {}
      const hook = webhooks.filter((hook) => {
        return (
          hook.webhook.name.substring(0, hook.webhook.name.lastIndexOf('<')) ===
          'Pins-' + sheet.name
        )
      })
      pinObj.sheetName = sheet.name
      pinObj.sheetId = sheet.id
      pinObj.permalink = sheet.permalink
      pinObj.webhookId = hook.length > 0 ? hook[0].webhook.id : 'N/A'
      pinObj.webhookName = hook.length > 0 ? hook[0].webhook.name : ''
      if (hook.length > 0 && hook[0].webhook.name.includes('<')) {
        const unformattedOwnerName = hook[0].webhook.name.substring(
          hook[0].webhook.name.lastIndexOf('<') + 1,
        )
        pinObj.owner = unformattedOwnerName.replace('>', ' ')
      } else {
        pinObj.owner = 'N/A'
      }
      pinObj.webhookEnabled = hook.length > 0 ? hook[0].webhook.enabled : false
      pinObj.webhookStatus = hook.length > 0 ? hook[0].webhook.status : ''
      pinSheets.push(pinObj)
    })
    dispatch({
      type: types.GET_PINS_AND_HOOKS,
      payload: pinSheets,
    })
  }
}

export function createPinGenerationHook(newWebhookData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .post(`${apiConfig.api.url}/webhooks`, newWebhookData)
      .then((resp) => {
        newWebhookData.name = resp.data.name
        dispatch(updatePinGenerationHook(resp.data.id, newWebhookData))
      })
  }
}

export function deletePinGenerationHook(webhookId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .delete(`${apiConfig.api.url}/webhooks/${webhookId}`)
      .then((resp) => {
        dispatch(getPinsWithHooks())
      })
  }
}

export function updatePinGenerationHook(webhookId, newWebhookData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .put(`${apiConfig.api.url}/webhooks/${webhookId}`, newWebhookData)
      .then((resp) => {
        dispatch(getPinsWithHooks())
      })
  }
}

export function revokePin(revokePinData) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .put(`${apiConfig.api.url}/revoke_pins`, revokePinData)
      .then((resp) => {
        if (
          resp.data.data[0].failed_locations != null &&
          resp.data.data[0].success_locations == null
        ) {
          const failedMessage =
            'Revoke failed for the following location(s): ' +
            resp.data.data[0].failed_locations.join(', ') +
            '.'
          dispatch({
            type: types.RETURN_MESSAGE,
            payload: failedMessage,
          })
        } else if (
          resp.data.data[0].success_locations != null &&
          resp.data.data[0].failed_locations == null
        ) {
          const successMessage =
            'Revoke was successful for the following location(s): ' +
            resp.data.data[0].success_locations.join(', ') +
            '.'
          dispatch({
            type: types.RETURN_MESSAGE,
            payload: successMessage,
          })
        } else {
          const successMessage = resp.data.data[0].success_locations.join(', ')
          const failedMessage = resp.data.data[0].failed_locations.join(', ')
          const message = `Revoke was successful for the following location(s): ${successMessage}.
            \nRevoke failed for the following location(s): ${failedMessage}.`
          dispatch({
            type: types.RETURN_MESSAGE,
            payload: message,
          })
        }
      })
      .catch((error) => {
        if (error.response) {
          var message =
            'Error Revoking PIN(s), contact Rollout Deployment Software Engineering Team for further help.'
          dispatch({
            type: types.RETURN_MESSAGE,
            payload: message,
          })
        }
      })
  }
}

export function resetRevokeMessage() {
  const resetRevokeMessage = ''
  return async function (dispatch) {
    dispatch({
      type: types.RETURN_MESSAGE,
      payload: resetRevokeMessage,
    })
  }
}
