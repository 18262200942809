import * as types from './actionType'

export const initialState = {
  activeSheets: [],
  executionPeriods: [],
  eventMessage: '',
  kitListSmartsheetData: [],
}

export default function kitListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_KIT_LIST: {
      const newState = {
        ...state,
        kitListSmartsheetData: action.payload.kitListSmartsheetData,
        eventMessage: action.payload.eventMessage,
      }
      return newState
    }
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        kitListSmartsheetData: [],
        eventMessage: '',
      }
      return newState
    }
    case types.GET_ACTIVE_SHEETS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            activeSheets: action.payload,
          }
        : {
            ...state,
            activeSheets: [action.payload],
          }
      return newState
    }
    case types.GET_EXECUTION_PERIODS: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            executionPeriods: action.payload,
          }
        : {
            ...state,
            executionPeriods: [action.payload],
          }
      return newState
    }
    case types.GET_WORKSPACES: {
      const newState = Array.isArray(action.payload)
        ? {
            ...state,
            workspaces: action.payload,
          }
        : {
            ...state,
            workspaces: [action.payload],
          }
      return newState
    }
    default: {
      return state
    }
  }
}
