import React from 'react'
import HeaderTitle from '../Header/HeaderTitle'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import {
  postPins,
  getPinsWithHooks,
  createPinGenerationHook,
  deletePinGenerationHook,
  revokePin,
  resetRevokeMessage,
} from '../../store/PinGeneration/actionCreator'
import CreationSuccessModal from './CreationSuccessModal'
import CustomToolbar from './CustomToolBarButtons'
import RevokePinDialog from './RevokePinDialog'
import apiConfig from '../../config/apiConfig'
import { FormGroup, FormControlLabel, Tooltip, Switch } from '@mui/material'
import PinHelpDialog from './PinHelpDialog'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'

const styles = (theme) => ({
  toggleLoadingCursor: {
    pointerEvents: 'none',
    cursor: 'wait',
  },
  toggleLoadedCursor: {
    pointerEvents: 'auto',
  },
  webhookRowLoading: {
    opacity: 0.3,
    cursor: 'wait',
  },
  webhookRowLoaded: {
    opacity: 1,
  },
})

export class PinGeneration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      smartsheetId: '',
      result: '',
      pinsList: [],
      dialogOpen: false,
      revokeDialogOpen: false,
      revokeMessageOpen: false,
      pinHelpOpen: false,
      revokePinHelpOpen: false,
      pinsMessage: '',
      revokeMessage: '',
      newWebhookData: {},
      rowLoading: [],
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.dialogBox = React.createRef()
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.revokePinDialogData = this.revokePinDialogData.bind(this)
    this.handleRevokeOpen = this.handleRevokeOpen.bind(this)
    this.handleRevokeClose = this.handleRevokeClose.bind(this)
    this.handleMessageOpen = this.handleMessageOpen.bind(this)
    this.handleMessageClose = this.handleMessageClose.bind(this)
    this.handlePinHelpOpen = this.handlePinHelpOpen.bind(this)
    this.handlePinHelpClose = this.handlePinHelpClose.bind(this)
    this.handleRevokePinHelpOpen = this.handleRevokePinHelpOpen.bind(this)
    this.handleRevokePinHelpClose = this.handleRevokePinHelpClose.bind(this)
    this.toggleWebhook = this.toggleWebhook.bind(this)
  }

  static defaultProps = {
    userInfo: {
      email: undefined,
      lanId: undefined,
    },
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Pin Generation',
          pageName: 'Pin Generation',
          pageType: 'Pin Generation',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Pin Generation' },
      user: { id: userInfo.email },
    })
    this.setState({ loading: true })
    await this.props.getPinsWithHooks()
    this.setState({ loading: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pinsMessage !== '' && nextProps.pinsMessage !== undefined) {
      this.setState({
        pinsMessage: nextProps.pinsMessage,
        pinsList: JSON.parse(JSON.stringify(nextProps.pinsList)),
      })
    } else {
      this.setState({
        pinsList: JSON.parse(JSON.stringify(nextProps.pinsList)),
        rowLoading: [],
      })
    }
    this.setState({
      revokeMessage: nextProps.revokeMessage,
    })
  }

  handleRevokeOpen() {
    this.setState({ revokeDialogOpen: true })
  }

  handleRevokeClose() {
    this.setState({ revokeDialogOpen: false })
    this.dialogBox.current.resetForm()
  }

  handlePinHelpOpen() {
    this.setState({ pinHelpOpen: true })
  }

  handlePinHelpClose() {
    this.setState({ pinHelpOpen: false })
  }

  handleRevokePinHelpOpen() {
    this.setState({ revokePinHelpOpen: true })
  }

  handleRevokePinHelpClose() {
    this.setState({ revokePinHelpOpen: false })
  }

  handleOpen() {
    this.setState({ dialogOpen: true })
  }

  handleClose() {
    if (
      this.state.pinsMessage.includes('PIN request submitted') ||
      this.state.pinsMessage.includes('Missing data for')
    ) {
      this.props.createPinGenerationHook(this.state.newWebhookData)
    } else {
      this.setState({ rowLoading: [] })
    }
    this.setState({
      dialogOpen: false,
    })
  }
  handleMessageOpen() {
    this.setState({ revokeMessageOpen: true })
  }
  handleMessageClose() {
    this.setState({
      revokeMessageOpen: false,
      revokeDialogOpen: false,
    })
    this.props.resetRevokeMessage()
  }

  toggleWebhook = (
    event,
    smartsheetId,
    sheetName,
    webhookId,
    user,
    rowIndex,
  ) => {
    if (this.state.rowLoading.includes(rowIndex) !== true) {
      const allLoadingRows = this.state.rowLoading.concat(rowIndex)
      if (event.target.value === 'false') {
        if (webhookId !== 'N/A') {
          this.props.deletePinGenerationHook(webhookId)
        }
        this.setState({ pinsMessage: '' })
        this.handleOpen()
        this.props.postPins(smartsheetId)
        const userName = user.email
          .substring(0, user.email.indexOf('@'))
          .replace('.', ' ')
        const newWebhookData = {
          callbackUrl: `${apiConfig.api.rapidUrl}/callback_generate_pins`,
          name: 'Pins-' + sheetName + '<' + userName,
          smartsheetId: smartsheetId,
          enabled: true,
          webhookType: 'pins',
        }
        this.setState({
          newWebhookData: newWebhookData,
          rowLoading: allLoadingRows,
        })
      } else {
        this.setState({ rowLoading: allLoadingRows })
        this.props.deletePinGenerationHook(webhookId)
      }
    }
  }

  revokePinDialogData = (event, email, locations, user) => {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Pin Generation - Revoke' },
      user: { id: user.email },
    })
    event.preventDefault()
    const revokePinData = {
      data: [
        {
          email: email,
          locations: locations.split(',').map((x) => +x),
        },
      ],
    }
    this.setState({ revokeMessageOpen: true })
    this.props.revokePin(revokePinData)
    this.dialogBox.current.resetForm()
  }
  formatStatus = (input) => {
    let formattedStatus
    if (input === 'ENABLED') {
      formattedStatus = 'Enabled'
    } else if (input === 'NEW_NOT_VERIFIED') {
      formattedStatus = 'Not Verified'
    } else if (input === 'DISABLED_BY_OWNER') {
      formattedStatus = 'Disabled By Owner'
    } else if (input === 'DISABLED_VERIFICATION_FAILED') {
      formattedStatus = 'Verification Failed'
    } else if (input === 'DISABLED_SCOPE_INACCESSIBLE') {
      formattedStatus = 'Smartsheet Scope Not Accessible'
    } else {
      formattedStatus = 'Automate PIN Generation'
    }
    return formattedStatus
  }

  render() {
    const { classes, userInfo } = this.props

    let pinSheets = []
    if (this.state.pinsList.length > 0) {
      const pinColumns = [
        {
          label: 'Sheet Name',
          name: 'sheetName',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <a
                  href={tableMeta.rowData[5]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value}
                </a>
              )
            },
          },
        },
        {
          label: 'Webhook Id',
          name: 'webhookId',
        },
        {
          label: 'Owner',
          name: 'owner',
        },
        {
          label: 'Disabled/Enabled',
          name: 'webhookEnabled',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Tooltip title={this.formatStatus(tableMeta.rowData[4])}>
                        <Switch
                          color={'primary'}
                          checked={value}
                          value={value}
                          onChange={(event) =>
                            this.toggleWebhook(
                              event,
                              tableMeta.rowData[6],
                              tableMeta.rowData[0],
                              tableMeta.rowData[1],
                              userInfo,
                              tableMeta.rowIndex,
                            )
                          }
                        />
                      </Tooltip>
                    }
                  />
                </FormGroup>
              )
            },
          },
        },
        {
          label: 'Status',
          name: 'webhookStatus',
          options: { display: false },
        },
        {
          label: 'Smartsheet Link',
          name: 'permalink',
          options: { display: false },
        },
        {
          label: 'Smartsheet Id',
          name: 'sheetId',
          options: { display: false },
        },
      ]
      pinSheets = (
        <MUIDataTable
          title="PIN Generation"
          columns={pinColumns}
          data={this.props.pinsList}
          options={{
            responsive: 'vertical',
            pagination: true,
            expandableRows: false,
            selectableRows: 'none',
            rowsPerPageOptions: [10, 20, 50],
            customToolbar: () => {
              return (
                <CustomToolbar
                  openDialog={this.handleRevokeOpen}
                  openHelpDialog={this.handlePinHelpOpen}
                />
              )
            },
            setRowProps: (row, dataIndex, rowIndex) => {
              let displayClass
              let loadingRow = this.state.rowLoading.find((currentRow) => {
                return currentRow === dataIndex
              })
              if (loadingRow !== undefined) {
                displayClass = classes.webhookRowLoading
              } else {
                displayClass = classes.webhookRowLoaded
              }
              return {
                className: displayClass,
              }
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )
    } else {
      pinSheets = this.state.pinsList
    }

    const pinsPanel = <div>{pinSheets}</div>

    return (
      <div>
        <HeaderTitle title="PIN Generation" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 10 }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <CreationSuccessModal
                  open={this.state.dialogOpen}
                  handleClose={this.handleClose}
                  pinsMessage={this.state.pinsMessage}
                />
                <PinHelpDialog
                  open={this.state.pinHelpOpen}
                  handlePinHelpClose={this.handlePinHelpClose}
                />
                <RevokePinDialog
                  open={this.state.revokeDialogOpen}
                  helpOpen={this.state.revokePinHelpOpen}
                  handleRevokeClose={this.handleRevokeClose}
                  revokePinDialogData={this.revokePinDialogData}
                  user={this.props.userInfo}
                  ref={this.dialogBox}
                  messageOpen={this.state.revokeMessageOpen}
                  revokeMessage={this.state.revokeMessage}
                  handleMessageOpen={this.handleMessageOpen}
                  handleMessageClose={this.handleMessageClose}
                  handleRevokePinHelpOpen={this.handleRevokePinHelpOpen}
                  handleRevokePinHelpClose={this.handleRevokePinHelpClose}
                />
                {pinsPanel}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    result: state.pinGeneration.result,
    pinsList: state.pinGeneration.pinsList,
    userInfo: state.auth.userInfo,
    pinsMessage: state.pinGeneration.pinsMessage,
    revokeMessage: state.pinGeneration.revokeMessage,
  }
}

const mapDispatchToProps = {
  postPins,
  getPinsWithHooks,
  createPinGenerationHook,
  deletePinGenerationHook,
  revokePin,
  resetRevokeMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PinGeneration))
