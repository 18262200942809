const SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS'

export const signInSuccess = (payload) => ({ type: SIGN_IN_SUCCESS, payload })

export const initialState = {
  userInfo: {
    email: '',
    firstName: '',
    lanId: '',
    lastName: '',
    memberOf: [],
  },
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        userInfo: {
          email: payload.userInfo.email,
          firstName: payload.userInfo.firstName,
          lanId: payload.userInfo.lanId,
          lastName: payload.userInfo.lastName,
          memberOf: payload.userInfo.memberOf,
        },
      }
    default:
      return state
  }
}

export default reducer
