import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material/'

export default class CreateScopeSheetSuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventMessage: '',
      smartsheetData: [],
    }
  }

  render() {
    const smartsheetData =
      this.props.smartsheetData !== undefined
        ? this.props.smartsheetData.map((link) => (
            <p key={link.permalink}>
              <a
                href={link.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            </p>
          ))
        : ''
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.title === '' ? (
          <div>
            <DialogContent>
              <LinearProgress color="success" />
            </DialogContent>
          </div>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <div>
                <div style={{ paddingBottom: '10px' }}>
                  {this.props.title === 'Success'
                    ? smartsheetData
                    : this.props.eventsMessage}
                </div>
                <Button variant="contained" onClick={this.props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
