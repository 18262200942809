import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class CreateScopeSheetHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Create Scope Sheet Help</DialogTitle>
        <DialogContent>
          <h4>Creating A Scope Sheet</h4>
          <div>
            <ol>
              <li>
                In 'Destination Workspace' drop-down, select which Smartsheet
                Workspace where you want to create your sheet.
              </li>
              <li>
                The 'Destination Folder' drop-down will then populate with all
                of the available folders within your chosen Workspace. Select
                the folder where you want your sheet to be created.
              </li>
              <li>
                In the 'Sheet Name' field, enter in the name for your scope
                sheet.
              </li>
              <li>
                The 'Automation Types' drop-down will show all of the available
                automated testing types. This drop-down is both searchable and
                mult-selectable. Select all of the automated testing types that
                you need for your sheet. NOTE: The selected testing types will
                each be created for ALL entered locations.
              </li>
              <li>
                The 'Enter Additional Automation Types' text box is for devices
                that don't currently have automated tests built for them, but
                are devices that still need to be included in the scope sheet.
                In the created sheet, the value of 'Manual' will be written to
                the 'Automation Type' column, and your entered value will be
                written in the 'Task Name' column for that row. NOTE: If adding
                multiple automation types in this box, they need to be entered
                as a comma-separated list. Also, this field is optional.
              </li>
              <li>
                In the final text box, enter a list of location T#s, separated
                by either spaces or commas.
              </li>
              <li>
                Once all of the required fields have been entered, click the
                Submit button. A link to your newly created scope sheet will be
                generated. Congratulations!
              </li>
            </ol>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
