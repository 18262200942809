import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'
import compare from '../../common/Compare'

export function clearDhcpMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_DHCP_MESSAGE,
      payload: {
        dhcpErrorMessage: '',
      },
    })
  }
}

export function downloadDhcpFile(uploadCsv) {
  var formData = new FormData()
  formData.append('file', uploadCsv)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    responseType: 'arraybuffer',
  }
  return async function (dispatch) {
    axios
      .post(`${apiConfig.api.url}/dhcp_generation`, formData, config)
      .then((resp) => {
        const responseData = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        if (
          resp.headers.message.includes('Success') ||
          resp.headers.message === ''
        ) {
          const fileName = resp.headers.message.split(' ')[1]
          dispatch({
            type: types.DHCP_GENERATION,
            payload: {
              dhcpErrorMessage: '',
              xlsxFileData: responseData,
              xlsxFileName: `${fileName}.xlsx`,
            },
          })
        } else {
          dispatch({
            type: types.DHCP_GENERATION,
            payload: {
              dhcpErrorMessage: resp.headers.message,
              xlsxFileData: responseData,
              xlsxFileName: 'Incomplete',
            },
          })
        }
      })
      .catch((resp) => {
        let errorMessage =
          'Something went wrong. Please contact RDSE team for assistance.'
        if (resp.response.headers.message !== '') {
          errorMessage = resp.response.headers.message
        }
        dispatch({
          type: types.DHCP_GENERATION,
          payload: {
            dhcpErrorMessage: errorMessage,
            xlsxFileData: '',
            xlsxFileName: '',
          },
        })
      })
  }
}

export function downloadDnsFile(locationsList) {
  const formattedLocationsList = formatFreeTextList(locationsList)
  return async function (dispatch) {
    axios
      .get(
        `${
          apiConfig.api.url
        }/dns_generation?location_ids=${formattedLocationsList.join()}`,
        { responseType: 'arraybuffer' },
      )
      .then((resp) => {
        const responseData = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        var excelFileName = 'DNS_RTM.xlsx'
        if (resp.headers.message === 'Success' || resp.headers.message === '') {
          dispatch({
            type: types.DNS_GENERATION,
            payload: {
              fileData: responseData,
              fileName: excelFileName,
              dnsErrorMessage: '',
              dnsErrorOpen: false,
            },
          })
        } else {
          dispatch({
            type: types.DNS_GENERATION,
            payload: {
              fileData: responseData,
              fileName: 'Incomplete_' + excelFileName,
              dnsErrorMessage: resp.headers.message,
              dnsErrorOpen: true,
            },
          })
        }
      })
      .catch(() => {
        dispatch({
          type: types.DNS_GENERATION,
          payload: {
            fileData: '',
            fileName: '',
            dnsErrorMessage:
              'Something went wrong. Please contact RDSE team for assistance.',
            dnsErrorOpen: true,
          },
        })
      })
  }
}

export function downloadGroupSheet(groupData) {
  return async function (dispatch) {
    axios
      .post(`${apiConfig.api.url}/export_members`, groupData, {
        responseType: 'arraybuffer',
      })
      .then((resp) => {
        if (resp.headers.message === '') {
          const fileData = new Blob([resp.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const sanitizedFileName = groupData.name.replace(
            new RegExp(/([,;[\]()<>])/g),
            '',
          )
          const removeDoublePeriods = sanitizedFileName.replace(
            new RegExp(/([.]{2,})/g),
            '',
          )
          var excelFileName = `${removeDoublePeriods}.xlsx`
          dispatch({
            type: types.DOWNLOAD_GROUP_SHEET,
            payload: {
              fileData: fileData,
              fileName: excelFileName,
              resultMessage: '',
            },
          })
        } else {
          dispatch({
            type: types.DOWNLOAD_GROUP_SHEET,
            payload: {
              fileData: '',
              fileName: '',
              resultMessage: resp.headers.message,
            },
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: types.DOWNLOAD_GROUP_SHEET,
          payload: {
            fileData: '',
            fileName: '',
            resultMessage: error.response,
          },
        })
      })
  }
}

export function downloadIpPhoneData() {
  return async function (dispatch) {
    axios
      .get(`${apiConfig.api.url}/ip_phones`, { responseType: 'arraybuffer' })
      .then((resp) => {
        const responseData = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        if (resp.headers.message.includes('IP_Phone_Data')) {
          const excelFileName = `${resp.headers.message}.xlsx`
          dispatch({
            type: types.DOWNLOAD_IP_PHONE_DATA,
            payload: {
              ipFileData: responseData,
              ipFileName: excelFileName,
              resultMessage: 'Success',
              statusOpen: false,
            },
          })
        } else {
          dispatch({
            type: types.DOWNLOAD_IP_PHONE_DATA,
            payload: {
              ipFileData: '',
              ipFileName: '',
              resultMessage: resp.headers.message,
              statusOpen: true,
            },
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: types.DOWNLOAD_IP_PHONE_DATA,
          payload: {
            ipFileData: '',
            ipFileName: '',
            resultMessage:
              'There was an error getting IP Phone data. Please reach out in the #r-and-d-pirates Slack channel for help.',
            statusOpen: true,
          },
        })
      })
  }
}

function formatFreeTextList(textList) {
  let formattedTextList = ''
  if (textList.includes(',')) {
    let removedWhitespace = textList.replaceAll(' ', '')
    let splitItems = removedWhitespace.split(',')
    formattedTextList = [...new Set(splitItems)]
  } else if (textList.includes(' ')) {
    let splitItems = textList.split(' ')
    formattedTextList = [...new Set(splitItems)]
  } else if (textList.includes('\n')) {
    let splitItems = textList.split('\n')
    formattedTextList = [...new Set(splitItems)]
  } else {
    formattedTextList = [textList]
  }
  return formattedTextList
}

export function getGroupMembers(groupIds) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/smartsheet_members?group_ids=${groupIds}`)
      .then((resp) => {
        dispatch({
          type: types.GET_GROUP_MEMBERS,
          payload: resp.data.groups,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Members.'
          dispatch({
            type: types.GET_GROUP_MEMBERS,
            payload: result,
          })
        }
      })
  }
}

export function getGroupNames() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/smartsheet_groups`)
      .then((resp) => {
        dispatch({
          type: types.GET_GROUP_NAMES,
          payload: resp.data.groups,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Groups.'
          dispatch({
            type: types.GET_GROUP_NAMES,
            payload: result,
          })
        }
      })
  }
}

export function resetDhcpGeneration() {
  return function (dispatch) {
    dispatch({
      type: types.DHCP_GENERATION,
      payload: {
        csvFileData: '',
        csvFileName: '',
        dnsErrorMessage: '',
        dnsErrorOpen: false,
      },
    })
  }
}

export function resetDnsGeneration() {
  return function (dispatch) {
    const payload = {
      dnsFileData: '',
      dnsFileName: '',
      dnsErrorMessage: '',
      dnsErrorOpen: false,
    }
    dispatch({
      type: types.DNS_GENERATION,
      payload: payload,
    })
  }
}

export function setResultMessage(message) {
  return function (dispatch) {
    dispatch({
      type: types.SET_RESULT_MESSAGE,
      payload: message,
    })
  }
}

export function toggleStatusOpen(statusOpen) {
  return function (dispatch) {
    dispatch({
      type: types.TOGGLE_STATUS_OPEN,
      payload: statusOpen,
    })
  }
}

export function clearMessage() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_MESSAGE,
      payload: '',
    })
  }
}

export function getMaximoOptions() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/maximo_work_order_options`)
      .then((resp) => {
        dispatch({
          type: types.GET_WORK_ORDER_OPTIONS,
          payload: resp.data,
        })
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: types.GET_WORK_ORDER_OPTIONS,
            payload: {
              messageString: 'Error loading form data from Smartsheet.',
            },
          })
        }
      })
  }
}

export function createMaximoWorkOrder(workOrderData) {
  return async function (dispatch) {
    await axios
      .post(`${apiConfig.api.url}/maximo_work_orders`, workOrderData, {
        responseType: 'arraybuffer',
      })
      .then((resp) => {
        if (resp.headers.message === '') {
          const responseData = new Blob([resp.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          var excelFileName = `R&D Maximo Work Order.xlsx`
          dispatch({
            type: types.POST_MAXIMO,
            payload: {
              fileData: responseData,
              fileName: excelFileName,
              eventsMessage: 'Maximo Work Order Created!',
              statusOpen: true,
            },
          })
        } else if (resp.headers.message === 'No Excel') {
          dispatch({
            type: types.POST_MAXIMO,
            payload: {
              fileData: '',
              fileName: 'No Excel',
              eventsMessage: 'Maximo Work Order Created!',
              statusOpen: true,
            },
          })
        } else {
          dispatch({
            type: types.POST_MAXIMO,
            payload: {
              fileData: '',
              fileName: '',
              eventsMessage: resp.headers.message,
              statusOpen: true,
            },
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: types.POST_MAXIMO,
          payload: {
            fileData: '',
            fileName: '',
            eventsMessage: error.response,
            statusOpen: true,
          },
        })
      })
  }
}

export function getActiveSheets(workspaceID) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/workspace_contents?workspace_id=${workspaceID}`,
      )
      .then((resp) => {
        const sortedSheets = resp.data.sort(compare)
        dispatch({
          type: types.GET_ACTIVE_SHEETS,
          payload: sortedSheets,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Sheets..'
          dispatch({
            type: types.GET_ACTIVE_SHEETS,
            payload: result,
          })
        }
      })
  }
}

export function getWorkspaces() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/workspaces`)
      .then((resp) => {
        dispatch({
          type: types.GET_WORKSPACES,
          payload: resp.data,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Workspaces..'
          dispatch({
            type: types.GET_WORKSPACES,
            payload: result,
          })
        }
      })
  }
}

export function getMaximoBulkLocations(smartsheetId) {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(
        `${apiConfig.api.url}/maximo_work_order_bulk_locations?smartsheetId=${smartsheetId}`,
      )
      .then((resp) => {
        dispatch({
          type: types.GET_MAXIMO_BULK_LOCATIONS,
          payload: resp.data,
        })
      })
      .catch((error) => {
        if (error.response) {
          var result = 'Error Loading Locations Data..'
          dispatch({
            type: types.GET_MAXIMO_BULK_LOCATIONS,
            payload: result,
          })
        }
      })
  }
}
