import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material/'

export default class KitListSuccessModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventMessage: '',
      kitListSmartsheetData: [],
    }
  }

  render() {
    const kitListSmartsheetData =
      this.props.kitListSmartsheetData !== undefined
        ? this.props.kitListSmartsheetData.map((link) => (
            <div>
              <a
                href={link.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            </div>
          ))
        : ''
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.props.title === '' ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <div>
                <Typography variant="body1" gutterBottom>
                  {this.props.title === 'Success'
                    ? kitListSmartsheetData
                    : this.props.eventMessage}
                </Typography>
                <Button variant="contained" onClick={this.props.handleClose}>
                  OK
                </Button>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    )
  }
}
