import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default class AvailablePortsHelpDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Available Port Information Help</DialogTitle>
        <DialogContent>
          <h4>Available Port Information</h4>
          <p>
            Enter a list of location T#s, separated by either spaces or and
            click the submit button. A link to a Smartsheet containing the
            requested information will be generated.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleHelpClose}
            className="helpModalClose"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
