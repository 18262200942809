import * as types from './actionType'

export const initialState = {
  projectsList: [],
}

export default function homePageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_PROJECTS: {
      const newState = Array.isArray(action.payload.projects)
        ? {
            ...state,
            projectsList: action.payload.projects,
          }
        : {
            ...state,
            projectsList: [action.payload.projects],
          }
      return newState
    }
    default: {
      return state
    }
  }
}
