import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import {
  clearMessage,
  downloadDnsFile,
  resetDnsGeneration,
} from '../../../store/AdditionalTools/actionCreator'
import { DnsErrorModal } from './DnsErrorModal'
import apiConfig from '../../../config/apiConfig'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../../common/FireflyHelper'

export class DnsExcelGenerationTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonEnabled: false,
      dnsErrorMessage: '',
      dnsErrorOpen: false,
      dnsFileData: '',
      dnsFileName: '',
      locations: '',
    }
    initializeFireflySDK()
    this.createRtm = this.createRtm.bind(this)
    this.fileDownload = React.createRef()
    this.handleErrorModalClose = this.handleErrorModalClose.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.dnsFileData !== nextProps.dnsFileData &&
      nextProps.dnsFileData !== '' &&
      nextProps.dnsFileData != null
    ) {
      const url = window.URL.createObjectURL(nextProps.dnsFileData)
      this.setState(
        {
          dnsFileData: url,
          dnsFileName: nextProps.dnsFileName,
        },
        function () {
          this.fileDownload.current.click()
        },
      )
    }
    if (this.props.dnsErrorMessage !== nextProps.dnsErrorMessage) {
      this.setState({
        dnsErrorMessage: nextProps.dnsErrorMessage,
        dnsErrorOpen: nextProps.dnsErrorOpen,
      })
    }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'DNS Excel Generation Tab' },
      user: { id: userInfo.email },
    })
  }

  createRtm = (event) => {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'DNS Excel Generation - Submit' },
      user: { id: userInfo.email },
    })
    event.preventDefault()
    this.props.clearMessage()
    this.props.downloadDnsFile(this.state.locations)
    this.resetForm()
  }

  handleErrorModalClose = () => {
    this.props.resetDnsGeneration()
  }

  resetForm = () => {
    this.setState({
      buttonEnabled: false,
      locations: '',
    })
  }

  validateForm = () => {
    if (this.state.locations === '') {
      this.setState({ buttonEnabled: false })
    } else {
      this.setState({ buttonEnabled: true })
    }
  }

  render() {
    return (
      <CardContent
        style={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Enter the locations you wish to create the DNS RTM file for.
            </Typography>
          </Grid>
        </CardActions>
        <form
          onSubmit={this.createRtm}
          style={{
            alignItems: 'stretch',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid container item style={{ paddingLeft: 8 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  paddingTop: '10px',
                  flexBasis: '500px',
                }}
              >
                <FormControl style={{ width: '90%' }}>
                  <TextareaAutosize
                    htmlFor="locations"
                    type="text"
                    minRows={8}
                    maxRows={18}
                    value={this.state.locations}
                    placeholder="Enter Locations (Ex: T0003 T0004 ETC)"
                    label="Locations"
                    required
                    onChange={(event) =>
                      this.setState(
                        { locations: event.target.value },
                        function () {
                          this.validateForm()
                        },
                      )
                    }
                    style={{ width: '100%' }}
                  />
                </FormControl>
                <CardActions
                  style={{
                    width: '100%',
                    height: '30%',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 20,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!this.state.buttonEnabled}
                    >
                      Submit
                    </Button>
                  </Grid>
                </CardActions>
              </div>
            </Grid>
          </Grid>
        </form>
        <DnsErrorModal
          open={this.state.dnsErrorOpen}
          handleClose={this.handleErrorModalClose}
          message={this.state.dnsErrorMessage}
          clearMessage={this.props.clearMessage}
        />
        <a
          href={this.state.dnsFileData}
          download={this.state.dnsFileName}
          ref={this.fileDownload}
          style={{ display: 'none' }}
        >
          ''
        </a>
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dnsErrorMessage: state.additionalTools.dnsErrorMessage,
    dnsErrorOpen: state.additionalTools.dnsErrorOpen,
    dnsFileData: state.additionalTools.dnsFileData,
    dnsFileName: state.additionalTools.dnsFileName,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  clearMessage,
  downloadDnsFile,
  resetDnsGeneration,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DnsExcelGenerationTab)
