import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CreateCutsheetsSuccessModal from './CreateCutsheetsSuccessModal'
import CreateCutsheetHelpDialog from './CreateCutsheetHelpDialog'
import {
  createCutsheets,
  clearMessage,
  getActiveSheets,
  getWorkspaces,
} from '../../store/CreateSmartsheets/actionCreator'
import Select from 'react-select'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../config/apiConfig'
import { initializeFireflySDK } from '../../common/FireflyHelper'

export class CreateSheetsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cutsheetType: '',
      smartsheetId: 999,
      smartsheetName: '',
      cutsheetTypeName: '',
      eventsMessage: '',
      helpOpen: false,
      statusOpen: false,
      selectedFiles: null,
      smartsheetData: [],
      teamName: '',
      teamNameLabel: '',
      activeSheets: [],
      workspaces: [],
      workspace: '',
      newOrExistingSheet: '',
      newOrExistingSheetName: '',
    }
    initializeFireflySDK()
    this.createCutsheets = this.createCutsheets.bind(this)
    this.handleHelpClose = this.handleHelpClose.bind(this)
    this.handleHelpOpen = this.handleHelpOpen.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Create Cutsheets Tab' },
      user: { id: userInfo.email },
    })
    await this.props.getWorkspaces()
  }

  createCutsheets = (
    event,
    selectedFiles,
    cutsheetType,
    smartsheetId,
    teamName,
    user,
  ) => {
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Create Cutsheets - Submit' },
      user: { id: user.email },
    })
    event.preventDefault()
    this.props.clearMessage()
    this.props.createCutsheets(
      cutsheetType,
      smartsheetId,
      teamName,
      selectedFiles,
    )
    this.setState({ statusOpen: true })
    this.resetForm()
  }

  handleHelpClose() {
    this.setState({ helpOpen: false })
  }

  handleHelpOpen() {
    this.setState({ helpOpen: true })
  }

  handleStatusClose() {
    this.setState({
      statusOpen: false,
    })
  }

  resetForm = () => {
    this.setState({
      cutsheetType: '',
      cutsheetTypeName: '',
      smartsheetId: 999,
      smartsheetName: '',
      selectedFiles: null,
      buttonEnabled: false,
      teamName: '',
      teamNameLabel: '',
      workspace: '',
      newOrExistingSheet: '',
      newOrExistingSheetName: '',
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      eventsMessage: nextProps.eventsMessage,
      smartsheetData: nextProps.smartsheetData,
      workspaces: JSON.parse(JSON.stringify(nextProps.workspaces)),
      activeSheets: nextProps.activeSheets,
    })
  }

  validateForm = () => {
    if (
      (this.state.cutsheetTypeName.label === 'Access Points - Cutsheet' &&
        this.state.selectedFiles !== null &&
        this.state.smartsheetId !== undefined) ||
      (this.state.cutsheetTypeName.label ===
        'Access Points - Supplemental Sheet (New Stores)' &&
        this.state.selectedFiles !== null &&
        this.state.smartsheetId !== undefined) ||
      (this.state.cutsheetTypeName.label === 'Phones - Supplemental Sheet' &&
        this.state.selectedFiles !== null &&
        this.state.smartsheetId !== undefined) ||
      (this.state.cutsheetTypeName.label === 'TVS - Supplemental Sheet' &&
        this.state.teamName !== '' &&
        this.state.selectedFiles !== null)
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
  }

  render() {
    const teamNameOptions = [
      { value: 'Bundle', label: 'Bundle' },
      { value: 'DC', label: 'DC' },
      { value: 'HQ', label: 'HQ' },
      { value: 'New Store', label: 'New Store' },
      { value: 'Remodel', label: 'Remodel' },
      { value: 'Special Project', label: 'Special Project' },
    ]
    const sheetOptions = [
      { value: 'Existing Sheet', label: 'Existing Sheet' },
      { value: 'New Sheet', label: 'New Sheet' },
    ]
    let modalTitle
    if (this.state.eventsMessage === 'Smartsheet(s) created!') {
      modalTitle = 'Success'
    } else if (
      this.state.eventsMessage !== undefined &&
      this.state.eventsMessage.length > 1 &&
      this.state.eventsMessage !== 'Smartsheet(s) created!'
    ) {
      modalTitle = 'Error'
    } else {
      modalTitle = ''
    }
    return (
      <CardContent
        style={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <CardActions style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom>
              Select type of sheet to you wish to create.
            </Typography>
            <Tooltip title={'Help'}>
              <IconButton onClick={this.handleHelpOpen} size="large">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </CardActions>
        <form
          onSubmit={(event) =>
            this.createCutsheets(
              event,
              this.state.selectedFiles,
              this.state.cutsheetType,
              this.state.smartsheetId,
              this.state.teamName,
              this.props.user,
            )
          }
          style={{
            alignItems: 'stretch',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              alignItems: 'stretch',
              display: 'flex',
            }}
          >
            <Grid container item style={{ paddingLeft: 8 }}>
              <div
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexBasis: '600px',
                }}
              >
                <Grid item xs={14} md={10}>
                  <FormControl style={{ width: '90%' }}>
                    <Select
                      value={this.state.cutsheetTypeName}
                      options={apiConfig.cutsheetTypes
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((cutsheetType) => ({
                          label: cutsheetType.name,
                          value: cutsheetType.type,
                        }))}
                      placeholder="Select Sheet Type"
                      onChange={(event) => {
                        this.setState(
                          {
                            cutsheetType: event.value,
                            cutsheetTypeName: event,
                            smartsheetId: 999,
                            smartsheetName: '',
                            selectedFiles: null,
                            buttonEnabled: false,
                            teamName: '',
                            teamNameLabel: '',
                            workspace: '',
                            newOrExistingSheet: '',
                            newOrExistingSheetName: '',
                          },
                          function () {
                            this.validateForm()
                          },
                        )
                      }}
                      inputProps={{
                        name: 'cutsheetType',
                        id: 'cutsheetType',
                      }}
                    />
                  </FormControl>
                </Grid>
                <br />
                {this.state.cutsheetType === '' ? (
                  ''
                ) : (
                  <div>
                    {this.state.cutsheetType === 'TVS' ? (
                      <div>
                        <Grid item xs={14} md={10}>
                          <FormControl style={{ width: '90%' }}>
                            <Select
                              value={this.state.teamNameLabel}
                              options={teamNameOptions}
                              placeholder="Select Team Name"
                              onChange={(event) => {
                                this.setState(
                                  {
                                    teamName: event.value,
                                    teamNameLabel: event,
                                  },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }}
                              inputProps={{
                                name: 'teamName',
                                id: 'teamName',
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <br />
                        <Grid item xs={14} md={10}>
                          <input
                            id="selectedFiles"
                            type="file"
                            multiple
                            accept={
                              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            }
                            onChange={(event) => {
                              this.setState(
                                { selectedFiles: event.target.files },
                                function () {
                                  this.validateForm()
                                },
                              )
                            }}
                          />
                        </Grid>
                      </div>
                    ) : (
                      <div>
                        <Grid item xs={14} md={10}>
                          <FormControl style={{ width: '90%' }}>
                            <Select
                              value={this.state.newOrExistingSheetName}
                              options={sheetOptions}
                              placeholder="Select New or Existing Sheet"
                              onChange={(event) => {
                                this.setState(
                                  {
                                    newOrExistingSheet: event.value,
                                    newOrExistingSheetName: event,
                                    smartsheetId: 999,
                                    smartsheetName: '',
                                    selectedFiles: null,
                                    buttonEnabled: false,
                                    teamName: '',
                                    teamNameLabel: '',
                                    workspace: '',
                                  },
                                  function () {
                                    this.validateForm()
                                  },
                                )
                              }}
                              inputProps={{
                                name: 'newOrExistingSheet',
                                id: 'newOrExistingSheet',
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <br />
                        {this.state.newOrExistingSheet === 'New Sheet' ? (
                          ''
                        ) : (
                          <div>
                            <Grid item xs={14} md={10}>
                              <FormControl
                                component="fieldset"
                                style={{
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '90%',
                                }}
                              >
                                <Select
                                  value={this.state.workspace}
                                  options={
                                    this.state.workspaces.length > 0
                                      ? this.state.workspaces.map(
                                          (workspace) => ({
                                            label: workspace.name,
                                            value: workspace.id,
                                          }),
                                        )
                                      : ''
                                  }
                                  placeholder="Select Workspace"
                                  onChange={(event) => {
                                    this.setState(
                                      {
                                        workspace: event,
                                      },
                                      function () {
                                        this.validateForm()
                                        this.props.getActiveSheets(
                                          this.state.workspace.value,
                                        )
                                      },
                                    )
                                  }}
                                  inputProps={{
                                    name: 'workspace',
                                    id: 'workspace',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <br />
                            <Grid item xs={14} md={10}>
                              <FormControl
                                component="fieldset"
                                style={{
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '90%',
                                }}
                              >
                                <Select
                                  value={this.state.smartsheetName}
                                  options={
                                    this.state.activeSheets.length > 0
                                      ? this.state.activeSheets.map(
                                          (activeSheet) => ({
                                            label: activeSheet.name,
                                            value: activeSheet.id,
                                          }),
                                        )
                                      : ''
                                  }
                                  placeholder="Select Smartsheet"
                                  onChange={(event) => {
                                    this.setState(
                                      {
                                        smartsheetId: event.value,
                                        smartsheetName: event,
                                      },
                                      function () {
                                        this.validateForm()
                                      },
                                    )
                                  }}
                                  inputProps={{
                                    name: 'smartsheetId',
                                    id: 'smartsheetId',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </div>
                        )}
                        <br />
                        <Grid item xs={14} md={10}>
                          <input
                            id="selectedFiles"
                            type="file"
                            multiple
                            accept={'.csv'}
                            onChange={(event) => {
                              this.setState(
                                { selectedFiles: event.target.files },
                                function () {
                                  this.validateForm()
                                },
                              )
                            }}
                          />
                        </Grid>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <CardActions
                style={{
                  width: '100%',
                  height: '30%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 20,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={!this.state.buttonEnabled}
                  >
                    Submit
                  </Button>
                </Grid>
              </CardActions>
            </Grid>
          </Grid>
        </form>
        <CreateCutsheetHelpDialog
          open={this.state.helpOpen}
          handleHelpClose={this.handleHelpClose}
        />
        <CreateCutsheetsSuccessModal
          open={this.state.statusOpen}
          handleClose={this.handleStatusClose}
          eventsMessage={this.state.eventsMessage}
          smartsheetData={this.state.smartsheetData}
          title={modalTitle}
        />
      </CardContent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    eventsMessage: state.createSmartsheets.eventsMessage,
    smartsheetData: state.createSmartsheets.smartsheetData,
    activeSheets: state.createSmartsheets.activeSheets,
    workspaces: state.createSmartsheets.workspaces,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  createCutsheets,
  clearMessage,
  getActiveSheets,
  getWorkspaces,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSheetsTab)
