import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import * as types from './actionType'

export function getDeviceStatus(storeIds) {
  return async function (dispatch) {
    var promises = []
    promises.push(
      axios.get(
        `${apiConfig.api.url}/device_statuses?location_ids=/${storeIds}`,
      ),
    )
    const promisesResolved = promises.map((promise) =>
      promise.catch((error) => ({ error })),
    )
    await axios.all(promisesResolved).then((resp) => {
      resp.forEach((response, index) => {
        if (response.status === 200) {
          dispatch({
            type: types.GET_DEVICE_LIST,
            payload: response.data,
          })
        } else if (
          response.error.response.status === 404 ||
          response.error.response.status === 400
        ) {
          dispatch({
            type: types.GET_DEVICE_LIST,
            payload: response.error.response.data.message.replace('/', ''),
          })
        } else {
          dispatch({
            type: types.GET_DEVICE_LIST,
            payload: 'An unexpected error has occurred!',
          })
        }
      })
    })
  }
}

export function getImagesoundPlayers() {
  return async function (dispatch) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    await axios
      .get(`${apiConfig.api.url}/imagesound_players`)
      .then((resp) => {
        let playersList = resp.data
        if (playersList.length === 0) {
          playersList = 'No Imagesound Players Found!'
        }
        dispatch({
          type: types.GET_IMAGESOUND_PLAYERS,
          payload: playersList,
        })
      })
      .catch((error) => {
        var result = 'No Imagesound Players Found!'
        dispatch({
          type: types.GET_IMAGESOUND_PLAYERS,
          payload: result,
        })
      })
  }
}
