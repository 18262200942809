import React from 'react'
import { connect } from 'react-redux'
import HeaderTitle from '../Header/HeaderTitle'
import {
  Grid,
  Typography,
  Card,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
} from '@mui/material'
import CablingTypesTab from './CablingTypesTab'
import CablingLayoutsTab from './CablingLayoutsTab'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'

export class Cabling extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      selectedTab: 0,
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.handleTabChange = this.handleTabChange.bind(this)
    this.dialogBox = React.createRef()
  }

  async componentDidMount() {
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Cabling',
          pageName: 'Cabling',
          pageType: 'Cabling',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: {
        name: 'ziptie',
      },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Cabling' },
      user: { id: userInfo.email },
    })
  }

  static defaultProps = {
    userInfo: {
      lanId: undefined,
      email: undefined,
    },
  }

  handleTabChange(event, newTab) {
    this.setState({
      selectedTab: newTab,
    })
  }

  render() {
    const { classes, userInfo } = this.props
    return (
      <div>
        <HeaderTitle title="Cabling" />
        {this.state.loading ? (
          <Grid
            container
            spacing={16}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ margin: 5 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={6}>
                <Card style={{ height: '75vh' }}>
                  <AppBar position="static">
                    <Tabs
                      value={this.state.selectedTab}
                      onChange={this.handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Cabling Type Sync" />
                      <Tab label="Switch and Patch Panel Layout" />
                    </Tabs>
                  </AppBar>
                  {this.state.selectedTab === 0 && (
                    <CablingTypesTab classes={classes} user={userInfo} />
                  )}
                  {this.state.selectedTab === 1 && (
                    <CablingLayoutsTab
                      classes={classes}
                      user={userInfo}
                      handleHelpOpen={this.state.handleHelpOpen}
                    />
                  )}
                </Card>
              </Grid>
            </div>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
  }
}

export default connect(mapStateToProps)(Cabling)
