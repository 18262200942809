import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  LinearProgress,
} from '@mui/material/'

export const DhcpModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{ onExited: props.clearMessage }}
    >
      <Fade in={props.open}>
        {props.message === '' ? (
          <DialogContent>
            <LinearProgress color="success" />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle>DHCP Generation Error</DialogTitle>
            <DialogContent>{props.message}</DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={props.handleClose}>
                Close
              </Button>
            </DialogActions>
          </div>
        )}
      </Fade>
    </Dialog>
  )
}
